import React from "react";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

// import Home from "./pages/home/home";
import MarketPlace from "./pages/home/marketplace";
import NftDetails from "./pages/home/nft_details";
import InoDetails from "./pages/home/ino_details";
import RentingDetails from "./pages/home/renting_details";
import MyAddsDetails from "./pages/home/my_adds_details";
import MyNft from "./pages/home/my_nft";

import './App.css';
//import './i18n';

class App extends React.Component {

  render() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/:cat_id?/:sub_cat_id?" element={<MarketPlace />}></Route>
          <Route exact path="/nft_details/:nft_id?/:from_action?" element={<NftDetails />}></Route>
          <Route exact path="/ino_details/:nft_id?/:from_action?" element={<InoDetails />}></Route>
          <Route exact path="/renting_details/:nft_id?/:from_action?" element={<RentingDetails />}></Route>
          <Route exact path="/my_adds_details/:nft_id?/:from_action?" element={<MyAddsDetails />}></Route>
          <Route exact path="/my_nft" element={<MyNft />}></Route>
          {/* <Route exact path="/" element={<Home />}></Route> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
  }
}

export default App;
