import React from 'react';
import Frame from "../../components/frame";
import Countdown from 'react-countdown';
import { useParams, useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import CaesiumNFT from "../../contracts/CaesiumNFT.json";
import moment from 'moment/moment';
import Web3 from "web3";
import { createWeb3Modal, defaultConfig, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalSigner } from '@web3modal/ethers5/react';
import { nftDetailsAPi } from '../../utils';
import { binanceSmartChain, metadata, projectId, ERC20ABI, SITE_URL,
  ERC1155ABI, NFT_MARKETPLACE_ABI, NFT_ASSET_CONTRACT_ADDRESS,NFT_MaketPlace_ADDRESS,
  RENTING_ABI, CSM_MEMBER_ABI,RENTING_CONTRACT,Caesium_Member_CONTRACT
 } from '../../utils/constants';


const modal = createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [binanceSmartChain],
  projectId,
  themeMode: 'dark',
  themeVariables: {
    '--w3m-z-index': 999
  }
})

const initialState = {
  loading: false,
  is_connected: false,
  web3: null,
  accounts: null,
  renting_contract:null,
  nft_asset_contract:null,
  nft_marketplace:null,
  contractMember: null,
  YourAddress: '-----------',
  YourAddressfull: null,
  total_nft_marketplace: 0,
  nft_marketplace_list: [],
  page_number: 1,
  page_limit: 12,
  pagination_m: null,
  current_page_m: 1,
  load_page_m: 1,
  nft_item:null
};

class MyAddsDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      web3: null,
      accounts: null,
      renting_contract:null,
      nft_asset_contract:null,
      contractMember: null,
      YourAddress: '-----------',
      YourAddressfull: null,
      nft_id: this.props?.nft_id != "undefined" ? this.props?.nft_id : "",
      total_nft_marketplace: 0,
      nft_marketplace_list: [],
      page_number: 1,
      page_limit: 12,
      pagination_m: null,
      current_page_m: 1,
      load_page_m: 1,
      nft_item:null
    }
  }

  componentDidMount = async () => {
    try {
      if(!this.props?.address){
        this.setWeb3();
      }
    } catch (error) {
      this.setState({ networkMain: false, loading: false });
      console.error(error);
    }
  };


  componentDidUpdate = async () => {
    if (this.props?.address && this.props?.address != this.state.accounts) {
      this.setState({ accounts: this.props?.address });
      this.connectToWallet();
    }

    if (this.state.accounts && !this.props?.isConnected) {
      this.disconnectFromWallet();
    }

  }


  connectToWallet = async (e) => {
    try {
      // Get network provider and web3 instance.
      let provider = this.props?.walletProvider;
      let web3 = new Web3(modal);
      web3.setProvider(binanceSmartChain.rpcUrl);
      // Use web3 to get the user's accounts.
      const accounts = this.props?.address;

      const networkId = this.props?.chainId;
      if (networkId !== 56) {
        enqueueSnackbar("Error! Please connect to bsc mainnet", { variant: 'error' });
        this.setState({ loading: false });
        return false;
      }

      if (this.state.accounts !== accounts) {
        // Get the contract instance.

        this.setState({ loading: true });
        const deployedNetwork = CaesiumNFT.networks;
        const nft_asset_contract = new web3.eth.Contract(
          ERC1155ABI,
          deployedNetwork && NFT_ASSET_CONTRACT_ADDRESS, provider
        );
  
        const nft_marketplace = new web3.eth.Contract(
          NFT_MARKETPLACE_ABI,
          deployedNetwork && NFT_MaketPlace_ADDRESS, provider
        );
  
        const renting_contract = new web3.eth.Contract(
          RENTING_ABI,
          deployedNetwork && RENTING_CONTRACT, provider
        );
  
        const contractMember = new web3.eth.Contract(
          CSM_MEMBER_ABI,
          deployedNetwork && Caesium_Member_CONTRACT, provider
        );

        let user_account = accounts;

        this.setState({ loading: false, is_connected: true });
        this.setState({ renting_contract, contractMember, web3, accounts: user_account, nft_asset_contract, nft_marketplace, loading: true }, this.fetchData);

        enqueueSnackbar("Wallet Connected", { variant: 'success' });
      }
    } catch (error) {
      // Catch any errors for any of the above operations.
      enqueueSnackbar(error.message, { variant: 'error' });
      this.setState({ networkMain: false });
      console.error(error);
      this.setState({ loading: false });
    }
  };

  disconnectFromWallet = async () => {
    try {
      this.setState({ loading: true });
      if (this.state.web3) {
        enqueueSnackbar("Wallet disconnected", { variant: 'success' });
      }
      this.setState(initialState);
    } catch (error) {
      // Catch any errors for any of the above operations.
      enqueueSnackbar(error.message, { variant: 'error' });
      this.setState({ networkMain: false });
      console.error(error);
      this.setState({ loading: false });
    }
  }

  fetchData = async () => {
    try {
      let {accounts, nft_asset_contract, renting_contract} = this.state;
      let YourAddress = this.state.YourAddress;
      if(accounts)
      YourAddress = accounts.slice(0, 6) + '.....' + accounts.slice(accounts.length - 5, accounts.length);
      let YourAddressfull = accounts;
      let getPurchaseData = await renting_contract.methods.getItemHistory(this.props?.nft_id).call();
      let nft_marketplace_list = [];
      let pagination_m = null;

      if(getPurchaseData.length > 0){
        let data_nft = getPurchaseData;
       
        getPurchaseData = data_nft.length;
        pagination_m = this.paginate(getPurchaseData, 1);
        for (var i = getPurchaseData - 1; i >= 0; i--) {
          let nft_market = data_nft[i];
          nft_market = {
            tokenId:parseInt(nft_market[0]),
            deadline:parseInt(nft_market[1]),
            quantity:parseInt(nft_market[2]),
            isRevoked:nft_market[3],
            startTime:parseInt(nft_market[4]),
            rentUserAddress:nft_market[5],
            index:i
          }

          let token_url = await nft_asset_contract.methods.geturi(nft_market.tokenId).call();
          
          let decimals = 1e18;
          let symbol = 'BNB';
          let nft_details = await nftDetailsAPi(token_url);
          nft_details = nft_details;
          let attributes = nft_details.attributes;
          nft_market.nft_details = nft_details;
          nft_market.attributes = attributes;
          nft_market.decimals = decimals;
          nft_market.symbol = symbol;
          nft_marketplace_list.push(nft_market);
          if(nft_marketplace_list.length%4 == 0){
            this.setState({
              nft_marketplace_list,pagination_m, loading: false
            });
          }
          if(nft_marketplace_list.length==this.state.page_limit){
            break;
          }                   
        }
      }

      this.setState({
        YourAddress,
        YourAddressfull,
        getPurchaseData,
        nft_marketplace_list,
        pagination_m,
        loading:false,
        is_refresh: false
      })
      
      // const script = document.createElement('script')
      // script.src = '/assets/js/image_cn.js';
      // script.async = true;
      // document.body.append(script);
    } catch (error) {
      this.setState({
        loading:false,
        is_refresh: false
      })
      console.log('fetch error', error);
    }
  }

  paginate = (
    totalItems,
    currentPage = 1,
    pageSize = 12,
    maxPages = 10
  ) => {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage, endPage;
    if (totalPages <= maxPages) {
      // total pages less than max so show all pages
      startPage = 1;
      endPage = totalPages;
    } else {
      // total pages more than max so calculate start and end pages
      let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
      let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
      if (currentPage <= maxPagesBeforeCurrentPage) {
        // current page near the start
        startPage = 1;
        endPage = maxPages;
      } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
        // current page near the end
        startPage = totalPages - maxPages + 1;
        endPage = totalPages;
      } else {
        // current page somewhere in the middle
        startPage = currentPage - maxPagesBeforeCurrentPage;
        endPage = currentPage + maxPagesAfterCurrentPage;
      }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

  getPaginationDataM = async () => {
    try {
      this.setState({ loading: true });
      const {nft_asset_contract,renting_contract} = this.state;
      let getPurchaseData = await renting_contract.methods.getItemHistory(this.props?.nft_id).call();
      let nft_marketplace_list = [];      

      let pagination_m = this.state.pagination_m;
      let current_page = this.state.current_page_m;
      let load_page_m = current_page;
      let cnt = 0;
      pagination_m = this.paginate(getPurchaseData.length, current_page);
      let total_length = getPurchaseData.length;
      let show_data = total_length - (current_page - 1) * pagination_m.pageSize;
      let data_nft = getPurchaseData;

      // if(getPurchaseData.length>0){
      if (show_data > 0) {
        // for(var i=0; i<getWithDrawList.length;i++){
        for (var i = show_data - 1; i >= 0; i--) {
          if (cnt >= pagination_m.pageSize) {
            break;
          }
          let nft_market = data_nft[i];
          nft_market = {
            tokenId:parseInt(nft_market[0]),
            deadline:parseInt(nft_market[1]),
            quantity:parseInt(nft_market[2]),
            isRevoked:nft_market[3],
            startTime:parseInt(nft_market[4]),
            rentUserAddress:nft_market[5],
            index:i
          }

          let token_url = await nft_asset_contract.methods.geturi(nft_market.tokenId).call();
          
          let decimals = 1e18;
          let symbol = 'BNB';
          let nft_details = await nftDetailsAPi(token_url);
          
          nft_details = nft_details;
          let attributes = nft_details.attributes;
          nft_market.nft_details = nft_details;
          nft_market.attributes = attributes;
          nft_market.decimals = decimals;
          nft_market.symbol = symbol;
          nft_marketplace_list.push(nft_market);
          if(nft_marketplace_list.length%4 == 0){
            this.setState({
              nft_marketplace_list,pagination_m, load_page_m,loading: false
            });
          }
          if(nft_marketplace_list.length==this.state.page_limit){
            break;
          }
          cnt++;
        }
      }

      this.setState({ nft_marketplace_list, pagination_m, load_page_m, loading: false });
      // const script = document.createElement('script')
      // script.src = '/assets/js/image_cn.js';
      // script.async = true;
      // document.body.append(script);
    } catch (error) {
      this.setState({ loading: false });
      console.log('pagination error', error);
    }
  }

  setWeb3 = async ()=>{
    try {      
      this.setState({loading:true});
      let provider = this.props?.walletProvider;
      let web3 = new Web3(modal);
      const accounts = this.props?.address;
      web3.setProvider(binanceSmartChain.rpcUrl);
      const deployedNetwork = CaesiumNFT.networks;
      const nft_asset_contract = new web3.eth.Contract(
        ERC1155ABI,
        deployedNetwork && NFT_ASSET_CONTRACT_ADDRESS, provider
      );

      const nft_marketplace = new web3.eth.Contract(
        NFT_MARKETPLACE_ABI,
        deployedNetwork && NFT_MaketPlace_ADDRESS, provider
      );

      const renting_contract = new web3.eth.Contract(
        RENTING_ABI,
        deployedNetwork && RENTING_CONTRACT, provider
      );

      const contractMember = new web3.eth.Contract(
        CSM_MEMBER_ABI,
        deployedNetwork && Caesium_Member_CONTRACT, provider
      );

      this.setState({ renting_contract, contractMember, web3, nft_asset_contract, accounts:accounts, nft_marketplace},()=>{
        this.fetchData();
      });
    } catch (error) {
        console.log('setWeb3',error);
    }
  }

  doRevokeOrder = async (selected_index) => {
    try {
      if(!this.state.accounts){
        enqueueSnackbar('Please connect to wallet.', { variant: "error" });
        this.setState({ loading: false });
        return false;
      }

      let weiValue = 0;
      this.setState({loading:true});      
      let gasPrice = await this.state.web3.eth.getGasPrice();
      gasPrice = parseInt(gasPrice) + 20;
      let gasLimit = 10000000;
      let estimateGas = 100000;

      let options = {
        from: this.state.accounts,
        gas: gasLimit,
        value:weiValue
      };

      let data = await this.state.renting_contract.methods.revoke(
        selected_index.toString(),this.props?.nft_id
      ).estimateGas(options);

      estimateGas = parseInt(data) + 10000;
      let claim_data = await this.state.renting_contract.methods.revoke(
        selected_index.toString(),this.props?.nft_id
        ).send({
          from: this.state.accounts,
          value: weiValue,
          data: {
            "inputs": [
              {
                "internalType": "uint256",
                "name": "index",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "itemId",
                "type": "uint256"
              }
            ],
            "name": "revoke",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
          },
          gas: estimateGas.toString(),
          gasPrice: gasPrice.toString(),
        });

      if (claim_data.status) {
        this.setState({ loading: false,input_quantity:'' });
        enqueueSnackbar("NFT Revoked Successfully!", { variant: "success" });
        this.fetchData();
      } else {
        this.setState({ loading: false });
        enqueueSnackbar("NFT Revoked failed!");
      }      

    } catch (error) {
      this.setState({ loading: false });
      console.log('buy nft error', error);
      if (error.message.includes("User denied transaction signature")) {
        // handle the "error" as a rejection
        enqueueSnackbar(error.message, { variant: "error" });
      } else {
        enqueueSnackbar(error, { variant: "error" });
      }
    }
  }

  doActivateOrder = async (selected_index) => {
    try {
      if(!this.state.accounts){
        enqueueSnackbar('Please connect to wallet.', { variant: "error" });
        this.setState({ loading: false });
        return false;
      }

      let weiValue = 0;
      this.setState({loading:true});      
      let gasPrice = await this.state.web3.eth.getGasPrice();
      gasPrice = parseInt(gasPrice) + 20;
      let gasLimit = 10000000;
      let estimateGas = 100000;

      let options = {
        from: this.state.accounts,
        gas: gasLimit,
        value:weiValue
      };

      let data = await this.state.renting_contract.methods.activeAgain(
        selected_index.toString(),this.props?.nft_id
      ).estimateGas(options);

      estimateGas = parseInt(data) + 10000;
      let claim_data = await this.state.renting_contract.methods.activeAgain(
        selected_index.toString(),this.props?.nft_id
        ).send({
          from: this.state.accounts,
          value: weiValue,
          data: {
            "inputs": [
              {
                "internalType": "uint256",
                "name": "index",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "itemId",
                "type": "uint256"
              }
            ],
            "name": "activeAgain",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
          },
          gas: estimateGas.toString(),
          gasPrice: gasPrice.toString(),
        });

      if (claim_data.status) {
        this.setState({ loading: false,input_quantity:'' });
        enqueueSnackbar("NFT activated Successfully!", { variant: "success" });
        this.fetchData();
      } else {
        this.setState({ loading: false });
        enqueueSnackbar("NFT activate failed!");
      }      

    } catch (error) {
      this.setState({ loading: false });
      console.log('buy nft error', error);
      if (error.message.includes("User denied transaction signature")) {
        // handle the "error" as a rejection
        enqueueSnackbar(error.message, { variant: "error" });
      } else {
        enqueueSnackbar(error, { variant: "error" });
      }
    }
  }


  render() {
    let this2 = this;
    return (
      <Frame withHeader={false} withFooter={true}>

        {this.state.loading ? (
          <div className="loader-container">
            <div className="linespinner"></div>
          </div>
        ) : (null)}

        <header id="header_main" className="pt-3 pb-3 header_1 header-fixed">
          <div className="container">
            <div className="header-inner">
              <div className="header-left">
                <div className="site-logo">
                  <a href="/" className="main-logo">
                    <img src="/assets/img/logo.png" />
                  </a>
                </div>
                <nav id="main-nav" className="main-nav">
                  <ul id="menu-primary-menu" className="menu">
                    {/* <li className="menu-item"><a href="/marketplace">Marketplace</a></li> */}
                    {/* <li className="menu-item"><a href="/my_nft">My Nft</a></li> */}
                   
                  </ul>
                </nav>
              </div>
              <div className="header-right">
                <div className="flat-wallet flex">                  
                  <div className="d-none d-lg-block ms-3">
                    {this.props.isConnected ? (
                      <a
                        disabled={this.state.loading}
                        // onClick={() => this.props.open()}
                        className="gradient_btn_skew btn pointer"
                      >
                        <i className="icon-sign-out-1"></i> {this.state.YourAddress}
                      </a>
                    ) : (
                      <a
                        disabled={this.state.loading}
                        onClick={() => this.props.open()}
                        className="gradient_btn_skew btn pointer"
                      >
                        <i className="icon-sign-out-1"></i> Connect wallet
                      </a>
                    )}
                  </div>
                  <div className="admin_active">
                    <div className="popup-user relative">
                      <div className="user">
                        <img src="/assets/img/avatar-small-09.png" alt="" />
                      </div>
                      <div className="avatar_popup2">
                        <div className="">
                          <div className="links">
                            {/* <a className="block mb-20" href="#">
                              <img src="/assets/img/boy-avater.png" alt="" />
                              <span>My Profile</span>
                            </a> */}
                            {/* <a className="block mb-20" href="#">
                              <img src="/assets/img/eye.png" alt="" />
                              <span>Watchlist</span>
                            </a>
                            <a className="block mb-20" href="/my_nft">
                              <img src="/assets/img/hand-shake.png" alt="" />
                              <span>My NFT</span>
                            </a> */}
                            {this.props.isConnected ? (
                              <a
                                disabled={this.state.loading}
                                onClick={() => this.props.open()}
                                className="block mb-20 pointer"
                              >
                                <img src="/assets/img/logout.png" alt="" />
                                <span> Sign Out</span>
                              </a>
                            ) : (
                              <a
                                disabled={this.state.loading}
                                onClick={() => this.props.open()}
                                className="block mb-20 pointer"
                              >
                                <img src="/assets/img/logout.png" alt="" />
                                <span> Sign In</span>
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mobile-button">
                    <span></span>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="mobile-nav-wrap">
            <div className="overlay-mobile-nav"></div>
            <div className="inner-mobile-nav">
              <a href="/" rel="home" className="main-logo">
                <img id="mobile-logo_header" src="/assets/img/logo.png" />
              </a>
              <div className="mobile-nav-close">
                <svg xmlns="http://www.w3.org/2000/svg" fill="white" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 122.878 122.88" enableBackground="new 0 0 122.878 122.88" space="preserve"><g><path d="M1.426,8.313c-1.901-1.901-1.901-4.984,0-6.886c1.901-1.902,4.984-1.902,6.886,0l53.127,53.127l53.127-53.127 c1.901-1.902,4.984-1.902,6.887,0c1.901,1.901,1.901,4.985,0,6.886L68.324,61.439l53.128,53.128c1.901,1.901,1.901,4.984,0,6.886 c-1.902,1.902-4.985,1.902-6.887,0L61.438,68.326L8.312,121.453c-1.901,1.902-4.984,1.902-6.886,0 c-1.901-1.901-1.901-4.984,0-6.886l53.127-53.128L1.426,8.313L1.426,8.313z" /></g></svg>
              </div>
              <nav id="mobile-main-nav" className="mobile-main-nav">
                <ul id="menu-mobile-menu" className="menu">
                  {/* <li className="menu-item"><a href="/marketplace">Marketplace</a></li>
                  <li className="menu-item"><a href="/my_nft">My Nft</a></li> */}
                </ul>
              </nav>
              <div className="mt-3">
                {this.props.isConnected ? (
                  <a
                    disabled={this.state.loading}
                    onClick={() => this.props.open()}
                    className="gradient_btn_skew btn btn-block pointer"
                  >
                    <i className="icon-sign-out-1"></i> Disconect wallet
                  </a>
                ) : (
                  <a
                    disabled={this.state.loading}
                    onClick={() => this.props.open()}
                    className="gradient_btn_skew btn btn-block pointer"
                  >
                    <i className="icon-sign-out-1"></i> Connect wallet
                  </a>
                )}
              </div>
            </div>
          </div>
        </header>

        <div className="pb-4 pt-4">
          <div className="container">
            <div className="heading-section pb-20" style={{justifyContent:'center'}}>
              <h2 className="tf-title ">My Adds Details</h2>
              <div className='divider'></div>
            </div>
          <div className="row">
          <div className="col-md-12">
            <div className="row g-3">
              {this.state.nft_marketplace_list ? (
                this.state.nft_marketplace_list.length > 0 ? (
                  this.state.nft_marketplace_list.map(function (item, index) {
                    let image_url = item?.nft_details?.image;
                    let countdown_data = Date.now();
                    if(parseInt(item?.deadline) > parseInt(Date.now()/1000)){
                      countdown_data = countdown_data + parseInt(item.deadline)*1000-Date.now();
                    }
                    return (
                      <div className="col-md-4 col-lg-4" key={`nft_list${index}`}>
                        <div className="token_balance_box">
                          <div className="token_balance_box_inner">                            
                            {image_url ? (
                              <img className="coin_img" src={image_url} />
                            ) : (
                              <img className="coin_img" src="/assets/img/csm-nft-no-image.jpg" />
                            )}
                            <div>
                              <h5 style={{marginBottom:5}}>{item.nft_details.name}</h5>
                              {parseInt(item?.deadline) > parseInt(Date.now()/1000) ? (
                                <Countdown key={`dl${index}`} intervalDelay={0}
                                precision={3} date={countdown_data} />
                              ):(
                                <span>00 : 00 : 00</span>
                              )}
                            </div>
                            <h4>Qty: {item.quantity}</h4>                            
                          </div>
                          <p className='add_text'>Purchase Time: <span className='float_right'>{moment(item.startTime*1000).format("LLL")}</span></p>
                          <p className='add_text'>Rent To: <span className='float_right'>{item?.rentUserAddress?.slice(0, 10) + '.....' + item?.rentUserAddress?.slice(item?.rentUserAddress?.length-5, item?.rentUserAddress?.length)}</span></p>
                          <div className='divider'></div>
                          <div className='row g-2'>
                            {item?.isRevoked == false && parseInt(item?.deadline) < parseInt(Date.now()/1000) ?
                            (
                              <>
                                <div className='col-md-6 col-lg-6 col-6' onClick={()=>{
                                  this2.doRevokeOrder(item?.index);
                                }}>
                                  <button disabled={this2.state.loading} className='tf-button style-1 pointer'>Revoke</button>
                                </div>
                                <div className='col-md-6 col-lg-6 col-6' onClick={()=>{
                                  this2.doActivateOrder(item?.index);
                                }}>
                                  <button disabled={this2.state.loading} className='tf-button style-1 pointer'>Activate Again</button>
                                </div>
                              </>
                            ):(
                              <>
                                <div className='col-md-6 col-lg-6 col-6' style={{opacity:0.5}}>
                                  <button disabled className='tf-button style-1'>Revoke</button>
                                </div>
                                <div className='col-md-6 col-lg-6 col-6' style={{opacity:0.5}}>
                                  <button disabled className='tf-button style-1'>Activate Again</button>
                                </div>
                              </>
                            )}

                          </div>
                        </div>                       
                      </div>
                    )
                  })
                ) : (
                  <div className='col-md-12'>
                    <div className='heading-section middle_heading text-center justify-content-center'>
                      <img className='no_data_img' src='/assets/img/no_data_found.png' />
                      <h1 className='tf-title mb-0 mt-3'>No data available</h1>
                    </div>
                  </div>
                )
              ) : (
                <div className='col-md-12'>
                  <div className='heading-section middle_heading text-center justify-content-center'>
                    <img className='no_data_img' src='/assets/img/no_data_found.png' />
                    <h1 className='tf-title mb-0 mt-3'>No data available</h1>
                  </div>
                </div>
              )}
            </div>

            {this.state.nft_marketplace_list && this.state.nft_marketplace_list?.length ? (
              <div className="widget-pagination mt-5">
                <ul className="justify-center">
                  <li>
                    <a
                      style={{
                        cursor: this2.state.current_page_m > 1 ? "pointer" : "auto",
                      }}
                      onClick={() => {
                        this2.setState(
                          { current_page_m: this2.state.current_page_m > 1 ? this2.state.current_page_m - 1 : 1 },
                          () => {
                            if (this2.state.load_page_m > 1) {
                              this2.getPaginationDataM();
                            }
                          }
                        );
                      }}
                    ><i className="icon-keyboard_arrow_left"></i></a>
                  </li>
                  {this.state.pagination_m.pages.length > 0
                    ? this.state.pagination_m.pages.map(function (
                      val,
                      index
                    ) {
                      let className = "";
                      if (val == this2.state.current_page_m) {
                        className = "active";
                      }
                      return (
                        <li
                          key={`wlp${index}`}
                          className={className}
                          style={{
                            cursor: val ? "pointer" : "initial",
                          }}
                        >
                          <a
                            onClick={() => {
                              if (val) {
                                this2.setState(
                                  { current_page_m: val },
                                  () => {
                                    this2.getPaginationDataM();
                                  }
                                );
                              }
                            }}
                            dangerouslySetInnerHTML={{
                              __html: val,
                            }}
                          ></a>
                        </li>
                      );
                    })
                    : null}
                  <li>
                    <a
                      style={{
                        cursor: this2.state.current_page_m < this2.state.pagination_m?.totalPages ? "pointer" : "auto",
                      }}
                      onClick={() => {
                        this2.setState(
                          { current_page_m: this2.state.current_page_m < this2.state.pagination_m?.totalPages ? this2.state.current_page_m + 1 : this2.state.pagination_m?.totalPages },
                          () => {
                            if (this2.state.load_page_m < this2.state.pagination_m?.totalPages) {
                              this2.getPaginationDataM();
                            }
                          }
                        );
                      }}
                    ><i className="icon-keyboard_arrow_right"></i></a>
                  </li>
                </ul>
              </div>
            ) : null}
          </div>
        </div>
          </div>
        </div>

      </Frame>
    );
  }
}

function MyAddsDetailsHOC(props) {
  let { nft_id, from_action } = useParams();
  const { open } = useWeb3Modal();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { signer, walletProvider } = useWeb3ModalSigner();
  const navigate = useNavigate();
  if (!nft_id) {
    navigate("/");
  }
  if (!address) {
    navigate("/");
  }
  nft_id = atob(nft_id);
  if(from_action){
    from_action = atob(from_action);
  }

  return <MyAddsDetails
    open={open}
    address={address}
    chainId={chainId}
    isConnected={isConnected}
    signer={signer}
    walletProvider={walletProvider}
    nft_id={nft_id}
    from_action={from_action}
    {...props}
  />;
}

export default MyAddsDetailsHOC;