import React from 'react';

class Header extends React.Component {
  // constructor(props) {
  //   super(props);
  // }
  render() {
    return (
      <header id="header_main" className="pt-3 pb-3 header_1 header-fixed">
        <div className="container">
          <div className="header-inner">
            <div className="header-left">
              <div className="site-logo">
                <a href="/" className="main-logo">
                  <img src="assets/img/logo.png" />
                </a>
              </div>
              <nav id="main-nav" className="main-nav">
                <ul id="menu-primary-menu" className="menu">
                  <li className="menu-item"><a href="#">About</a></li>
                  <li className="menu-item"><a href="/marketplace">Marketplace</a></li>
                  <li className="menu-item"><a href="#">Create</a></li>
                  <li className="menu-item"><a href="#">Stats</a></li>
                </ul>
              </nav>
            </div>
            <div className="header-right">
              <div className="flat-wallet flex">
                <div className="header-search relative">
                  <a href="#" className="show-search">
                    <i className="icon-search"></i>
                  </a>
                  <div className="top-search">
                    <form action="#" method="get" role="search" className="search-form relative">
                      <input type="search" id="search" className="search-field style-1" placeholder="Search..." value="" name="s" title="Search for" required="" />
                      <button className="search search-submit" type="submit" title="Search">
                        <i className="icon-search"></i>
                      </button>
                    </form>
                  </div>
                </div>
                <div className="d-none d-lg-block ms-3">
                  <a href="#" className="gradient_btn_skew btn">
                    <i className="icon-sign-out-1"></i> Login</a>
                </div>
                <div className="admin_active">
                  <div className="popup-user relative">
                    <div className="user">
                      <img src="assets/img/avatar-small-09.png" alt="" />
                    </div>
                    <div className="avatar_popup2">
                      <div className="">
                        <div className="links">
                          <a className="block mb-20" href="#">
                            <img src="assets/img/boy-avater.png" alt="" />
                            <span>My Profile</span>
                          </a>
                          <a className="block mb-20" href="#">
                            <img src="assets/img/eye.png" alt="" />
                            <span>Watchlist</span>
                          </a>
                          <a className="block mb-20" href="#">
                            <img src="assets/img/hand-shake.png" alt="" />
                            <span>Deals</span>
                          </a>
                          <a className="block mb-20" href="#">
                            <img src="assets/img/logout.png" alt="" />
                            <span> Sign Out</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mobile-button">
                  <span></span>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="mobile-nav-wrap">
          <div className="overlay-mobile-nav"></div>
          <div className="inner-mobile-nav">
            <a href="/" rel="home" className="main-logo">
              <img id="mobile-logo_header" src="assets/img/logo.png" />
            </a>
            <div className="mobile-nav-close">
              <svg xmlns="http://www.w3.org/2000/svg" fill="white" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 122.878 122.88" enableBackground="new 0 0 122.878 122.88" space="preserve"><g><path d="M1.426,8.313c-1.901-1.901-1.901-4.984,0-6.886c1.901-1.902,4.984-1.902,6.886,0l53.127,53.127l53.127-53.127 c1.901-1.902,4.984-1.902,6.887,0c1.901,1.901,1.901,4.985,0,6.886L68.324,61.439l53.128,53.128c1.901,1.901,1.901,4.984,0,6.886 c-1.902,1.902-4.985,1.902-6.887,0L61.438,68.326L8.312,121.453c-1.901,1.902-4.984,1.902-6.886,0 c-1.901-1.901-1.901-4.984,0-6.886l53.127-53.128L1.426,8.313L1.426,8.313z" /></g></svg>
            </div>
            <nav id="mobile-main-nav" className="mobile-main-nav">
              <ul id="menu-mobile-menu" className="menu">
                <li className="menu-item"><a href="#">About</a></li>
                <li className="menu-item"><a href="#">Marketplace</a></li>
                <li className="menu-item"><a href="#">Create</a></li>
                <li className="menu-item"><a href="#">Stats</a></li>
              </ul>
            </nav>
            <div className="mt-3">
              <a href="#" className="gradient_btn_skew btn btn-block">
                <i className="icon-sign-out-1"></i>
                Login
              </a>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;