export const GATEFI_ACCESS_KEY = 'nRihEVVkHRUJUKduYEgImfwaikMhgtWP';
export const GATEFI_Secrate_KEY = 'vsxzSPCQGoTFexJBQCYvchCGHWhaKAru';
export const GATEFI_BASE_URL = 'https://api.gatefi.com';
export const GATEFI_Merchant_ID = '1e8cc89f-75a0-4118-a159-ea07c1fc8ff5';
export const NFT_TOKEN_URL = 'https://ipfs.io/ipfs/';
export const SITE_URL = 'https://nftmarketplace.caesiumlab.com/';

export const NFT_MaketPlace_ADDRESS = '0x05779460aDDd96d7B060A697970008fcD9cBd970'; //live
export const NFT_CONTRACT_ADDRESS = '0xd0318172cA38Fb50BAFea286CF14a14A22a30e3E'; //live
export const NFT_ASSET_CONTRACT_ADDRESS = '0xde53bB6e76bD602cCa40CE2482b37a860C35af9E'; //live
export const GAME_INO_CONTRACT_ADDRESS = '0x13E6c2445bb333d06B98363C6c3d5e646AED7399'; //live
export const FARMING_CONTRACT_ADDRESS = '0x8E2eC0De786F681dDDa52E0e0a3D499072E52939'; //live

export const Caesium_Member_CONTRACT = '0x88B2E1D923D5b82df85Fe315B3F1b74061cd4ebd';
export const NFT_STAKING_CONTRACT = '0x5F835b01B73e324d4aadC35238683FEbCD4EE377';
export const RENTING_CONTRACT = '0xbDc6A830A72e207E86b141eb39F3556bE6AfA0d4';

export const apikeyCovalenthq = 'cqt_rQQhRdCHqmtxHwfX7dKGtxmXk7J3';

export const projectId = 'fe5331da52e0e0ec1aeddc58e22e3e1e';

export const binanceSmartChain = {
    chainId: 56,
    name: 'Binance Smart Chain',
    currency: 'BNB',
    explorerUrl: 'https://bscscan.com',
    rpcUrl: 'https://rpc.ankr.com/bsc'
  }
  
export  const metadata = {
    name: 'Caesium',
    description: 'Caesium',
    url: 'https://nftmarketplace.caesiumlab.com/',
    icons: ['https://caesiumlab.com/web/assets/images/token3.png']
}

export const decimal_arr ={
    '0x0000000000000000000000000000000000000000': '18',
    '0x55d398326f99059fF775485246999027B3197955': '18',
    '0xe50e179c5701Ba93c0eaFf5aB005086925FeE4Ab': '8'  
}

export const currency_arr = [
    {
        symbol:'BNB',
        address:'0x0000000000000000000000000000000000000000'
    },
    {
        symbol:'USDT',
        address:'0x55d398326f99059fF775485246999027B3197955'
    },
    {
        symbol:'CSM',
        address:'0xe50e179c5701Ba93c0eaFf5aB005086925FeE4Ab'
    }
];


export const ERC20ABI = [
    {
        "constant": true,
        "inputs": [],
        "name": "name",
        "outputs": [
            {
                "name": "",
                "type": "string"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": false,
        "inputs": [
            {
                "name": "_spender",
                "type": "address"
            },
            {
                "name": "_value",
                "type": "uint256"
            }
        ],
        "name": "approve",
        "outputs": [
            {
                "name": "",
                "type": "bool"
            }
        ],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [],
        "name": "totalSupply",
        "outputs": [
            {
                "name": "",
                "type": "uint256"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": false,
        "inputs": [
            {
                "name": "_from",
                "type": "address"
            },
            {
                "name": "_to",
                "type": "address"
            },
            {
                "name": "_value",
                "type": "uint256"
            }
        ],
        "name": "transferFrom",
        "outputs": [
            {
                "name": "",
                "type": "bool"
            }
        ],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [],
        "name": "decimals",
        "outputs": [
            {
                "name": "",
                "type": "uint8"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [
            {
                "name": "_owner",
                "type": "address"
            }
        ],
        "name": "balanceOf",
        "outputs": [
            {
                "name": "balance",
                "type": "uint256"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [],
        "name": "symbol",
        "outputs": [
            {
                "name": "",
                "type": "string"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": false,
        "inputs": [
            {
                "name": "_to",
                "type": "address"
            },
            {
                "name": "_value",
                "type": "uint256"
            }
        ],
        "name": "transfer",
        "outputs": [
            {
                "name": "",
                "type": "bool"
            }
        ],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [
            {
                "name": "_owner",
                "type": "address"
            },
            {
                "name": "_spender",
                "type": "address"
            }
        ],
        "name": "allowance",
        "outputs": [
            {
                "name": "",
                "type": "uint256"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "payable": true,
        "stateMutability": "payable",
        "type": "fallback"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "name": "owner",
                "type": "address"
            },
            {
                "indexed": true,
                "name": "spender",
                "type": "address"
            },
            {
                "indexed": false,
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "Approval",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "name": "from",
                "type": "address"
            },
            {
                "indexed": true,
                "name": "to",
                "type": "address"
            },
            {
                "indexed": false,
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "Transfer",
        "type": "event"
    }
];

export const ERC1155ABI = [
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "initialOwner",
          "type": "address"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "constructor"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "sender",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "balance",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "needed",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        }
      ],
      "name": "ERC1155InsufficientBalance",
      "type": "error"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "approver",
          "type": "address"
        }
      ],
      "name": "ERC1155InvalidApprover",
      "type": "error"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "idsLength",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "valuesLength",
          "type": "uint256"
        }
      ],
      "name": "ERC1155InvalidArrayLength",
      "type": "error"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "operator",
          "type": "address"
        }
      ],
      "name": "ERC1155InvalidOperator",
      "type": "error"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "receiver",
          "type": "address"
        }
      ],
      "name": "ERC1155InvalidReceiver",
      "type": "error"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "sender",
          "type": "address"
        }
      ],
      "name": "ERC1155InvalidSender",
      "type": "error"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "operator",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "owner",
          "type": "address"
        }
      ],
      "name": "ERC1155MissingApprovalForAll",
      "type": "error"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "owner",
          "type": "address"
        }
      ],
      "name": "OwnableInvalidOwner",
      "type": "error"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "account",
          "type": "address"
        }
      ],
      "name": "OwnableUnauthorizedAccount",
      "type": "error"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "account",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "operator",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "bool",
          "name": "approved",
          "type": "bool"
        }
      ],
      "name": "ApprovalForAll",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "previousOwner",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "newOwner",
          "type": "address"
        }
      ],
      "name": "OwnershipTransferred",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "operator",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "from",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "to",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "uint256[]",
          "name": "ids",
          "type": "uint256[]"
        },
        {
          "indexed": false,
          "internalType": "uint256[]",
          "name": "values",
          "type": "uint256[]"
        }
      ],
      "name": "TransferBatch",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "operator",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "from",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "to",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "id",
          "type": "uint256"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "value",
          "type": "uint256"
        }
      ],
      "name": "TransferSingle",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": false,
          "internalType": "string",
          "name": "value",
          "type": "string"
        },
        {
          "indexed": true,
          "internalType": "uint256",
          "name": "id",
          "type": "uint256"
        }
      ],
      "name": "URI",
      "type": "event"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "account",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "id",
          "type": "uint256"
        }
      ],
      "name": "balanceOf",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address[]",
          "name": "accounts",
          "type": "address[]"
        },
        {
          "internalType": "uint256[]",
          "name": "ids",
          "type": "uint256[]"
        }
      ],
      "name": "balanceOfBatch",
      "outputs": [
        {
          "internalType": "uint256[]",
          "name": "",
          "type": "uint256[]"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "account",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "id",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "value",
          "type": "uint256"
        }
      ],
      "name": "burn",
      "outputs": [
  
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "account",
          "type": "address"
        },
        {
          "internalType": "uint256[]",
          "name": "ids",
          "type": "uint256[]"
        },
        {
          "internalType": "uint256[]",
          "name": "values",
          "type": "uint256[]"
        }
      ],
      "name": "burnBatch",
      "outputs": [
  
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_tokenId",
          "type": "uint256"
        }
      ],
      "name": "geturi",
      "outputs": [
        {
          "internalType": "string",
          "name": "tokenUri",
          "type": "string"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "account",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "operator",
          "type": "address"
        }
      ],
      "name": "isApprovedForAll",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "account",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "id",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        },
        {
          "internalType": "bytes",
          "name": "data",
          "type": "bytes"
        }
      ],
      "name": "mint",
      "outputs": [
  
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "to",
          "type": "address"
        },
        {
          "internalType": "uint256[]",
          "name": "ids",
          "type": "uint256[]"
        },
        {
          "internalType": "uint256[]",
          "name": "amounts",
          "type": "uint256[]"
        },
        {
          "internalType": "bytes",
          "name": "data",
          "type": "bytes"
        }
      ],
      "name": "mintBatch",
      "outputs": [
  
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
  
      ],
      "name": "owner",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
  
      ],
      "name": "renounceOwnership",
      "outputs": [
  
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "from",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "to",
          "type": "address"
        },
        {
          "internalType": "uint256[]",
          "name": "ids",
          "type": "uint256[]"
        },
        {
          "internalType": "uint256[]",
          "name": "values",
          "type": "uint256[]"
        },
        {
          "internalType": "bytes",
          "name": "data",
          "type": "bytes"
        }
      ],
      "name": "safeBatchTransferFrom",
      "outputs": [
  
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "from",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "to",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "id",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "value",
          "type": "uint256"
        },
        {
          "internalType": "bytes",
          "name": "data",
          "type": "bytes"
        }
      ],
      "name": "safeTransferFrom",
      "outputs": [
  
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "operator",
          "type": "address"
        },
        {
          "internalType": "bool",
          "name": "approved",
          "type": "bool"
        }
      ],
      "name": "setApprovalForAll",
      "outputs": [
  
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "string",
          "name": "newuri",
          "type": "string"
        }
      ],
      "name": "setURI",
      "outputs": [
  
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "bytes4",
          "name": "interfaceId",
          "type": "bytes4"
        }
      ],
      "name": "supportsInterface",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "newOwner",
          "type": "address"
        }
      ],
      "name": "transferOwnership",
      "outputs": [
  
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "name": "uri",
      "outputs": [
        {
          "internalType": "string",
          "name": "",
          "type": "string"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }
];

export const GAME_INO_ABI = [
    {
      "inputs": [
        {
          "components": [
            {
              "internalType": "uint256",
              "name": "tokenId",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "totalQuantity",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "soldQuantity",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "deadLine",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "initalPrice",
              "type": "uint256"
            },
            {
              "internalType": "string",
              "name": "category",
              "type": "string"
            },
            {
              "internalType": "uint256",
              "name": "finalPrice",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "discountPer",
              "type": "uint256"
            },
            {
              "internalType": "bool",
              "name": "isClaimBack",
              "type": "bool"
            },
            {
              "internalType": "bool",
              "name": "isAvilableForClaim",
              "type": "bool"
            }
          ],
          "internalType": "struct CaesiumINOV5.NFTDetails",
          "name": "params",
          "type": "tuple"
        }
      ],
      "name": "CreateList",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "IsInitinalized",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "int256",
          "name": "_amount",
          "type": "int256"
        }
      ],
      "name": "TotalusdPrice",
      "outputs": [
        {
          "internalType": "int256",
          "name": "",
          "type": "int256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "addFunds",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "admin",
      "outputs": [
        {
          "internalType": "address payable",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "baseDivider",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "quantity",
          "type": "uint256"
        }
      ],
      "name": "buyNFT",
      "outputs": [],
      "stateMutability": "payable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "caesiumRouter",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "index",
          "type": "uint256"
        }
      ],
      "name": "claim",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_amount",
          "type": "uint256"
        }
      ],
      "name": "getCalculatedBNBRecieved",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        }
      ],
      "name": "getCurrentPer",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "per",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "total",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "divquantity",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "initalPrice",
          "type": "uint256"
        }
      ],
      "name": "getCurrentPrice",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "per",
          "type": "uint256"
        }
      ],
      "stateMutability": "pure",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "getItems",
      "outputs": [
        {
          "components": [
            {
              "internalType": "uint256",
              "name": "tokenId",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "totalQuantity",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "soldQuantity",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "deadLine",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "initalPrice",
              "type": "uint256"
            },
            {
              "internalType": "string",
              "name": "category",
              "type": "string"
            },
            {
              "internalType": "uint256",
              "name": "finalPrice",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "discountPer",
              "type": "uint256"
            },
            {
              "internalType": "bool",
              "name": "isClaimBack",
              "type": "bool"
            },
            {
              "internalType": "bool",
              "name": "isAvilableForClaim",
              "type": "bool"
            }
          ],
          "internalType": "struct CaesiumINOV5.NFTDetails[]",
          "name": "data",
          "type": "tuple[]"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "string",
          "name": "category",
          "type": "string"
        }
      ],
      "name": "getItemsByCategory",
      "outputs": [
        {
          "components": [
            {
              "internalType": "uint256",
              "name": "tokenId",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "totalQuantity",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "soldQuantity",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "deadLine",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "initalPrice",
              "type": "uint256"
            },
            {
              "internalType": "string",
              "name": "category",
              "type": "string"
            },
            {
              "internalType": "uint256",
              "name": "finalPrice",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "discountPer",
              "type": "uint256"
            },
            {
              "internalType": "bool",
              "name": "isClaimBack",
              "type": "bool"
            },
            {
              "internalType": "bool",
              "name": "isAvilableForClaim",
              "type": "bool"
            }
          ],
          "internalType": "struct CaesiumINOV5.NFTDetails[]",
          "name": "",
          "type": "tuple[]"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "getLatestPrice",
      "outputs": [
        {
          "internalType": "int256",
          "name": "",
          "type": "int256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_nftid",
          "type": "uint256"
        }
      ],
      "name": "getNftData",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "totalQuantity",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "soldQuantity",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "deadLine",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "initalPrice",
          "type": "uint256"
        },
        {
          "internalType": "string",
          "name": "category",
          "type": "string"
        },
        {
          "internalType": "uint256",
          "name": "finalPrice",
          "type": "uint256"
        },
        {
          "internalType": "bool",
          "name": "isClaimBack",
          "type": "bool"
        },
        {
          "internalType": "bool",
          "name": "isAvilableForClaim",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_user",
          "type": "address"
        }
      ],
      "name": "getPurchaseData",
      "outputs": [
        {
          "components": [
            {
              "internalType": "uint256",
              "name": "tokenId",
              "type": "uint256"
            },
            {
              "internalType": "bool",
              "name": "isClaimed",
              "type": "bool"
            },
            {
              "internalType": "uint256",
              "name": "totalPayedamount",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "quantity",
              "type": "uint256"
            },
            {
              "internalType": "address",
              "name": "user",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "purchaseTime",
              "type": "uint256"
            }
          ],
          "internalType": "struct CaesiumINOV5.OrderDetails[]",
          "name": "data",
          "type": "tuple[]"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        }
      ],
      "name": "getStatus",
      "outputs": [
        {
          "internalType": "bool",
          "name": "isAvilableForClaim",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address payable",
          "name": "_admin",
          "type": "address"
        },
        {
          "internalType": "address payable",
          "name": "_owner",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "_nft",
          "type": "address"
        },
        {
          "internalType": "contract Caesium_memberShip",
          "name": "_add",
          "type": "address"
        }
      ],
      "name": "initinalize",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "name": "listedTokenIds",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "marketPlace",
      "outputs": [
        {
          "internalType": "contract MarketPlace",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "marketPlaceAdd",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "memberContract",
      "outputs": [
        {
          "internalType": "contract Caesium_memberShip",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "nftAddress",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "nftContract",
      "outputs": [
        {
          "internalType": "contract BEP1155",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "nftStakeing",
      "outputs": [
        {
          "internalType": "contract NFTStaking",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "nftStakeingAdd",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "name": "nftdata",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "totalQuantity",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "soldQuantity",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "deadLine",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "initalPrice",
          "type": "uint256"
        },
        {
          "internalType": "string",
          "name": "category",
          "type": "string"
        },
        {
          "internalType": "uint256",
          "name": "finalPrice",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "discountPer",
          "type": "uint256"
        },
        {
          "internalType": "bool",
          "name": "isClaimBack",
          "type": "bool"
        },
        {
          "internalType": "bool",
          "name": "isAvilableForClaim",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        },
        {
          "internalType": "uint256[]",
          "name": "",
          "type": "uint256[]"
        },
        {
          "internalType": "uint256[]",
          "name": "",
          "type": "uint256[]"
        },
        {
          "internalType": "bytes",
          "name": "",
          "type": "bytes"
        }
      ],
      "name": "onERC1155BatchReceived",
      "outputs": [
        {
          "internalType": "bytes4",
          "name": "",
          "type": "bytes4"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        },
        {
          "internalType": "bytes",
          "name": "",
          "type": "bytes"
        }
      ],
      "name": "onERC1155Received",
      "outputs": [
        {
          "internalType": "bytes4",
          "name": "",
          "type": "bytes4"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "owner",
      "outputs": [
        {
          "internalType": "address payable",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_userr",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }
      ],
      "name": "payBack",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        }
      ],
      "name": "priceMaker",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "name": "purchaseOrders",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        },
        {
          "internalType": "bool",
          "name": "isClaimed",
          "type": "bool"
        },
        {
          "internalType": "uint256",
          "name": "totalPayedamount",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "quantity",
          "type": "uint256"
        },
        {
          "internalType": "address",
          "name": "user",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "purchaseTime",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "quna",
          "type": "uint256"
        }
      ],
      "name": "revertBackNFt",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "market",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "staking",
          "type": "address"
        }
      ],
      "name": "setSetting",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "bytes4",
          "name": "interfaceId",
          "type": "bytes4"
        }
      ],
      "name": "supportsInterface",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "name": "tagretPer",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "newdeadLine",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "disper",
          "type": "uint256"
        }
      ],
      "name": "updateNft",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    }
];

export const CSM_MEMBER_ABI = [
    {
      "inputs": [
        {
          "internalType": "int256",
          "name": "_amount",
          "type": "int256"
        }
      ],
      "name": "TotalusdPrice",
      "outputs": [
        {
          "internalType": "int256",
          "name": "",
          "type": "int256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "fee",
          "type": "uint256"
        }
      ],
      "name": "changeMemberShipFee",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_amount",
          "type": "uint256"
        }
      ],
      "name": "getCalculatedBnbRecieved",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "getLatestPrice",
      "outputs": [
        {
          "internalType": "int256",
          "name": "",
          "type": "int256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "getMembersdata",
      "outputs": [
        {
          "internalType": "address[]",
          "name": "data",
          "type": "address[]"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_user",
          "type": "address"
        }
      ],
      "name": "getUserdata",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "amountInusd",
          "type": "uint256"
        },
        {
          "internalType": "bool",
          "name": "memberShipStatus",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address payable",
          "name": "_admin",
          "type": "address"
        }
      ],
      "name": "initialize",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "invest",
      "outputs": [],
      "stateMutability": "payable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "name": "members",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "minFee",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address payable",
          "name": "_add",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }
      ],
      "name": "payBack",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "name": "users",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "invest",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "investInUsd",
          "type": "uint256"
        },
        {
          "internalType": "bool",
          "name": "memberShipStatus",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }
];

export const NFT_MARKETPLACE_ABI = [
    {
       "inputs":[
          
       ],
       "name":"IsInitinalized",
       "outputs":[
          {
             "internalType":"bool",
             "name":"",
             "type":"bool"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          {
             "internalType":"int256",
             "name":"_amount",
             "type":"int256"
          }
       ],
       "name":"TotalusdPrice",
       "outputs":[
          {
             "internalType":"int256",
             "name":"",
             "type":"int256"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          {
             "internalType":"uint256",
             "name":"amount",
             "type":"uint256"
          }
       ],
       "name":"calculatedToken",
       "outputs":[
          {
             "internalType":"uint256",
             "name":"token",
             "type":"uint256"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          {
             "internalType":"address",
             "name":"_user",
             "type":"address"
          },
          {
             "internalType":"address",
             "name":"nftContractadd",
             "type":"address"
          },
          {
             "internalType":"uint256",
             "name":"tokenId",
             "type":"uint256"
          },
          {
             "internalType":"uint256",
             "name":"price",
             "type":"uint256"
          },
          {
             "internalType":"address",
             "name":"currency",
             "type":"address"
          },
          {
             "internalType":"uint256",
             "name":"quantity",
             "type":"uint256"
          },
          {
             "internalType":"string",
             "name":"category",
             "type":"string"
          }
       ],
       "name":"createListing",
       "outputs":[
          
       ],
       "stateMutability":"payable",
       "type":"function"
    },
    {
       "inputs":[
          {
             "internalType":"address",
             "name":"nftContractadd",
             "type":"address"
          },
          {
             "internalType":"uint256",
             "name":"tokenId",
             "type":"uint256"
          },
          {
             "internalType":"uint256",
             "name":"price",
             "type":"uint256"
          },
          {
             "internalType":"address",
             "name":"currency",
             "type":"address"
          },
          {
             "internalType":"uint256",
             "name":"quantity",
             "type":"uint256"
          },
          {
             "internalType":"string",
             "name":"category",
             "type":"string"
          }
       ],
       "name":"createMarketItem",
       "outputs":[
          
       ],
       "stateMutability":"payable",
       "type":"function"
    },
    {
       "inputs":[
          {
             "internalType":"uint256",
             "name":"itemId",
             "type":"uint256"
          },
          {
             "internalType":"uint256",
             "name":"quantity",
             "type":"uint256"
          }
       ],
       "name":"createMarketSale",
       "outputs":[
          
       ],
       "stateMutability":"payable",
       "type":"function"
    },
    {
       "inputs":[
          
       ],
       "name":"csmMaster",
       "outputs":[
          {
             "internalType":"contract CSM_Master_v2",
             "name":"",
             "type":"address"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          
       ],
       "name":"currentFee",
       "outputs":[
          {
             "internalType":"uint256",
             "name":"",
             "type":"uint256"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          
       ],
       "name":"feeRev",
       "outputs":[
          {
             "internalType":"address",
             "name":"",
             "type":"address"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          
       ],
       "name":"fetchCreateNFTs",
       "outputs":[
          {
             "components":[
                {
                   "internalType":"uint256",
                   "name":"itemId",
                   "type":"uint256"
                },
                {
                   "internalType":"address",
                   "name":"nftContract",
                   "type":"address"
                },
                {
                   "internalType":"uint256",
                   "name":"tokenId",
                   "type":"uint256"
                },
                {
                   "internalType":"address payable",
                   "name":"seller",
                   "type":"address"
                },
                {
                   "internalType":"address payable",
                   "name":"owner",
                   "type":"address"
                },
                {
                   "internalType":"string",
                   "name":"category",
                   "type":"string"
                },
                {
                   "internalType":"uint256",
                   "name":"price",
                   "type":"uint256"
                },
                {
                   "internalType":"address",
                   "name":"currency",
                   "type":"address"
                },
                {
                   "internalType":"uint256",
                   "name":"quantity",
                   "type":"uint256"
                },
                {
                   "internalType":"bool",
                   "name":"isSold",
                   "type":"bool"
                }
             ],
             "internalType":"struct AlphaReturnsNFTMarket.MarketItem[]",
             "name":"",
             "type":"tuple[]"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          
       ],
       "name":"fetchPurchasedNFTs",
       "outputs":[
          {
             "components":[
                {
                   "internalType":"uint256",
                   "name":"itemId",
                   "type":"uint256"
                },
                {
                   "internalType":"address",
                   "name":"nftContract",
                   "type":"address"
                },
                {
                   "internalType":"uint256",
                   "name":"tokenId",
                   "type":"uint256"
                },
                {
                   "internalType":"address payable",
                   "name":"seller",
                   "type":"address"
                },
                {
                   "internalType":"address payable",
                   "name":"owner",
                   "type":"address"
                },
                {
                   "internalType":"string",
                   "name":"category",
                   "type":"string"
                },
                {
                   "internalType":"uint256",
                   "name":"price",
                   "type":"uint256"
                },
                {
                   "internalType":"address",
                   "name":"currency",
                   "type":"address"
                },
                {
                   "internalType":"uint256",
                   "name":"quantity",
                   "type":"uint256"
                },
                {
                   "internalType":"bool",
                   "name":"isSold",
                   "type":"bool"
                }
             ],
             "internalType":"struct AlphaReturnsNFTMarket.MarketItem[]",
             "name":"",
             "type":"tuple[]"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          {
             "internalType":"address",
             "name":"_user",
             "type":"address"
          },
          {
             "internalType":"uint256",
             "name":"qty",
             "type":"uint256"
          },
          {
             "internalType":"uint256",
             "name":"tokenid",
             "type":"uint256"
          }
       ],
       "name":"getBackNfts",
       "outputs":[
          
       ],
       "stateMutability":"nonpayable",
       "type":"function"
    },
    {
       "inputs":[
          {
             "internalType":"uint256",
             "name":"_amount",
             "type":"uint256"
          }
       ],
       "name":"getCalculatedBnbRecieved",
       "outputs":[
          {
             "internalType":"uint256",
             "name":"",
             "type":"uint256"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          {
             "internalType":"string",
             "name":"category",
             "type":"string"
          }
       ],
       "name":"getItemsByCategory",
       "outputs":[
          {
             "components":[
                {
                   "internalType":"uint256",
                   "name":"itemId",
                   "type":"uint256"
                },
                {
                   "internalType":"address",
                   "name":"nftContract",
                   "type":"address"
                },
                {
                   "internalType":"uint256",
                   "name":"tokenId",
                   "type":"uint256"
                },
                {
                   "internalType":"address payable",
                   "name":"seller",
                   "type":"address"
                },
                {
                   "internalType":"address payable",
                   "name":"owner",
                   "type":"address"
                },
                {
                   "internalType":"string",
                   "name":"category",
                   "type":"string"
                },
                {
                   "internalType":"uint256",
                   "name":"price",
                   "type":"uint256"
                },
                {
                   "internalType":"address",
                   "name":"currency",
                   "type":"address"
                },
                {
                   "internalType":"uint256",
                   "name":"quantity",
                   "type":"uint256"
                },
                {
                   "internalType":"bool",
                   "name":"isSold",
                   "type":"bool"
                }
             ],
             "internalType":"struct AlphaReturnsNFTMarket.MarketItem[]",
             "name":"",
             "type":"tuple[]"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          
       ],
       "name":"getLatestPrice",
       "outputs":[
          {
             "internalType":"int256",
             "name":"",
             "type":"int256"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          
       ],
       "name":"getMarketItems",
       "outputs":[
          {
             "components":[
                {
                   "internalType":"uint256",
                   "name":"itemId",
                   "type":"uint256"
                },
                {
                   "internalType":"address",
                   "name":"nftContract",
                   "type":"address"
                },
                {
                   "internalType":"uint256",
                   "name":"tokenId",
                   "type":"uint256"
                },
                {
                   "internalType":"address payable",
                   "name":"seller",
                   "type":"address"
                },
                {
                   "internalType":"address payable",
                   "name":"owner",
                   "type":"address"
                },
                {
                   "internalType":"string",
                   "name":"category",
                   "type":"string"
                },
                {
                   "internalType":"uint256",
                   "name":"price",
                   "type":"uint256"
                },
                {
                   "internalType":"address",
                   "name":"currency",
                   "type":"address"
                },
                {
                   "internalType":"uint256",
                   "name":"quantity",
                   "type":"uint256"
                },
                {
                   "internalType":"bool",
                   "name":"isSold",
                   "type":"bool"
                }
             ],
             "internalType":"struct AlphaReturnsNFTMarket.MarketItem[]",
             "name":"",
             "type":"tuple[]"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          {
             "internalType":"uint256",
             "name":"_id",
             "type":"uint256"
          }
       ],
       "name":"getNftByListingId",
       "outputs":[
          {
             "components":[
                {
                   "internalType":"uint256",
                   "name":"itemId",
                   "type":"uint256"
                },
                {
                   "internalType":"address",
                   "name":"nftContract",
                   "type":"address"
                },
                {
                   "internalType":"uint256",
                   "name":"tokenId",
                   "type":"uint256"
                },
                {
                   "internalType":"address payable",
                   "name":"seller",
                   "type":"address"
                },
                {
                   "internalType":"address payable",
                   "name":"owner",
                   "type":"address"
                },
                {
                   "internalType":"string",
                   "name":"category",
                   "type":"string"
                },
                {
                   "internalType":"uint256",
                   "name":"price",
                   "type":"uint256"
                },
                {
                   "internalType":"address",
                   "name":"currency",
                   "type":"address"
                },
                {
                   "internalType":"uint256",
                   "name":"quantity",
                   "type":"uint256"
                },
                {
                   "internalType":"bool",
                   "name":"isSold",
                   "type":"bool"
                }
             ],
             "internalType":"struct AlphaReturnsNFTMarket.MarketItem",
             "name":"data",
             "type":"tuple"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          
       ],
       "name":"getStats",
       "outputs":[
          {
             "internalType":"uint256",
             "name":"feee",
             "type":"uint256"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          {
             "internalType":"address payable",
             "name":"_admin",
             "type":"address"
          },
          {
             "internalType":"address",
             "name":"_feeRev",
             "type":"address"
          },
          {
             "internalType":"contract NFTTOKEN",
             "name":"_dd",
             "type":"address"
          },
          {
             "internalType":"address",
             "name":"_nft",
             "type":"address"
          },
          {
             "internalType":"address",
             "name":"_ino",
             "type":"address"
          },
          {
             "internalType":"address",
             "name":"_ddd",
             "type":"address"
          },
          {
             "internalType":"address",
             "name":"_liq",
             "type":"address"
          }
       ],
       "name":"initinalize",
       "outputs":[
          
       ],
       "stateMutability":"nonpayable",
       "type":"function"
    },
    {
       "inputs":[
          
       ],
       "name":"inoContract",
       "outputs":[
          {
             "internalType":"contract INO",
             "name":"",
             "type":"address"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          
       ],
       "name":"inoContractAdd",
       "outputs":[
          {
             "internalType":"address",
             "name":"",
             "type":"address"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          
       ],
       "name":"liquidityContract",
       "outputs":[
          {
             "internalType":"contract LiquidityContract",
             "name":"",
             "type":"address"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          
       ],
       "name":"nftAddress",
       "outputs":[
          {
             "internalType":"address",
             "name":"",
             "type":"address"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          
       ],
       "name":"nftContract",
       "outputs":[
          {
             "internalType":"contract NFTTOKEN",
             "name":"",
             "type":"address"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          {
             "internalType":"uint256",
             "name":"",
             "type":"uint256"
          },
          {
             "internalType":"uint256",
             "name":"",
             "type":"uint256"
          }
       ],
       "name":"nftOwnersList",
       "outputs":[
          {
             "internalType":"address",
             "name":"",
             "type":"address"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          {
             "internalType":"address",
             "name":"",
             "type":"address"
          },
          {
             "internalType":"address",
             "name":"",
             "type":"address"
          },
          {
             "internalType":"uint256[]",
             "name":"",
             "type":"uint256[]"
          },
          {
             "internalType":"uint256[]",
             "name":"",
             "type":"uint256[]"
          },
          {
             "internalType":"bytes",
             "name":"",
             "type":"bytes"
          }
       ],
       "name":"onERC1155BatchReceived",
       "outputs":[
          {
             "internalType":"bytes4",
             "name":"",
             "type":"bytes4"
          }
       ],
       "stateMutability":"nonpayable",
       "type":"function"
    },
    {
       "inputs":[
          {
             "internalType":"address",
             "name":"",
             "type":"address"
          },
          {
             "internalType":"address",
             "name":"",
             "type":"address"
          },
          {
             "internalType":"uint256",
             "name":"",
             "type":"uint256"
          },
          {
             "internalType":"uint256",
             "name":"",
             "type":"uint256"
          },
          {
             "internalType":"bytes",
             "name":"",
             "type":"bytes"
          }
       ],
       "name":"onERC1155Received",
       "outputs":[
          {
             "internalType":"bytes4",
             "name":"",
             "type":"bytes4"
          }
       ],
       "stateMutability":"nonpayable",
       "type":"function"
    },
    {
       "inputs":[
          
       ],
       "name":"owner",
       "outputs":[
          {
             "internalType":"address payable",
             "name":"",
             "type":"address"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          {
             "internalType":"address",
             "name":"_user",
             "type":"address"
          },
          {
             "internalType":"uint256",
             "name":"amount",
             "type":"uint256"
          },
          {
             "internalType":"address",
             "name":"token",
             "type":"address"
          }
       ],
       "name":"payBack",
       "outputs":[
          
       ],
       "stateMutability":"nonpayable",
       "type":"function"
    },
    {
       "inputs":[
          
       ],
       "name":"platformfee",
       "outputs":[
          {
             "internalType":"uint256",
             "name":"",
             "type":"uint256"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          
       ],
       "name":"royalityFee",
       "outputs":[
          {
             "internalType":"uint256",
             "name":"",
             "type":"uint256"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          {
             "internalType":"address",
             "name":"_ddd",
             "type":"address"
          }
       ],
       "name":"setMaster",
       "outputs":[
          
       ],
       "stateMutability":"nonpayable",
       "type":"function"
    },
    {
       "inputs":[
          
       ],
       "name":"setPriceFeed",
       "outputs":[
          
       ],
       "stateMutability":"nonpayable",
       "type":"function"
    },
    {
       "inputs":[
          {
             "internalType":"address",
             "name":"_staking",
             "type":"address"
          }
       ],
       "name":"setStakingAddress",
       "outputs":[
          
       ],
       "stateMutability":"nonpayable",
       "type":"function"
    },
    {
       "inputs":[
          {
             "internalType":"address",
             "name":"_nft",
             "type":"address"
          }
       ],
       "name":"setaddress",
       "outputs":[
          
       ],
       "stateMutability":"nonpayable",
       "type":"function"
    },
    {
       "inputs":[
          
       ],
       "name":"stakingAddress",
       "outputs":[
          {
             "internalType":"address",
             "name":"",
             "type":"address"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          {
             "internalType":"bytes4",
             "name":"interfaceId",
             "type":"bytes4"
          }
       ],
       "name":"supportsInterface",
       "outputs":[
          {
             "internalType":"bool",
             "name":"",
             "type":"bool"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          
       ],
       "name":"tokenContract",
       "outputs":[
          {
             "internalType":"contract BEP20TOKEN",
             "name":"",
             "type":"address"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          {
             "internalType":"uint256",
             "name":"bal",
             "type":"uint256"
          }
       ],
       "name":"withdrawBal",
       "outputs":[
          
       ],
       "stateMutability":"nonpayable",
       "type":"function"
    }
];

export const NFT_STAKING_ABI = [
    {
       "inputs":[
          
       ],
       "name":"IsInitinalized",
       "outputs":[
          {
             "internalType":"bool",
             "name":"",
             "type":"bool"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          {
             "internalType":"int256",
             "name":"_amount",
             "type":"int256"
          }
       ],
       "name":"TotalusdPrice",
       "outputs":[
          {
             "internalType":"int256",
             "name":"",
             "type":"int256"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          
       ],
       "name":"csmMaster",
       "outputs":[
          {
             "internalType":"contract CSM_Master_v2",
             "name":"",
             "type":"address"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          
       ],
       "name":"currentTotalQtyStake",
       "outputs":[
          {
             "internalType":"uint256",
             "name":"",
             "type":"uint256"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          {
             "internalType":"uint256",
             "name":"",
             "type":"uint256"
          }
       ],
       "name":"distributionData",
       "outputs":[
          {
             "internalType":"uint256",
             "name":"totalamount",
             "type":"uint256"
          },
          {
             "internalType":"uint256",
             "name":"perQtyamount",
             "type":"uint256"
          },
          {
             "internalType":"uint256",
             "name":"totalQty",
             "type":"uint256"
          },
          {
             "internalType":"bool",
             "name":"istakeFromContract",
             "type":"bool"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          {
             "internalType":"uint256",
             "name":"_amount",
             "type":"uint256"
          }
       ],
       "name":"getCalculatedBnbRecieved",
       "outputs":[
          {
             "internalType":"uint256",
             "name":"",
             "type":"uint256"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          {
             "internalType":"uint256",
             "name":"time",
             "type":"uint256"
          }
       ],
       "name":"getCurDay",
       "outputs":[
          {
             "internalType":"uint256",
             "name":"_day",
             "type":"uint256"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          
       ],
       "name":"getLatestPrice",
       "outputs":[
          {
             "internalType":"int256",
             "name":"",
             "type":"int256"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          {
             "internalType":"address",
             "name":"_user",
             "type":"address"
          }
       ],
       "name":"getUserData",
       "outputs":[
          {
             "components":[
                {
                   "internalType":"uint256",
                   "name":"nftId",
                   "type":"uint256"
                },
                {
                   "internalType":"uint256",
                   "name":"createdTime",
                   "type":"uint256"
                },
                {
                   "internalType":"uint256",
                   "name":"quantity",
                   "type":"uint256"
                },
                {
                   "internalType":"uint256",
                   "name":"earntaken",
                   "type":"uint256"
                },
                {
                   "internalType":"uint256",
                   "name":"startDay",
                   "type":"uint256"
                },
                {
                   "internalType":"uint256",
                   "name":"endDay",
                   "type":"uint256"
                },
                {
                   "internalType":"bool",
                   "name":"isUnstake",
                   "type":"bool"
                }
             ],
             "internalType":"struct NFTStakingV1.UserStakeLog[]",
             "name":"data",
             "type":"tuple[]"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          {
             "internalType":"address",
             "name":"user",
             "type":"address"
          },
          {
             "internalType":"uint256",
             "name":"index",
             "type":"uint256"
          }
       ],
       "name":"getUserEarnData",
       "outputs":[
          {
             "internalType":"uint256",
             "name":"income",
             "type":"uint256"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          {
             "internalType":"address payable",
             "name":"_admin",
             "type":"address"
          },
          {
             "internalType":"contract NFTTOKEN",
             "name":"_dd",
             "type":"address"
          },
          {
             "internalType":"address",
             "name":"_nft",
             "type":"address"
          },
          {
             "internalType":"address",
             "name":"_market",
             "type":"address"
          }
       ],
       "name":"initinalize",
       "outputs":[
          
       ],
       "stateMutability":"nonpayable",
       "type":"function"
    },
    {
       "inputs":[
          
       ],
       "name":"inoContract",
       "outputs":[
          {
             "internalType":"contract INO",
             "name":"",
             "type":"address"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          
       ],
       "name":"marketPlaceContract",
       "outputs":[
          {
             "internalType":"contract NFTMARKETPLACE",
             "name":"",
             "type":"address"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          
       ],
       "name":"minPool",
       "outputs":[
          {
             "internalType":"uint256",
             "name":"",
             "type":"uint256"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          
       ],
       "name":"nftAddress",
       "outputs":[
          {
             "internalType":"address",
             "name":"",
             "type":"address"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          
       ],
       "name":"nftContract",
       "outputs":[
          {
             "internalType":"contract NFTTOKEN",
             "name":"",
             "type":"address"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          {
             "internalType":"address",
             "name":"",
             "type":"address"
          },
          {
             "internalType":"address",
             "name":"",
             "type":"address"
          },
          {
             "internalType":"uint256[]",
             "name":"",
             "type":"uint256[]"
          },
          {
             "internalType":"uint256[]",
             "name":"",
             "type":"uint256[]"
          },
          {
             "internalType":"bytes",
             "name":"",
             "type":"bytes"
          }
       ],
       "name":"onERC1155BatchReceived",
       "outputs":[
          {
             "internalType":"bytes4",
             "name":"",
             "type":"bytes4"
          }
       ],
       "stateMutability":"nonpayable",
       "type":"function"
    },
    {
       "inputs":[
          {
             "internalType":"address",
             "name":"",
             "type":"address"
          },
          {
             "internalType":"address",
             "name":"",
             "type":"address"
          },
          {
             "internalType":"uint256",
             "name":"",
             "type":"uint256"
          },
          {
             "internalType":"uint256",
             "name":"",
             "type":"uint256"
          },
          {
             "internalType":"bytes",
             "name":"",
             "type":"bytes"
          }
       ],
       "name":"onERC1155Received",
       "outputs":[
          {
             "internalType":"bytes4",
             "name":"",
             "type":"bytes4"
          }
       ],
       "stateMutability":"nonpayable",
       "type":"function"
    },
    {
       "inputs":[
          {
             "internalType":"uint256",
             "name":"index",
             "type":"uint256"
          }
       ],
       "name":"redeem",
       "outputs":[
          
       ],
       "stateMutability":"nonpayable",
       "type":"function"
    },
    {
       "inputs":[
          {
             "internalType":"uint256",
             "name":"nftId",
             "type":"uint256"
          },
          {
             "internalType":"uint256",
             "name":"qty",
             "type":"uint256"
          }
       ],
       "name":"stake",
       "outputs":[
          
       ],
       "stateMutability":"nonpayable",
       "type":"function"
    },
    {
       "inputs":[
          
       ],
       "name":"starttime",
       "outputs":[
          {
             "internalType":"uint256",
             "name":"",
             "type":"uint256"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          {
             "internalType":"bytes4",
             "name":"interfaceId",
             "type":"bytes4"
          }
       ],
       "name":"supportsInterface",
       "outputs":[
          {
             "internalType":"bool",
             "name":"",
             "type":"bool"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          
       ],
       "name":"takenFee",
       "outputs":[
          {
             "internalType":"uint256",
             "name":"",
             "type":"uint256"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          
       ],
       "name":"timeStamp",
       "outputs":[
          {
             "internalType":"uint256",
             "name":"",
             "type":"uint256"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          
       ],
       "name":"tokenContract",
       "outputs":[
          {
             "internalType":"contract BEP20TOKEN",
             "name":"",
             "type":"address"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    },
    {
       "inputs":[
          {
             "internalType":"uint256",
             "name":"index",
             "type":"uint256"
          }
       ],
       "name":"unStake",
       "outputs":[
          
       ],
       "stateMutability":"nonpayable",
       "type":"function"
    },
    {
       "inputs":[
          {
             "internalType":"address",
             "name":"",
             "type":"address"
          },
          {
             "internalType":"uint256",
             "name":"",
             "type":"uint256"
          }
       ],
       "name":"userStakeData",
       "outputs":[
          {
             "internalType":"uint256",
             "name":"nftId",
             "type":"uint256"
          },
          {
             "internalType":"uint256",
             "name":"createdTime",
             "type":"uint256"
          },
          {
             "internalType":"uint256",
             "name":"quantity",
             "type":"uint256"
          },
          {
             "internalType":"uint256",
             "name":"earntaken",
             "type":"uint256"
          },
          {
             "internalType":"uint256",
             "name":"startDay",
             "type":"uint256"
          },
          {
             "internalType":"uint256",
             "name":"endDay",
             "type":"uint256"
          },
          {
             "internalType":"bool",
             "name":"isUnstake",
             "type":"bool"
          }
       ],
       "stateMutability":"view",
       "type":"function"
    }
];

export const RENTING_ABI = [
  {
     "inputs":[
        
     ],
     "name":"IsInitinalized",
     "outputs":[
        {
           "internalType":"bool",
           "name":"",
           "type":"bool"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"int256",
           "name":"_amount",
           "type":"int256"
        }
     ],
     "name":"TotalusdPrice",
     "outputs":[
        {
           "internalType":"int256",
           "name":"",
           "type":"int256"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"uint256",
           "name":"index",
           "type":"uint256"
        },
        {
           "internalType":"uint256",
           "name":"itemId",
           "type":"uint256"
        }
     ],
     "name":"activeAgain",
     "outputs":[
        
     ],
     "stateMutability":"nonpayable",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"uint256",
           "name":"amount",
           "type":"uint256"
        }
     ],
     "name":"calculatedToken",
     "outputs":[
        {
           "internalType":"uint256",
           "name":"token",
           "type":"uint256"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"uint256",
           "name":"tokenId",
           "type":"uint256"
        },
        {
           "internalType":"uint256",
           "name":"price",
           "type":"uint256"
        },
        {
           "internalType":"address",
           "name":"currency",
           "type":"address"
        },
        {
           "internalType":"uint256",
           "name":"quantity",
           "type":"uint256"
        },
        {
           "internalType":"uint256",
           "name":"timePeriod",
           "type":"uint256"
        },
        {
           "internalType":"string",
           "name":"category",
           "type":"string"
        }
     ],
     "name":"createItem",
     "outputs":[
        
     ],
     "stateMutability":"payable",
     "type":"function"
  },
  {
     "inputs":[
        
     ],
     "name":"csmMaster",
     "outputs":[
        {
           "internalType":"contract CSM_Master_v2",
           "name":"",
           "type":"address"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        
     ],
     "name":"currentFee",
     "outputs":[
        {
           "internalType":"uint256",
           "name":"",
           "type":"uint256"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        
     ],
     "name":"feeRev",
     "outputs":[
        {
           "internalType":"address",
           "name":"",
           "type":"address"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"address",
           "name":"_user",
           "type":"address"
        },
        {
           "internalType":"uint256",
           "name":"qty",
           "type":"uint256"
        },
        {
           "internalType":"uint256",
           "name":"tokenid",
           "type":"uint256"
        }
     ],
     "name":"getBackNfts",
     "outputs":[
        
     ],
     "stateMutability":"nonpayable",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"uint256",
           "name":"_amount",
           "type":"uint256"
        }
     ],
     "name":"getCalculatedBnbRecieved",
     "outputs":[
        {
           "internalType":"uint256",
           "name":"",
           "type":"uint256"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"uint256",
           "name":"id",
           "type":"uint256"
        }
     ],
     "name":"getItemHistory",
     "outputs":[
        {
           "components":[
              {
                 "internalType":"uint256",
                 "name":"tokenId",
                 "type":"uint256"
              },
              {
                 "internalType":"uint256",
                 "name":"deadline",
                 "type":"uint256"
              },
              {
                 "internalType":"uint256",
                 "name":"quantity",
                 "type":"uint256"
              },
              {
                 "internalType":"bool",
                 "name":"isRevoked",
                 "type":"bool"
              },
              {
                 "internalType":"uint256",
                 "name":"startTime",
                 "type":"uint256"
              },
              {
                 "internalType":"address",
                 "name":"rentUserAddress",
                 "type":"address"
              }
           ],
           "internalType":"struct CaesiumNFTRentingV1.RentalData[]",
           "name":"data",
           "type":"tuple[]"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"address payable",
           "name":"user",
           "type":"address"
        }
     ],
     "name":"getItemsByAddress",
     "outputs":[
        {
           "components":[
              {
                 "internalType":"uint256",
                 "name":"itemId",
                 "type":"uint256"
              },
              {
                 "internalType":"uint256",
                 "name":"tokenId",
                 "type":"uint256"
              },
              {
                 "components":[
                    {
                       "internalType":"uint256",
                       "name":"tokenId",
                       "type":"uint256"
                    },
                    {
                       "internalType":"uint256",
                       "name":"deadline",
                       "type":"uint256"
                    },
                    {
                       "internalType":"uint256",
                       "name":"quantity",
                       "type":"uint256"
                    },
                    {
                       "internalType":"bool",
                       "name":"isRevoked",
                       "type":"bool"
                    },
                    {
                       "internalType":"uint256",
                       "name":"startTime",
                       "type":"uint256"
                    },
                    {
                       "internalType":"address",
                       "name":"rentUserAddress",
                       "type":"address"
                    }
                 ],
                 "internalType":"struct CaesiumNFTRentingV1.RentalData[]",
                 "name":"history",
                 "type":"tuple[]"
              },
              {
                 "internalType":"string",
                 "name":"category",
                 "type":"string"
              },
              {
                 "internalType":"uint256",
                 "name":"price",
                 "type":"uint256"
              },
              {
                 "internalType":"address",
                 "name":"currency",
                 "type":"address"
              },
              {
                 "internalType":"uint256",
                 "name":"quantity",
                 "type":"uint256"
              },
              {
                 "internalType":"uint256",
                 "name":"timePeriod",
                 "type":"uint256"
              },
              {
                 "internalType":"address payable",
                 "name":"owner",
                 "type":"address"
              }
           ],
           "internalType":"struct CaesiumNFTRentingV1.MarketItem[]",
           "name":"",
           "type":"tuple[]"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"string",
           "name":"category",
           "type":"string"
        }
     ],
     "name":"getItemsByCategory",
     "outputs":[
        {
           "components":[
              {
                 "internalType":"uint256",
                 "name":"itemId",
                 "type":"uint256"
              },
              {
                 "internalType":"uint256",
                 "name":"tokenId",
                 "type":"uint256"
              },
              {
                 "components":[
                    {
                       "internalType":"uint256",
                       "name":"tokenId",
                       "type":"uint256"
                    },
                    {
                       "internalType":"uint256",
                       "name":"deadline",
                       "type":"uint256"
                    },
                    {
                       "internalType":"uint256",
                       "name":"quantity",
                       "type":"uint256"
                    },
                    {
                       "internalType":"bool",
                       "name":"isRevoked",
                       "type":"bool"
                    },
                    {
                       "internalType":"uint256",
                       "name":"startTime",
                       "type":"uint256"
                    },
                    {
                       "internalType":"address",
                       "name":"rentUserAddress",
                       "type":"address"
                    }
                 ],
                 "internalType":"struct CaesiumNFTRentingV1.RentalData[]",
                 "name":"history",
                 "type":"tuple[]"
              },
              {
                 "internalType":"string",
                 "name":"category",
                 "type":"string"
              },
              {
                 "internalType":"uint256",
                 "name":"price",
                 "type":"uint256"
              },
              {
                 "internalType":"address",
                 "name":"currency",
                 "type":"address"
              },
              {
                 "internalType":"uint256",
                 "name":"quantity",
                 "type":"uint256"
              },
              {
                 "internalType":"uint256",
                 "name":"timePeriod",
                 "type":"uint256"
              },
              {
                 "internalType":"address payable",
                 "name":"owner",
                 "type":"address"
              }
           ],
           "internalType":"struct CaesiumNFTRentingV1.MarketItem[]",
           "name":"",
           "type":"tuple[]"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        
     ],
     "name":"getLatestPrice",
     "outputs":[
        {
           "internalType":"int256",
           "name":"",
           "type":"int256"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        
     ],
     "name":"getMarketItems",
     "outputs":[
        {
           "components":[
              {
                 "internalType":"uint256",
                 "name":"itemId",
                 "type":"uint256"
              },
              {
                 "internalType":"uint256",
                 "name":"tokenId",
                 "type":"uint256"
              },
              {
                 "components":[
                    {
                       "internalType":"uint256",
                       "name":"tokenId",
                       "type":"uint256"
                    },
                    {
                       "internalType":"uint256",
                       "name":"deadline",
                       "type":"uint256"
                    },
                    {
                       "internalType":"uint256",
                       "name":"quantity",
                       "type":"uint256"
                    },
                    {
                       "internalType":"bool",
                       "name":"isRevoked",
                       "type":"bool"
                    },
                    {
                       "internalType":"uint256",
                       "name":"startTime",
                       "type":"uint256"
                    },
                    {
                       "internalType":"address",
                       "name":"rentUserAddress",
                       "type":"address"
                    }
                 ],
                 "internalType":"struct CaesiumNFTRentingV1.RentalData[]",
                 "name":"history",
                 "type":"tuple[]"
              },
              {
                 "internalType":"string",
                 "name":"category",
                 "type":"string"
              },
              {
                 "internalType":"uint256",
                 "name":"price",
                 "type":"uint256"
              },
              {
                 "internalType":"address",
                 "name":"currency",
                 "type":"address"
              },
              {
                 "internalType":"uint256",
                 "name":"quantity",
                 "type":"uint256"
              },
              {
                 "internalType":"uint256",
                 "name":"timePeriod",
                 "type":"uint256"
              },
              {
                 "internalType":"address payable",
                 "name":"owner",
                 "type":"address"
              }
           ],
           "internalType":"struct CaesiumNFTRentingV1.MarketItem[]",
           "name":"",
           "type":"tuple[]"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"uint256",
           "name":"_id",
           "type":"uint256"
        }
     ],
     "name":"getNftByListingId",
     "outputs":[
        {
           "components":[
              {
                 "internalType":"uint256",
                 "name":"itemId",
                 "type":"uint256"
              },
              {
                 "internalType":"uint256",
                 "name":"tokenId",
                 "type":"uint256"
              },
              {
                 "components":[
                    {
                       "internalType":"uint256",
                       "name":"tokenId",
                       "type":"uint256"
                    },
                    {
                       "internalType":"uint256",
                       "name":"deadline",
                       "type":"uint256"
                    },
                    {
                       "internalType":"uint256",
                       "name":"quantity",
                       "type":"uint256"
                    },
                    {
                       "internalType":"bool",
                       "name":"isRevoked",
                       "type":"bool"
                    },
                    {
                       "internalType":"uint256",
                       "name":"startTime",
                       "type":"uint256"
                    },
                    {
                       "internalType":"address",
                       "name":"rentUserAddress",
                       "type":"address"
                    }
                 ],
                 "internalType":"struct CaesiumNFTRentingV1.RentalData[]",
                 "name":"history",
                 "type":"tuple[]"
              },
              {
                 "internalType":"string",
                 "name":"category",
                 "type":"string"
              },
              {
                 "internalType":"uint256",
                 "name":"price",
                 "type":"uint256"
              },
              {
                 "internalType":"address",
                 "name":"currency",
                 "type":"address"
              },
              {
                 "internalType":"uint256",
                 "name":"quantity",
                 "type":"uint256"
              },
              {
                 "internalType":"uint256",
                 "name":"timePeriod",
                 "type":"uint256"
              },
              {
                 "internalType":"address payable",
                 "name":"owner",
                 "type":"address"
              }
           ],
           "internalType":"struct CaesiumNFTRentingV1.MarketItem",
           "name":"data",
           "type":"tuple"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"address",
           "name":"_user",
           "type":"address"
        }
     ],
     "name":"getPurchaseData",
     "outputs":[
        {
           "components":[
              {
                 "internalType":"uint256",
                 "name":"itemId",
                 "type":"uint256"
              },
              {
                 "internalType":"uint256",
                 "name":"tokenId",
                 "type":"uint256"
              },
              {
                 "internalType":"uint256",
                 "name":"amountPaid",
                 "type":"uint256"
              },
              {
                 "internalType":"uint256",
                 "name":"deadline",
                 "type":"uint256"
              },
              {
                 "internalType":"uint256",
                 "name":"quantity",
                 "type":"uint256"
              },
              {
                 "internalType":"uint256",
                 "name":"purchaseTime",
                 "type":"uint256"
              }
           ],
           "internalType":"struct CaesiumNFTRentingV1.PurchaseHistory[]",
           "name":"data",
           "type":"tuple[]"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        
     ],
     "name":"getStats",
     "outputs":[
        {
           "internalType":"uint256",
           "name":"feee",
           "type":"uint256"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"uint256",
           "name":"",
           "type":"uint256"
        }
     ],
     "name":"idToMarketItem",
     "outputs":[
        {
           "internalType":"uint256",
           "name":"itemId",
           "type":"uint256"
        },
        {
           "internalType":"uint256",
           "name":"tokenId",
           "type":"uint256"
        },
        {
           "internalType":"string",
           "name":"category",
           "type":"string"
        },
        {
           "internalType":"uint256",
           "name":"price",
           "type":"uint256"
        },
        {
           "internalType":"address",
           "name":"currency",
           "type":"address"
        },
        {
           "internalType":"uint256",
           "name":"quantity",
           "type":"uint256"
        },
        {
           "internalType":"uint256",
           "name":"timePeriod",
           "type":"uint256"
        },
        {
           "internalType":"address payable",
           "name":"owner",
           "type":"address"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"address payable",
           "name":"_admin",
           "type":"address"
        },
        {
           "internalType":"contract NFTTOKEN",
           "name":"_dd",
           "type":"address"
        },
        {
           "internalType":"address",
           "name":"_nft",
           "type":"address"
        },
        {
           "internalType":"address",
           "name":"_ddd",
           "type":"address"
        }
     ],
     "name":"initinalize",
     "outputs":[
        
     ],
     "stateMutability":"nonpayable",
     "type":"function"
  },
  {
     "inputs":[
        
     ],
     "name":"nftAddress",
     "outputs":[
        {
           "internalType":"address",
           "name":"",
           "type":"address"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        
     ],
     "name":"nftContract",
     "outputs":[
        {
           "internalType":"contract NFTTOKEN",
           "name":"",
           "type":"address"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"uint256",
           "name":"",
           "type":"uint256"
        },
        {
           "internalType":"uint256",
           "name":"",
           "type":"uint256"
        }
     ],
     "name":"nftOwnersList",
     "outputs":[
        {
           "internalType":"address",
           "name":"",
           "type":"address"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"address",
           "name":"",
           "type":"address"
        },
        {
           "internalType":"address",
           "name":"",
           "type":"address"
        },
        {
           "internalType":"uint256[]",
           "name":"",
           "type":"uint256[]"
        },
        {
           "internalType":"uint256[]",
           "name":"",
           "type":"uint256[]"
        },
        {
           "internalType":"bytes",
           "name":"",
           "type":"bytes"
        }
     ],
     "name":"onERC1155BatchReceived",
     "outputs":[
        {
           "internalType":"bytes4",
           "name":"",
           "type":"bytes4"
        }
     ],
     "stateMutability":"nonpayable",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"address",
           "name":"",
           "type":"address"
        },
        {
           "internalType":"address",
           "name":"",
           "type":"address"
        },
        {
           "internalType":"uint256",
           "name":"",
           "type":"uint256"
        },
        {
           "internalType":"uint256",
           "name":"",
           "type":"uint256"
        },
        {
           "internalType":"bytes",
           "name":"",
           "type":"bytes"
        }
     ],
     "name":"onERC1155Received",
     "outputs":[
        {
           "internalType":"bytes4",
           "name":"",
           "type":"bytes4"
        }
     ],
     "stateMutability":"nonpayable",
     "type":"function"
  },
  {
     "inputs":[
        
     ],
     "name":"owner",
     "outputs":[
        {
           "internalType":"address payable",
           "name":"",
           "type":"address"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"address",
           "name":"_user",
           "type":"address"
        },
        {
           "internalType":"uint256",
           "name":"amount",
           "type":"uint256"
        },
        {
           "internalType":"address",
           "name":"token",
           "type":"address"
        }
     ],
     "name":"payBack",
     "outputs":[
        
     ],
     "stateMutability":"nonpayable",
     "type":"function"
  },
  {
     "inputs":[
        
     ],
     "name":"platformfee",
     "outputs":[
        {
           "internalType":"uint256",
           "name":"",
           "type":"uint256"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"address",
           "name":"",
           "type":"address"
        },
        {
           "internalType":"uint256",
           "name":"",
           "type":"uint256"
        }
     ],
     "name":"purchaseHistory",
     "outputs":[
        {
           "internalType":"uint256",
           "name":"itemId",
           "type":"uint256"
        },
        {
           "internalType":"uint256",
           "name":"tokenId",
           "type":"uint256"
        },
        {
           "internalType":"uint256",
           "name":"amountPaid",
           "type":"uint256"
        },
        {
           "internalType":"uint256",
           "name":"deadline",
           "type":"uint256"
        },
        {
           "internalType":"uint256",
           "name":"quantity",
           "type":"uint256"
        },
        {
           "internalType":"uint256",
           "name":"purchaseTime",
           "type":"uint256"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"uint256",
           "name":"itemId",
           "type":"uint256"
        },
        {
           "internalType":"uint256",
           "name":"quantity",
           "type":"uint256"
        }
     ],
     "name":"rentalNFT",
     "outputs":[
        
     ],
     "stateMutability":"payable",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"uint256",
           "name":"index",
           "type":"uint256"
        },
        {
           "internalType":"uint256",
           "name":"itemId",
           "type":"uint256"
        }
     ],
     "name":"revoke",
     "outputs":[
        
     ],
     "stateMutability":"nonpayable",
     "type":"function"
  },
  {
     "inputs":[
        
     ],
     "name":"royalityFee",
     "outputs":[
        {
           "internalType":"uint256",
           "name":"",
           "type":"uint256"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"address",
           "name":"_ddd",
           "type":"address"
        }
     ],
     "name":"setMaster",
     "outputs":[
        
     ],
     "stateMutability":"nonpayable",
     "type":"function"
  },
  {
     "inputs":[
        
     ],
     "name":"setPriceFeed",
     "outputs":[
        
     ],
     "stateMutability":"nonpayable",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"address",
           "name":"_staking",
           "type":"address"
        }
     ],
     "name":"setStakingAddress",
     "outputs":[
        
     ],
     "stateMutability":"nonpayable",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"address",
           "name":"_nft",
           "type":"address"
        }
     ],
     "name":"setaddress",
     "outputs":[
        
     ],
     "stateMutability":"nonpayable",
     "type":"function"
  },
  {
     "inputs":[
        
     ],
     "name":"stakingAddress",
     "outputs":[
        {
           "internalType":"address",
           "name":"",
           "type":"address"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"bytes4",
           "name":"interfaceId",
           "type":"bytes4"
        }
     ],
     "name":"supportsInterface",
     "outputs":[
        {
           "internalType":"bool",
           "name":"",
           "type":"bool"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        
     ],
     "name":"tokenContract",
     "outputs":[
        {
           "internalType":"contract BEP20TOKEN",
           "name":"",
           "type":"address"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  }
];
