import React from 'react';
import Frame from "../../components/frame";
import { useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import CaesiumNFT from "../../contracts/CaesiumNFT.json";
import CaesiumMarketplace from "../../contracts/CaesiumMarketplace.json";
import Web3 from "web3";
import { createWeb3Modal, defaultConfig, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalSigner } from '@web3modal/ethers5/react';
import { nftDetailsAPi } from '../../utils';
import { binanceSmartChain, metadata, projectId, NFT_TOKEN_URL, ERC20ABI } from '../../utils/constants';

const modal = createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [binanceSmartChain],
  projectId,
  themeMode: 'dark',
  themeVariables: {
    '--w3m-z-index': 999
  }
})

const initialState = {
  loading: false,
  is_connected: false,
  web3: null,
  accounts: null,
  nft_marketplace: null,
  nft_contract: null,
  contractAddress: null,
  contractAddress_Short: '-',
  YourAddress: '-----------',
  YourAddressfull: null
};

class MyNft extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      web3: null,
      accounts: null,
      nft_marketplace: null,
      nft_contract: null,
      contractAddress: null,
      contractAddress_Short: '-',
      YourAddress: '-----------',
      YourAddressfull: null,
      total_nft_marketplace: 0,
      nft_marketplace_list: [],
      page_number: 1,
      page_limit: 8,
      pagination_m:null,
      current_page_m:1,
      load_page_m:1,
    }
  }

  componentDidMount = async () => {
    try {
      if(!this.props?.isConnected){
        this.setWeb3();
      }
      let contractAddress_Short = CaesiumNFT.address.slice(0, 10) + '.....' + CaesiumNFT.address.slice(CaesiumNFT.address.length - 5, CaesiumNFT.address.length);
      this.setState({ contractAddress_Short, contractAddress: CaesiumNFT.address });
    } catch (error) {
      this.setState({ networkMain: false, loading: false });
      console.error(error);
    }
  };


  componentDidUpdate = async () => {
    if (this.props?.address && this.props?.address != this.state.accounts) {
      this.setState({ accounts: this.props?.address });
      this.connectToWallet();
    }

    if (this.state.accounts && !this.props?.isConnected) {
      this.disconnectFromWallet();
    }

  }


  connectToWallet = async (e) => {
    try {
      // Get network provider and web3 instance.
      let provider = this.props?.walletProvider;
      let web3 = new Web3(modal);
      web3.setProvider(binanceSmartChain.rpcUrl);
      // Use web3 to get the user's accounts.
      const accounts = this.props?.address;

      const networkId = this.props?.chainId;
      if (networkId !== 56) {
        enqueueSnackbar("Error! Please connect to bsc mainnet", { variant: 'error' });
        this.setState({ loading: false });
        return false;
      }

      if (this.state.accounts !== accounts) {
        // Get the contract instance.

        this.setState({ loading: true });
        const deployedNetwork = CaesiumNFT.networks;
        const instance = new web3.eth.Contract(
          CaesiumNFT.abi,
          deployedNetwork && CaesiumNFT.address, provider
        );

        const instance2 = new web3.eth.Contract(
          CaesiumMarketplace.abi,
          deployedNetwork && CaesiumMarketplace.address, provider
        );

        let user_account = accounts;

        this.setState({ loading: false, is_connected: true });
        this.setState({ web3, accounts: user_account, nft_contract: instance, nft_marketplace: instance2, contractAddress: CaesiumNFT.address, loading: true }, this.fetchData);

        //this.setState({ web3, accounts:user_account, contract: instance, contractAddress:deployedNetwork.address}, this.fetchData);
        enqueueSnackbar("Wallet Connected", { variant: 'success' });
      }
    } catch (error) {
      // Catch any errors for any of the above operations.
      enqueueSnackbar(error.message, { variant: 'error' });
      this.setState({ networkMain: false });
      console.error(error);
      this.setState({ loading: false });
    }
  };

  disconnectFromWallet = async () => {
    try {
      this.setState({ loading: true });
      if (this.state.web3) {
        enqueueSnackbar("Wallet disconnected", { variant: 'success' });
      }
      this.setState(initialState);
    } catch (error) {
      // Catch any errors for any of the above operations.
      enqueueSnackbar(error.message, { variant: 'error' });
      this.setState({ networkMain: false });
      console.error(error);
      this.setState({ loading: false });
    }
  }

  fetchData = async () => {
    let { accounts, contractAddress, nft_marketplace, nft_contract } = this.state;
    let contractAddress_Short = contractAddress.slice(0, 10) + '.....' + contractAddress.slice(contractAddress.length - 5, contractAddress.length);
    let YourAddress = this.state.YourAddress;
    if(accounts)
    YourAddress = accounts.slice(0, 6) + '.....' + accounts.slice(accounts.length - 5, accounts.length);
    let YourAddressfull = accounts;

    let total_nft_marketplace = await nft_marketplace.methods.fetchPurchasedNFTs().call({
      from:accounts
    });
    let nft_marketplace_list = [];
    let pagination_m = null;

    if (total_nft_marketplace.length > 0) {
      pagination_m = this.paginate(total_nft_marketplace.length,1);
      let data_nft = total_nft_marketplace;

      total_nft_marketplace = data_nft.length;
      // console.log("data_nft",total_nft_marketplace)
      for (var i = total_nft_marketplace - 1; i >= 0; i--) {
        let nft_market = data_nft[i];
        nft_market = {
          itemId: parseInt(nft_market[0]),
          nftContract: nft_market[1],
          tokenId: parseInt(nft_market[2]),
          seller: nft_market[3],
          owner: nft_market[4],
          category: nft_market[5],
          price: parseInt(nft_market[6]),
          currency: nft_market[7],
          isSold: nft_market[8]
        }
        let token_url = await nft_contract.methods.tokenURI(nft_market.tokenId).call();

        if (token_url?.includes('ipfs://')) {
          token_url = NFT_TOKEN_URL + token_url.replace('ipfs://', '');
        }
        // console.log('44',token_url)
        let decimals;
        let symbol;
        if (/^0x00+$/.test(nft_market.currency)) {
          decimals = 1e18;
          symbol = 'BNB';
        } else {
          let contract = new this.state.web3.eth.Contract(ERC20ABI, nft_market.currency, this.props?.walletProvider);
          decimals = await contract.methods.decimals().call();
          decimals = parseInt(decimals);
          decimals = 10 ** decimals;
          symbol = await contract.methods.symbol().call();
        }

        let nft_details = await nftDetailsAPi(token_url);
        // console.log('nft_details',nft_details);
        nft_details = nft_details;
        let attributes = nft_details?.attributes;
        nft_market.nft_details = nft_details;
        nft_market.attributes = attributes;
        nft_market.decimals = decimals;
        nft_market.symbol = symbol;
        // console.log("1",nft_market);
        nft_marketplace_list.push(nft_market);
        if (nft_marketplace_list.length == this.state.page_limit) {
          break;
        }
      }
    }

    this.setState({
      contractAddress_Short,
      YourAddress,
      YourAddressfull,
      total_nft_marketplace,
      nft_marketplace_list,
      pagination_m,
      loading: false,
      is_refresh: false
    });
    // const script = document.createElement('script')
    // script.src = '/assets/js/image_cn.js' // path of the plugin called Slick Slider
    // script.async = true // false not work too
    // document.body.append(script)
  }

  setWeb3 = async ()=>{
    try {      
      this.setState({loading:true});
      let provider = this.props?.walletProvider;
      let web3 = new Web3(modal);
      const accounts = this.props?.address;
      web3.setProvider(binanceSmartChain.rpcUrl);
      const deployedNetwork = CaesiumNFT.networks;
        const instance = new web3.eth.Contract(
          CaesiumNFT.abi,
          deployedNetwork && CaesiumNFT.address, provider
        );

      const instance2 = new web3.eth.Contract(
        CaesiumMarketplace.abi,
        deployedNetwork && CaesiumMarketplace.address, provider
      );
      this.setState({ web3, nft_contract: instance, accounts:accounts, nft_marketplace: instance2, contractAddress: CaesiumNFT.address},()=>{
        if(this.state.accounts){
          this.fetchData();
        }else{
          this.setState({loading:false});
        }
        
      });
    } catch (error) {
        console.log('setWeb3',error);
    }
  }

  paginate = (
    totalItems,
    currentPage = 1,
    pageSize = 8,
    maxPages = 10
  ) => {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
        currentPage = 1;
    } else if (currentPage > totalPages) {
        currentPage = totalPages;
    }

    let startPage, endPage;
    if (totalPages <= maxPages) {
        // total pages less than max so show all pages
        startPage = 1;
        endPage = totalPages;
    } else {
        // total pages more than max so calculate start and end pages
        let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
        let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
        if (currentPage <= maxPagesBeforeCurrentPage) {
            // current page near the start
            startPage = 1;
            endPage = maxPages;
        } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
            // current page near the end
            startPage = totalPages - maxPages + 1;
            endPage = totalPages;
        } else {
            // current page somewhere in the middle
            startPage = currentPage - maxPagesBeforeCurrentPage;
            endPage = currentPage + maxPagesAfterCurrentPage;
        }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
        totalItems: totalItems,
        currentPage: currentPage,
        pageSize: pageSize,
        totalPages: totalPages,
        startPage: startPage,
        endPage: endPage,
        startIndex: startIndex,
        endIndex: endIndex,
        pages: pages
    };
  }

  getPaginationDataM = async () => {
    try {
      this.setState({loading:true});
      const {nft_contract,nft_marketplace} = this.state;
      let total_nft_marketplace = await nft_marketplace.methods.getItemsByCategory(this.state.active_sub_cat).call();
      let nft_marketplace_list = [];
      let pagination_m = this.state.pagination_m;
      let current_page=this.state.current_page_m;
      let load_page_m = current_page;
      let cnt = 0;
      pagination_m = this.paginate(total_nft_marketplace.length,current_page);
      let total_length = total_nft_marketplace.length;
      let show_data = total_length - (current_page-1)*pagination_m.pageSize;
      let data_nft = total_nft_marketplace;

      // if(total_nft_marketplace.length>0){
      if(show_data>0){
        
        // for(var i=0; i<getWithDrawList.length;i++){
        for(var i=show_data-1; i>=0;i--){
          if(cnt >=pagination_m.pageSize){
            break;
          }
          let nft_market = data_nft[i];
          nft_market = {
            itemId: parseInt(nft_market[0]),
            nftContract: nft_market[1],
            tokenId: parseInt(nft_market[2]),
            seller: nft_market[3],
            owner: nft_market[4],
            category: nft_market[5],
            price: parseInt(nft_market[6]),
            currency: nft_market[7],
            isSold: nft_market[8]
          }
          let token_url = await nft_contract.methods.tokenURI(nft_market.tokenId).call();

          if (token_url?.includes('ipfs://')) {
            token_url = NFT_TOKEN_URL + token_url.replace('ipfs://', '');
          }
          // console.log('44',token_url)
          let decimals;
          let symbol;
          if (/^0x00+$/.test(nft_market.currency)) {
            decimals = 1e18;
            symbol = 'BNB';
          } else {
            let contract = new this.state.web3.eth.Contract(ERC20ABI, nft_market.currency, this.props?.walletProvider);
            decimals = await contract.methods.decimals().call();
            decimals = parseInt(decimals);
            decimals = 10 ** decimals;
            symbol = await contract.methods.symbol().call();
          }

          let nft_details = await nftDetailsAPi(token_url);
          // console.log('nft_details',nft_details);
          nft_details = nft_details;
          let attributes = nft_details?.attributes;
          nft_market.nft_details = nft_details;
          nft_market.attributes = attributes;
          nft_market.decimals = decimals;
          nft_market.symbol = symbol;
          // console.log("1",nft_market);
          nft_marketplace_list.push(nft_market);
          if (nft_marketplace_list.length == this.state.page_limit) {
            break;
          }
          cnt++;
        }
      }

      this.setState({nft_marketplace_list,pagination_m,load_page_m,loading:false});
      // const script = document.createElement('script')
      // script.src = '/assets/js/image_cn.js' // path of the plugin called Slick Slider
      // script.async = true // false not work too
      // document.body.append(script)
    } catch (error) {
      this.setState({loading:false});
      console.log('pagination error',error);
    }
  }

  render() {
    let this2 = this;

    return (
      <Frame withHeader={false} withFooter={true}>

        {this.state.loading ? (
          <div className="loader-container">
            <div className="linespinner"></div>
          </div>
        ) : (null)}

        <header id="header_main" className="pt-3 pb-3 header_1 header-fixed">
          <div className="container">
            <div className="header-inner">
              <div className="header-left">
                <div className="site-logo">
                  <a href="/" className="main-logo">
                    <img src="/assets/img/logo.png" />
                  </a>
                </div>
                <nav id="main-nav" className="main-nav">
                  <ul id="menu-primary-menu" className="menu">
                    <li className="menu-item"><a href="/marketplace">Marketplace</a></li>
                    {/* <li className="menu-item"><a href="/my_nft">My Nft</a></li> */}
                   
                  </ul>
                </nav>
              </div>
              <div className="header-right">
                <div className="flat-wallet flex">                  
                  <div className="d-none d-lg-block ms-3">
                    {this.props.isConnected ? (
                      <a
                        disabled={this.state.loading}
                        // onClick={() => this.props.open()}
                        className="gradient_btn_skew btn pointer"
                      >
                        <i className="icon-sign-out-1"></i> {this.state.YourAddress}
                      </a>
                    ) : (
                      <a
                        disabled={this.state.loading}
                        onClick={() => this.props.open()}
                        className="gradient_btn_skew btn pointer"
                      >
                        <i className="icon-sign-out-1"></i> Connect wallet
                      </a>
                    )}
                  </div>
                  <div className="admin_active">
                    <div className="popup-user relative">
                      <div className="user">
                        <img src="/assets/img/avatar-small-09.png" alt="" />
                      </div>
                      <div className="avatar_popup2">
                        <div className="">
                          <div className="links">
                            {/* <a className="block mb-20" href="#">
                              <img src="/assets/img/boy-avater.png" alt="" />
                              <span>My Profile</span>
                            </a> */}
                            <a className="block mb-20" href="#">
                              <img src="/assets/img/eye.png" alt="" />
                              <span>Watchlist</span>
                            </a>
                            <a className="block mb-20" href="/my_nft">
                              <img src="/assets/img/hand-shake.png" alt="" />
                              <span>My NFT</span>
                            </a>
                            {this.props.isConnected ? (
                              <a
                                disabled={this.state.loading}
                                onClick={() => this.props.open()}
                                className="block mb-20 pointer"
                              >
                                <img src="/assets/img/logout.png" alt="" />
                                <span> Sign Out</span>
                              </a>
                            ) : (
                              <a
                                disabled={this.state.loading}
                                onClick={() => this.props.open()}
                                className="block mb-20 pointer"
                              >
                                <img src="/assets/img/logout.png" alt="" />
                                <span> Sign In</span>
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mobile-button">
                    <span></span>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="mobile-nav-wrap">
            <div className="overlay-mobile-nav"></div>
            <div className="inner-mobile-nav">
              <a href="/" rel="home" className="main-logo">
                <img id="mobile-logo_header" src="/assets/img/logo.png" />
              </a>
              <div className="mobile-nav-close">
                <svg xmlns="http://www.w3.org/2000/svg" fill="white" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 122.878 122.88" enableBackground="new 0 0 122.878 122.88" space="preserve"><g><path d="M1.426,8.313c-1.901-1.901-1.901-4.984,0-6.886c1.901-1.902,4.984-1.902,6.886,0l53.127,53.127l53.127-53.127 c1.901-1.902,4.984-1.902,6.887,0c1.901,1.901,1.901,4.985,0,6.886L68.324,61.439l53.128,53.128c1.901,1.901,1.901,4.984,0,6.886 c-1.902,1.902-4.985,1.902-6.887,0L61.438,68.326L8.312,121.453c-1.901,1.902-4.984,1.902-6.886,0 c-1.901-1.901-1.901-4.984,0-6.886l53.127-53.128L1.426,8.313L1.426,8.313z" /></g></svg>
              </div>
              <nav id="mobile-main-nav" className="mobile-main-nav">
                <ul id="menu-mobile-menu" className="menu">
                  <li className="menu-item"><a href="/marketplace">Marketplace</a></li>
                  <li className="menu-item"><a href="/my_nft">My Nft</a></li>
                </ul>
              </nav>
              <div className="mt-3">
                {this.props.isConnected ? (
                  <a
                    disabled={this.state.loading}
                    onClick={() => this.props.open()}
                    className="gradient_btn_skew btn btn-block pointer"
                  >
                    <i className="icon-sign-out-1"></i> Disconect wallet
                  </a>
                ) : (
                  <a
                    disabled={this.state.loading}
                    onClick={() => this.props.open()}
                    className="gradient_btn_skew btn btn-block pointer"
                  >
                    <i className="icon-sign-out-1"></i> Connect wallet
                  </a>
                )}
              </div>
            </div>
          </div>
        </header>

        <div className="pt-5 pb-5 relative">
          <div className="container">
            <div className="row">             
              <div className="col-md-12">
                <div className="row g-3">                
                  {this.state.nft_marketplace_list ? (
                    this.state.nft_marketplace_list.length>0 ? (
                      this.state.nft_marketplace_list.map(function(item, index){
                        let image_url = item.nft_details.image;
                        if(image_url?.includes('ipfs://')){
                          image_url = NFT_TOKEN_URL+image_url.replace('ipfs://','');
                        }
                        let seller_view = '---';
                        if(item?.seller && !/^0x00+$/.test(item?.seller)){
                          let seller = item?.seller;
                          seller_view = seller.slice(0, 10) + '.....' + seller.slice(seller.length - 5, seller.length);
                        }                        

                        return (
                          <div className="col-lg-3 col-md-4 col-6" key={`nft_list${index}`}>
                            <div className="tf-card-box style-1">
                              <div className="card-media">
                                <div className="mygif_img_box">
                                  <canvas className="mycanvas_gif" width="512" height="512"></canvas>
                                  {image_url?(
                                    <img className="my_gif_img" src={image_url} />
                                  ):(
                                    <img className="my_gif_img" src="/assets/img/csm-nft-no-image.jpg" />
                                  )}
                                </div>
                                <span className="wishlist-button icon-heart"></span>
                                <div className="button-place-bid">
                                  <a href={`/nft_details/${btoa(item.tokenId)}`} className="tf-button"><span>Place Buy</span></a>
                                </div>
                              </div>
                              <h5 className="name"><a >NFT ID : #{item.tokenId}</a></h5>
                              <div className="author flex items-center">
                                <div className="avatar">
                                  <img src="/assets/img/avatar-box-01.jpg" alt="Image" />
                                </div>
                                <div className="info">
                                  <span>Created by:</span>
                                  <h6><a>{seller_view}</a> </h6>
                                </div>
                              </div>
                              <div className="divider"></div>
                              <div className="meta-info flex items-center justify-between">
                                <span className="text-bid">Current Price</span>
                                <h6 className="price gem">
                                  {/* <i className="icon-gem"></i> */}
                                  {parseFloat(item.price/item.decimals).toFixed(4)} {item.symbol}
                                </h6>
                              </div>
                            </div>
                          </div>
                          )
                      })
                    ):(
                    <div className='heading-section middle_heading text-center justify-content-center'>
                      <img className='no_data_img' src='/assets/img/no_data_found.png' />
                      <h1 className='tf-title mb-0 mt-3'>No data available</h1>
                    </div>
                    )
                  ) : (
                    <div className='heading-section middle_heading text-center justify-content-center'>
                      <img className='no_data_img' src='/assets/img/no_data_found.png' />
                      <h1 className='tf-title mb-0 mt-3'>No data available</h1>
                    </div>
                  )}
                </div>

                {this.state.nft_marketplace_list && this.state.nft_marketplace_list?.length ? (
                  <div className="widget-pagination mt-5">
                    <ul className="justify-center">
                      <li>
                        <a
                          style={{
                            cursor: this2.state.current_page_m>1 ? "pointer" : "auto",
                          }}
                          onClick={() => {
                            this2.setState(
                              { current_page_m:this2.state.current_page_m>1?this2.state.current_page_m-1:1 },
                              () => {
                                if(this2.state.load_page_m>1){
                                  this2.getPaginationDataM();
                                }                                      
                              }
                            );
                          }}
                        ><i className="icon-keyboard_arrow_left"></i></a>
                      </li>
                      {this.state.pagination_m.pages.length > 0
                        ? this.state.pagination_m.pages.map(function (
                            val,
                            index
                          ) {
                            let className = "";
                            if (val == this2.state.current_page_m) {
                              className = "active";
                            }                                  
                            return (
                              <li
                                key={`wlp${index}`}
                                className={className}
                                style={{
                                  cursor: val ? "pointer" : "initial",
                                }}
                              >
                                <a
                                  onClick={() => {
                                    if (val) {
                                      this2.setState(
                                        { current_page_m:val },
                                        () => {
                                          this2.getPaginationDataM();
                                        }
                                      );
                                    }
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: val,
                                  }}
                                ></a>
                              </li>
                            );
                          })
                        : null}
                      <li>
                        <a
                          style={{
                            cursor: this2.state.current_page_m<this2.state.pagination_m?.totalPages ? "pointer" : "auto",
                          }}
                          onClick={() => {
                            this2.setState(
                              { current_page_m:this2.state.current_page_m<this2.state.pagination_m?.totalPages?this2.state.current_page_m+1:this2.state.pagination_m?.totalPages },
                              () => {
                                if(this2.state.load_page_m<this2.state.pagination_m?.totalPages){
                                  this2.getPaginationDataM();
                                }                                      
                              }
                            );
                          }}                          
                        ><i className="icon-keyboard_arrow_right"></i></a>
                      </li>
                    </ul>
                  </div>
                ) : null}                
              </div>
            </div>
          </div>
        </div>

      </Frame>
    );
  }
}

function MyNftHOC(props) {
  let { cat_id, sub_cat_id } = useParams();
  const { open } = useWeb3Modal();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { signer, walletProvider } = useWeb3ModalSigner();
  return <MyNft
    open={open}
    address={address}
    chainId={chainId}
    isConnected={isConnected}
    signer={signer}
    walletProvider={walletProvider}
    cat_id={cat_id}
    sub_cat_id={sub_cat_id}
    {...props}
  />;
}

export default MyNftHOC;