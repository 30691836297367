import React from 'react';
import Countdown from 'react-countdown';
import CaesiumNFT from "../contracts/CaesiumNFT.json";
import { nftCategoryAPi, nftDetailsAPi } from '../utils';
import { ERC1155ABI, GAME_INO_ABI, CSM_MEMBER_ABI, NFT_ASSET_CONTRACT_ADDRESS,GAME_INO_CONTRACT_ADDRESS,Caesium_Member_CONTRACT } from '../utils/constants';

class InoMarketPlace extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      web3: null,
      accounts: null,
      nft_asset_contract:null,
      game_ino_contract:null,
      contractMember:null,
      contractAddress: null,
      contractAddress_Short: '-',
      YourAddress: '-----------',
      YourAddressfull: null,
      total_nft_marketplace: 0,
      nft_marketplace_list: [],
      page_number: 1,
      page_limit: 12,
      category_list: [],
      category_list_obj: [],
      active_cat: null,
      active_sub_cat: null,
      pagination_m: null,
      current_page_m: 1,
      load_page_m: 1,
    }
  }

  componentDidMount = async () => {
    try {
      if(this.props.web3){
        let provider = this.props?.walletProvider;
        let web3 = this.props.web3;
        const deployedNetwork = CaesiumNFT.networks;
        const nft_asset_contract = new web3.eth.Contract(
          ERC1155ABI,
          deployedNetwork && NFT_ASSET_CONTRACT_ADDRESS, provider
        );

        const game_ino_contract = new web3.eth.Contract(
          GAME_INO_ABI,
          deployedNetwork && GAME_INO_CONTRACT_ADDRESS, provider
        );

        const contractMember = new web3.eth.Contract(
          CSM_MEMBER_ABI,
          deployedNetwork && Caesium_Member_CONTRACT, provider
        );
        this.setState({nft_asset_contract,game_ino_contract,contractMember},()=>{
          this.getDefaultData();
        });        
      }
    } catch (error) {
      this.setState({ networkMain: false, loading: false });
      console.error(error);
    }
  };

  fetchData = async () => {
    try {
      let { accounts, nft_asset_contract,game_ino_contract,contractMember } = this.state;
      let YourAddress = this.state.YourAddress;
      if (accounts)
        YourAddress = accounts.slice(0, 6) + '.....' + accounts.slice(accounts.length - 5, accounts.length);
      let YourAddressfull = accounts;
      
      // let total_nft_marketplace = 0;
      let nft_marketplace_list = [];
      let pagination_m = null;
      let active_sub_cat = this.state.active_sub_cat;
      let total_nft_marketplace = [];
      if(active_sub_cat){
        total_nft_marketplace = await game_ino_contract.methods.getItemsByCategory(active_sub_cat).call();
      }else{
        total_nft_marketplace = await game_ino_contract.methods.getItems().call();
      }
      
      if (total_nft_marketplace.length > 0) {
        let baseDivider;
        if(accounts){
          let userInfo_Member = await contractMember.methods.users(this.props?.address).call();
          if(userInfo_Member.memberShipStatus){
            baseDivider = await game_ino_contract.methods.baseDivider().call();
            baseDivider = parseFloat(baseDivider);
          }
        }          

        pagination_m = this.paginate(total_nft_marketplace.length, 1);
        let data_nft = total_nft_marketplace;

        total_nft_marketplace = data_nft.length;
        // console.log("data_nft",total_nft_marketplace)
        for (var i = total_nft_marketplace - 1; i >= 0; i--) {            
          let nft_market = data_nft[i];
          let percent = 0;
          if(accounts){
            percent = parseFloat(parseFloat(nft_market[7])/baseDivider);
          }
          nft_market = {
            tokenId:parseFloat(nft_market[0]),
            totalQuantity:parseFloat(nft_market[1]),
            soldQuantity:parseFloat(nft_market[2]),
            deadLine:parseFloat(nft_market[3]),
            initalPrice:parseFloat(nft_market[4]),
            category:nft_market[5],
            finalPrice:parseInt(nft_market[6]),
            percent:percent,
            isClaimBack:nft_market[8],
            isAvilableForClaim:nft_market[9],
          }

          if(parseInt(nft_market.deadLine)<Date.now()/1000){
            continue;
          }

          let token_url = await nft_asset_contract.methods.geturi(nft_market.tokenId).call();
          
          let decimals = 1e18;
          let symbol = 'BNB';
          let nft_details = await nftDetailsAPi(token_url);
          nft_details = nft_details;
          let attributes = nft_details?.attributes;
          nft_market.nft_details = nft_details;
          nft_market.attributes = attributes;
          nft_market.decimals = decimals;
          nft_market.symbol = symbol;
          // console.log("1",nft_market);
          nft_marketplace_list.push(nft_market);
          if(nft_marketplace_list.length%4 == 0){
            this.setState({
              nft_marketplace_list,pagination_m,loading: false
            });
          }
          if (nft_marketplace_list.length == this.state.page_limit) {
            break;
          }
        }
      }
      this.setState({
        YourAddress,
        YourAddressfull,
        total_nft_marketplace,
        nft_marketplace_list,
        pagination_m,
        loading: false,
        is_refresh: false
      });
      
      // const script = document.createElement('script')
      // script.src = '/assets/js/image_cn.js';
      // script.async = true;
      // document.body.append(script);
    } catch (error) {
      console.log('fetch error', error);
    }
  }

  getDefaultData = async () => {
    try {
      this.setState({ loading: true });
      const {nft_asset_contract,game_ino_contract,contractMember} = this.state;
      let category_data = await nftCategoryAPi();
      let category_list = this.state.category_list;
      let category_list_obj = this.state.category_list_obj;
      let active_cat = this.state.active_cat;
      let active_sub_cat = this.state.active_sub_cat;
      if (category_data.status) {
        category_list = category_data.data;
        category_list_obj = Object.entries(category_list);
        // active_cat = category_list_obj[0][0];
        // active_sub_cat = category_list_obj[0][1][0];
      }

      let total_nft_marketplace = [];
      if(active_sub_cat){
        total_nft_marketplace = await game_ino_contract.methods.getItemsByCategory(active_sub_cat).call();
      }else{
        total_nft_marketplace = await game_ino_contract.methods.getItems().call();
      }

      let nft_marketplace_list = [];
      let pagination_m = null;      

      if (total_nft_marketplace.length > 0) {
        let baseDivider;
        if(this.props?.address){
          let userInfo_Member = await contractMember.methods.users(this.props?.address).call();
          if(userInfo_Member.memberShipStatus){
            baseDivider = await game_ino_contract.methods.baseDivider().call();
            baseDivider = parseFloat(baseDivider);
          }
        }

        pagination_m = this.paginate(total_nft_marketplace.length, 1);
        let data_nft = total_nft_marketplace;

        total_nft_marketplace = data_nft.length;
        // console.log("data_nft",total_nft_marketplace)
        for (var i = total_nft_marketplace - 1; i >= 0; i--) {
          let nft_market = data_nft[i];
          let percent = 0;
          if(this.props?.address){
            percent = parseFloat(parseFloat(nft_market[7])/baseDivider);
          }
          nft_market = {
            tokenId:parseFloat(nft_market[0]),
            totalQuantity:parseFloat(nft_market[1]),
            soldQuantity:parseFloat(nft_market[2]),
            deadLine:parseFloat(nft_market[3]),
            initalPrice:parseFloat(nft_market[4]),
            category:nft_market[5],
            finalPrice:parseInt(nft_market[6]),
            percent:percent,
            isClaimBack:nft_market[8],
            isAvilableForClaim:nft_market[9],
          }

          if(parseInt(nft_market.deadLine)<Date.now()/1000){
            continue;
          }

          let token_url = await nft_asset_contract.methods.geturi(nft_market.tokenId).call();
          
          let decimals = 1e18;
          let symbol = 'BNB';
          let nft_details = await nftDetailsAPi(token_url);
          nft_details = nft_details;
          let attributes = nft_details?.attributes;
          nft_market.nft_details = nft_details;
          nft_market.attributes = attributes;
          nft_market.decimals = decimals;
          nft_market.symbol = symbol;
          // console.log("1",nft_market);
          nft_marketplace_list.push(nft_market);
          if(nft_marketplace_list.length%4 == 0){
            this.setState({
              nft_marketplace_list,pagination_m,loading: false
            });
          }
          if (nft_marketplace_list.length == this.state.page_limit) {
            break;
          }
        }
      }

      this.setState({
        accounts: this.props.address,
        category_list, category_list_obj, active_cat, active_sub_cat,
        total_nft_marketplace,
        nft_marketplace_list,
        pagination_m,
        loading: false,
        is_refresh: false
      });
      // const script = document.createElement('script')
      // script.src = '/assets/js/image_cn.js';
      // script.async = true;
      // document.body.append(script);
    } catch (error) {
      console.log('get d error', error);
      this.setState({ loading: false });
    }
  }

  getCategoryList = async () => {
    try {
      let category_data = await nftCategoryAPi();
      let category_list = this.state.category_list;
      let category_list_obj = this.state.category_list_obj;
      let active_cat = this.state.active_cat;
      let active_sub_cat = this.state.active_sub_cat;
      if (category_data.status) {
        category_list = category_data.data;
        category_list_obj = Object.entries(category_list);
        active_cat = category_list_obj[0][0];
        active_sub_cat = category_list_obj[0][1][0];
      }

      if (this.state.active_cat) {
        active_cat = this.state.active_cat;
      }

      if (this.state.active_sub_cat) {
        active_sub_cat = this.state.active_sub_cat;
      }

      this.setState({ category_list, category_list_obj, active_cat, active_sub_cat }, async () => {
        await this.fetchData();
      });
    } catch (error) {
      console.log('fetch category err', error);
    }
  }

  handleClickCat = async (active_cat) => {
    if (active_cat == this.state.active_cat) {
      return;
    }
    let sub_cat_all = [];
    let active_sub_cat = null;
    if(active_cat){
      sub_cat_all = this.state.category_list[active_cat];
      active_sub_cat = sub_cat_all[0];
    }
    this.setState({
      active_cat, active_sub_cat, loading: true,
      current_page_m: 1,
      load_page_m: 1
    }, () => {
      this.fetchData();
    });
  }

  handleClickSubCat = async (active_sub_cat) => {
    if (active_sub_cat == this.state.active_sub_cat) {
      return;
    }
    this.setState({
      active_sub_cat, loading: true,
      current_page_m: 1,
      load_page_m: 1
    }, () => {
      this.fetchData();
    });
  }

  paginate = (
    totalItems,
    currentPage = 1,
    pageSize = 12,
    maxPages = 10
  ) => {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage, endPage;
    if (totalPages <= maxPages) {
      // total pages less than max so show all pages
      startPage = 1;
      endPage = totalPages;
    } else {
      // total pages more than max so calculate start and end pages
      let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
      let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
      if (currentPage <= maxPagesBeforeCurrentPage) {
        // current page near the start
        startPage = 1;
        endPage = maxPages;
      } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
        // current page near the end
        startPage = totalPages - maxPages + 1;
        endPage = totalPages;
      } else {
        // current page somewhere in the middle
        startPage = currentPage - maxPagesBeforeCurrentPage;
        endPage = currentPage + maxPagesAfterCurrentPage;
      }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

  getPaginationDataM = async () => {
    try {
      this.setState({ loading: true });
      const {nft_asset_contract,game_ino_contract,contractMember} = this.state;
      let active_sub_cat = this.state.active_sub_cat;
      let total_nft_marketplace = [];
      if(active_sub_cat){
        total_nft_marketplace = await game_ino_contract.methods.getItemsByCategory(active_sub_cat).call();
      }else{
        total_nft_marketplace = await game_ino_contract.methods.getItems().call();
      }
      let nft_marketplace_list = [];      

      let pagination_m = this.state.pagination_m;
      let current_page = this.state.current_page_m;
      let load_page_m = current_page;
      let cnt = 0;
      pagination_m = this.paginate(total_nft_marketplace.length, current_page);
      let total_length = total_nft_marketplace.length;
      let show_data = total_length - (current_page - 1) * pagination_m.pageSize;
      let data_nft = total_nft_marketplace;

      // if(total_nft_marketplace.length>0){
      if (show_data > 0) {
        let baseDivider;
        if(this.props?.address){
          let userInfo_Member = await contractMember.methods.users(this.props?.address).call();
          if(userInfo_Member.memberShipStatus){
            baseDivider = await game_ino_contract.methods.baseDivider().call();
            baseDivider = parseFloat(baseDivider);
          }
        }
        // for(var i=0; i<getWithDrawList.length;i++){
        for (var i = show_data - 1; i >= 0; i--) {
          if (cnt >= pagination_m.pageSize) {
            break;
          }
          let nft_market = data_nft[i];
          nft_market = {
            tokenId:parseFloat(nft_market[0]),
            totalQuantity:parseFloat(nft_market[1]),
            soldQuantity:parseFloat(nft_market[2]),
            deadLine:parseFloat(nft_market[3]),
            initalPrice:parseFloat(nft_market[4]),
            category:nft_market[5],
            finalPrice:parseInt(nft_market[6]),
            percent:parseFloat(parseFloat(nft_market[7])/baseDivider),
            isClaimBack:nft_market[8],
            isAvilableForClaim:nft_market[9],
          }

          if(parseInt(nft_market.deadLine)<Date.now()/1000){
            continue;
          }

          let token_url = await nft_asset_contract.methods.geturi(nft_market.tokenId).call();
          
          let decimals = 1e18;
          let symbol = 'BNB';
          let nft_details = await nftDetailsAPi(token_url);
          nft_details = nft_details;
          let attributes = nft_details?.attributes;
          nft_market.nft_details = nft_details;
          nft_market.attributes = attributes;
          nft_market.decimals = decimals;
          nft_market.symbol = symbol;
          // console.log("1",nft_market);
          nft_marketplace_list.push(nft_market);
          if(nft_marketplace_list.length%4 == 0){
            this.setState({
              nft_marketplace_list,pagination_m, load_page_m,loading: false
            });
          }
          if (nft_marketplace_list.length == this.state.page_limit) {
            break;
          }
          cnt++;
        }
      }

      this.setState({ nft_marketplace_list, pagination_m, load_page_m, loading: false });
      // const script = document.createElement('script')
      // script.src = '/assets/js/image_cn.js';
      // script.async = true;
      // document.body.append(script);
    } catch (error) {
      this.setState({ loading: false });
      console.log('pagination error', error);
    }
  }

  render() {
    let this2 = this;

    return (
      <>

        {this.state.loading ? (
          <div className="loader-container">
            <div className="linespinner"></div>
          </div>
        ) : (null)}

        <div className='row'>
          <div className="col-md-12 pb-30">
            <div className="tf-soft flex items-center justify-between">
              <div className="soft-left">
                <div className="dropdown">
                  <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16.875 6.25L16.3542 15.11C16.3261 15.5875 16.1166 16.0363 15.7685 16.3644C15.4204 16.6925 14.96 16.8752 14.4817 16.875H5.51833C5.03997 16.8752 4.57962 16.6925 4.23152 16.3644C3.88342 16.0363 3.6739 15.5875 3.64583 15.11L3.125 6.25M8.33333 9.375H11.6667M2.8125 6.25H17.1875C17.705 6.25 18.125 5.83 18.125 5.3125V4.0625C18.125 3.545 17.705 3.125 17.1875 3.125H2.8125C2.295 3.125 1.875 3.545 1.875 4.0625V5.3125C1.875 5.83 2.295 6.25 2.8125 6.25Z" stroke="white"  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <span className="inner">Category</span>
                  </button>
                  <div className="dropdown-menu">
                    <a className="dropdown-item" key={`cat_0`}
                      onClick={() => this2.handleClickCat(null)}>
                      {this2.state.active_cat == null ? (
                        <div className="sort-filter active">
                          <span>All</span>
                          <span className="icon-tick"><span className="path2"></span></span>
                        </div>
                      ) : (
                        <div className="sort-filter">
                          <span>All</span>
                          <span className="icon-tick"><span className="path2"></span></span>
                        </div>
                      )}
                    </a>
                    {Object.entries(this.state.category_list).length > 0 ? (
                      Object.keys(this.state.category_list).map(function (item, index) {
                        return (
                          <a className="dropdown-item" key={`cat${index}`}
                            onClick={() => this2.handleClickCat(item)}>
                            {this2.state.active_cat == item ? (
                              <div className="sort-filter active">
                                <span>{item}</span>
                                <span className="icon-tick"><span className="path2"></span></span>
                              </div>
                            ) : (
                              <div className="sort-filter">
                                <span>{item}</span>
                                <span className="icon-tick"><span className="path2"></span></span>
                              </div>
                            )}

                          </a>

                        )
                      })
                    ) : (null)}



                  </div>
                </div>

              </div>
              <div className="soft-right">

                <div className="dropdown">
                  <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.125 5.625H16.875M3.125 10H16.875M3.125 14.375H10" stroke="white"  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <span>Sub Category</span>
                  </button>
                  <div className="dropdown-menu">
                    {this2.state.active_cat && Object.entries(this.state.category_list).length > 0 ? (
                      this.state.category_list[this2.state.active_cat].map(function (item, index) {
                        return (
                          <a className="dropdown-item" key={`sub_cat${index}`}
                            onClick={() => this2.handleClickSubCat(item)}>
                            {this2.state.active_sub_cat == item ? (
                              <div className="sort-filter active">
                                <span>{item}</span>
                                <span className="icon-tick"><span className="path2"></span></span>
                              </div>
                            ) : (
                              <div className="sort-filter">
                                <span>{item}</span>
                                <span className="icon-tick"><span className="path2"></span></span>
                              </div>
                            )}

                          </a>

                        )
                      })
                    ) : (null)}



                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="row g-3">
              {this.state.nft_marketplace_list ? (
                this.state.nft_marketplace_list.length > 0 ? (
                  this.state.nft_marketplace_list.map(function (item, index) {
                    let image_url = item?.nft_details?.image;
                    let price = parseFloat(item.finalPrice/item.decimals);
                    let old_price = parseFloat(item.finalPrice/item.decimals);
                    if(item.percent>0){
                      price = price - price*item.percent;
                    }
                    let countdown_data = Date.now();
                    if(parseInt(item?.deadLine) > parseInt(Date.now()/1000)){
                      countdown_data = countdown_data + parseInt(item.deadLine)*1000-Date.now();
                    }

                    return (
                      <div className="col-lg-3 col-md-4 col-6" key={`nft_list${index}`}>
                        <div className="tf-card-box style-1">
                          <div className="card-media">
                            <div className="mygif_img_box">
                              <canvas className="mycanvas_gif" width="512" height="512"></canvas>
                              {image_url ? (
                                <img className="my_gif_img" src={image_url} />
                              ) : (
                                <img className="my_gif_img" src="/assets/img/csm-nft-no-image.jpg" />
                              )}                              
                            </div>
                            {item.percent>0?(
                              <div className='tag_offer_box'>
                                <img src='/assets/img/tag.png' />
                                <p><span>{item.percent*100}%</span> Off</p>
                              </div>
                            ):(null)}
                            {/* <span className="wishlist-button icon-heart"></span> */}
                            <div className="button-place-bid">
                              <a href={`/ino_details/${btoa(item.tokenId)}/${btoa('InoMarketPlace')}`} className="tf-button"><span>Place Buy</span></a>
                            </div>
                          </div>
                          <h5 className="name"><a >{item.nft_details.name} #{item.tokenId}</a></h5>
                          {parseInt(item?.deadLine) > parseInt(Date.now()/1000) ? (
                            <Countdown key={`dl${index}`} intervalDelay={0}
                            precision={3} date={countdown_data} />
                          ):(null)}
                          <h6 className='price gem mt-2'>Qty: {item.soldQuantity}/{item.totalQuantity}</h6>
                          <div className="divider"></div>
                          <div className="meta-info flex items-center justify-between">
                            <span className="text-bid">Current Price</span>
                            {item.percent>0?(
                              <h6 className="price gem">
                                <del>{parseFloat(old_price).toFixed(4)}</del> {parseFloat(price).toFixed(4)} {item.symbol}
                              </h6>
                            ):(
                              <h6 className="price gem">                              
                                {parseFloat(item.finalPrice/item.decimals).toFixed(4)} {item.symbol}
                              </h6>
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  })
                ) : (
                  <div className='col-md-12'>
                    <div className='heading-section middle_heading text-center justify-content-center'>
                      <img className='no_data_img' src='/assets/img/no_data_found.png' />
                      <h1 className='tf-title mb-0 mt-3'>No data available</h1>
                    </div>
                  </div>
                )
              ) : (
                <div className='col-md-12'>
                  <div className='heading-section middle_heading text-center justify-content-center'>
                    <img className='no_data_img' src='/assets/img/no_data_found.png' />
                    <h1 className='tf-title mb-0 mt-3'>No data available</h1>
                  </div>
                </div>
              )}
            </div>

            {this.state.nft_marketplace_list && this.state.nft_marketplace_list?.length ? (
              <div className="widget-pagination mt-5">
                <ul className="justify-center">
                  <li>
                    <a
                      style={{
                        cursor: this2.state.current_page_m > 1 ? "pointer" : "auto",
                      }}
                      onClick={() => {
                        this2.setState(
                          { current_page_m: this2.state.current_page_m > 1 ? this2.state.current_page_m - 1 : 1 },
                          () => {
                            if (this2.state.load_page_m > 1) {
                              this2.getPaginationDataM();
                            }
                          }
                        );
                      }}
                    ><i className="icon-keyboard_arrow_left"></i></a>
                  </li>
                  {this.state.pagination_m.pages.length > 0
                    ? this.state.pagination_m.pages.map(function (
                      val,
                      index
                    ) {
                      let className = "";
                      if (val == this2.state.current_page_m) {
                        className = "active";
                      }
                      return (
                        <li
                          key={`wlp${index}`}
                          className={className}
                          style={{
                            cursor: val ? "pointer" : "initial",
                          }}
                        >
                          <a
                            onClick={() => {
                              if (val) {
                                this2.setState(
                                  { current_page_m: val },
                                  () => {
                                    this2.getPaginationDataM();
                                  }
                                );
                              }
                            }}
                            dangerouslySetInnerHTML={{
                              __html: val,
                            }}
                          ></a>
                        </li>
                      );
                    })
                    : null}
                  <li>
                    <a
                      style={{
                        cursor: this2.state.current_page_m < this2.state.pagination_m?.totalPages ? "pointer" : "auto",
                      }}
                      onClick={() => {
                        this2.setState(
                          { current_page_m: this2.state.current_page_m < this2.state.pagination_m?.totalPages ? this2.state.current_page_m + 1 : this2.state.pagination_m?.totalPages },
                          () => {
                            if (this2.state.load_page_m < this2.state.pagination_m?.totalPages) {
                              this2.getPaginationDataM();
                            }
                          }
                        );
                      }}
                    ><i className="icon-keyboard_arrow_right"></i></a>
                  </li>
                </ul>
              </div>
            ) : null}
          </div>
        </div>

      </>
    );
  }
}

export default InoMarketPlace;