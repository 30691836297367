import React from 'react';
import Frame from "../../components/frame";
import { useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import CaesiumNFT from "../../contracts/CaesiumNFT.json";
import CaesiumMarketplace from "../../contracts/CaesiumMarketplace.json";
import Web3 from "web3";
import { createWeb3Modal, defaultConfig, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalSigner } from '@web3modal/ethers5/react';
import { binanceSmartChain, metadata, projectId,} from '../../utils/constants';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import InoMarketPlace from '../../components/InoMarketPlace';
import InoMyPurchases from '../../components/InoMyPurchases';
import NftMarketPlace from '../../components/NftMarketPlace';
import NftStaking from '../../components/NftStaking';
import NftMyAssets from '../../components/NftMyAssets';
import RentingMarketPlace from '../../components/RentingMarketPlace';
import RentingMyPurchases from '../../components/RentingMyPurchases';
import RentingMyAdds from '../../components/RentingMyAdds';

const modal = createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [binanceSmartChain],
  projectId,
  themeMode: 'dark',
  themeVariables: {
    '--w3m-z-index': 999
  }
})

const initialState = {
  loading: false,
  is_connected: false,
  // web3: null,
  accounts: null,
  YourAddress: '-----------',
  YourAddressfull: null
};

class MarketPlace extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      is_connected: false,
      web3: null,
      accounts: null,
      YourAddress: '-----------',
      YourAddressfull: null
    }
  }

  componentDidMount = async () => {
    try {
      if(!this.props?.isConnected){
        this.setWeb3();
      }
    } catch (error) {
      this.setState({ networkMain: false, loading: false });
      console.error(error);
    }
  };


  componentDidUpdate = async () => {
    if (this.props?.address && this.props?.address != this.state.accounts) {
      this.setState({ accounts: this.props?.address });
      this.connectToWallet();
    }

    if (this.state.accounts && !this.props?.isConnected) {
      this.disconnectFromWallet();
    }

  }


  connectToWallet = async (e) => {
    try {
      // Get network provider and web3 instance.
      let provider = this.props?.walletProvider;
      let web3 = new Web3(modal);
      web3.setProvider(binanceSmartChain.rpcUrl);
      // Use web3 to get the user's accounts.
      const accounts = this.props?.address;

      const networkId = this.props?.chainId;
      if (networkId !== 56) {
        enqueueSnackbar("Error! Please connect to bsc mainnet", { variant: 'error' });
        this.setState({ loading: false });
        return false;
      }

      if (this.state.accounts !== accounts) {
        // Get the contract instance.

        this.setState({ loading: true });
        const deployedNetwork = CaesiumNFT.networks;
        const instance = new web3.eth.Contract(
          CaesiumNFT.abi,
          deployedNetwork && CaesiumNFT.address, provider
        );

        const instance2 = new web3.eth.Contract(
          CaesiumMarketplace.abi,
          deployedNetwork && CaesiumMarketplace.address, provider
        );

        let user_account = accounts;
        let YourAddressfull = accounts;
        let YourAddress = user_account.slice(0, 6) + '.....' +user_account.slice(user_account.length - 5, user_account.length);

        this.setState({ loading: false, is_connected: true, YourAddress, YourAddressfull });
        this.setState({ web3, accounts: user_account, nft_contract: instance, nft_marketplace: instance2, contractAddress: CaesiumNFT.address, loading: false },this.setWeb3);
        enqueueSnackbar("Wallet Connected", { variant: 'success' });
      }
    } catch (error) {
      // Catch any errors for any of the above operations.
      enqueueSnackbar(error.message, { variant: 'error' });
      this.setState({ networkMain: false });
      console.error(error);
      this.setState({ loading: false });
    }
  };

  disconnectFromWallet = async () => {
    try {
      this.setState({ loading: true });
      if (this.state.web3) {
        enqueueSnackbar("Wallet disconnected", { variant: 'success' });
      }
      this.setState(initialState);
    } catch (error) {
      // Catch any errors for any of the above operations.
      enqueueSnackbar(error.message, { variant: 'error' });
      this.setState({ networkMain: false });
      console.error(error);
      this.setState({ loading: false });
    }
  }

  setWeb3 = async () => {
    try {
      this.setState({ loading: true });
      let provider = this.props?.walletProvider;
      let web3 = new Web3(modal);
      const accounts = this.props?.address;
      web3.setProvider(binanceSmartChain.rpcUrl);
      const deployedNetwork = CaesiumNFT.networks;
      const instance = new web3.eth.Contract(
        CaesiumNFT.abi,
        deployedNetwork && CaesiumNFT.address, provider
      );

      const instance2 = new web3.eth.Contract(
        CaesiumMarketplace.abi,
        deployedNetwork && CaesiumMarketplace.address, provider
      );
      this.setState({ web3, nft_contract: instance, accounts: accounts, nft_marketplace: instance2, contractAddress: CaesiumNFT.address,loading:false },()=>{
     
      });
    } catch (error) {
      console.log('setWeb3', error);
    }
  }

  render() {
    let this2 = this;

    return (
      <Frame withHeader={false} withFooter={true}>

        {/* {this.state.loading ? (
          <div className="loader-container">
            <div className="linespinner"></div>
          </div>
        ) : (null)} */}

        <header id="header_main" className="pt-3 pb-3 header_1 header-fixed">
          <div className="container">
            <div className="header-inner">
              <div className="header-left">
                <div className="site-logo">
                  <a href="/" className="main-logo">
                    <img src="/assets/img/logo.png" />
                  </a>
                </div>
                <nav id="main-nav" className="main-nav">
                  <ul id="menu-primary-menu" className="menu">
                    {/* <li className="menu-item"><a href="/marketplace">Marketplace</a></li> */}
                    {/* <li className="menu-item"><a href="/my_nft">My Nft</a></li> */}
                   
                  </ul>
                </nav>
              </div>
              <div className="header-right">
                <div className="flat-wallet flex">                  
                  <div className="d-none d-lg-block ms-3">
                    {this.props.isConnected ? (
                      <a
                        disabled={this.state.loading}
                        // onClick={() => this.props.open()}
                        className="gradient_btn_skew btn pointer"
                      >
                        <i className="icon-sign-out-1"></i> {this.state.YourAddress}
                      </a>
                    ) : (
                      <a
                        disabled={this.state.loading}
                        onClick={() => this.props.open()}
                        className="gradient_btn_skew btn pointer"
                      >
                        <i className="icon-sign-out-1"></i> Connect wallet
                      </a>
                    )}
                  </div>
                  <div className="admin_active">
                    <div className="popup-user relative">
                      <div className="user">
                        <img src="/assets/img/avatar-small-09.png" alt="" />
                      </div>
                      <div className="avatar_popup2">
                        <div className="">
                          <div className="links">
                            {/* <a className="block mb-20" href="#">
                              <img src="/assets/img/boy-avater.png" alt="" />
                              <span>My Profile</span>
                            </a> */}
                            {/* <a className="block mb-20" href="#">
                              <img src="/assets/img/eye.png" alt="" />
                              <span>Watchlist</span>
                            </a> */}
                            {/* <a className="block mb-20" href="/my_nft">
                              <img src="/assets/img/hand-shake.png" alt="" />
                              <span>My NFT</span>
                            </a> */}
                            {this.props.isConnected ? (
                              <a
                                disabled={this.state.loading}
                                onClick={() => this.props.open()}
                                className="block mb-20 pointer"
                              >
                                <img src="/assets/img/logout.png" alt="" />
                                <span> Sign Out</span>
                              </a>
                            ) : (
                              <a
                                disabled={this.state.loading}
                                onClick={() => this.props.open()}
                                className="block mb-20 pointer"
                              >
                                <img src="/assets/img/logout.png" alt="" />
                                <span> Sign In</span>
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mobile-button">
                    <span></span>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="mobile-nav-wrap">
            <div className="overlay-mobile-nav"></div>
            <div className="inner-mobile-nav">
              <a href="/" rel="home" className="main-logo">
                <img id="mobile-logo_header" src="/assets/img/logo.png" />
              </a>
              <div className="mobile-nav-close">
                <svg xmlns="http://www.w3.org/2000/svg" fill="white" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 122.878 122.88" enableBackground="new 0 0 122.878 122.88" space="preserve"><g><path d="M1.426,8.313c-1.901-1.901-1.901-4.984,0-6.886c1.901-1.902,4.984-1.902,6.886,0l53.127,53.127l53.127-53.127 c1.901-1.902,4.984-1.902,6.887,0c1.901,1.901,1.901,4.985,0,6.886L68.324,61.439l53.128,53.128c1.901,1.901,1.901,4.984,0,6.886 c-1.902,1.902-4.985,1.902-6.887,0L61.438,68.326L8.312,121.453c-1.901,1.902-4.984,1.902-6.886,0 c-1.901-1.901-1.901-4.984,0-6.886l53.127-53.128L1.426,8.313L1.426,8.313z" /></g></svg>
              </div>
              <nav id="mobile-main-nav" className="mobile-main-nav">
                <ul id="menu-mobile-menu" className="menu">
                  {/* <li className="menu-item"><a href="/marketplace">Marketplace</a></li>
                  <li className="menu-item"><a href="/my_nft">My Nft</a></li> */}
                </ul>
              </nav>
              <div className="mt-3">
                {this.props.isConnected ? (
                  <a
                    disabled={this.state.loading}
                    onClick={() => this.props.open()}
                    className="gradient_btn_skew btn btn-block pointer"
                  >
                    <i className="icon-sign-out-1"></i> Disconect wallet
                  </a>
                ) : (
                  <a
                    disabled={this.state.loading}
                    onClick={() => this.props.open()}
                    className="gradient_btn_skew btn btn-block pointer"
                  >
                    <i className="icon-sign-out-1"></i> Connect wallet
                  </a>
                )}
              </div>
            </div>
          </div>
        </header>

        <div className="pt-5 pb-5">
          <div className="container">
          <Tabs>
              <TabList className="nav nav-tabs nav-justified mb-3">
                <Tab>INO</Tab>
                <Tab>Marketplace</Tab>
                <Tab>NFT Staking</Tab>
                <Tab>NFT Renting</Tab>
                <Tab>My Assets</Tab>
              </TabList>  
              <TabPanel>
                <Tabs>
                  <TabList className="lien_tab">
                    <Tab>INO</Tab>
                    <Tab>My Purchases</Tab>
                  </TabList>  
                  <TabPanel>
                    {this.state.web3 &&
                      <InoMarketPlace 
                        address={this.props.address}
                        chainId={this.props.chainId}
                        isConnected={this.props.isConnected}
                        signer={this.props.signer}
                        walletProvider={this.props.walletProvider}
                        web3={this.state.web3}
                      />
                    }
                  </TabPanel>
                  <TabPanel>
                    {this.state.web3 &&
                      <InoMyPurchases 
                        address={this.props.address}
                        chainId={this.props.chainId}
                        isConnected={this.props.isConnected}
                        signer={this.props.signer}
                        walletProvider={this.props.walletProvider}
                        web3={this.state.web3}
                      />
                    }
                  </TabPanel>
                </Tabs>
              </TabPanel>
              <TabPanel>
                {this.state.web3 &&
                  <NftMarketPlace 
                    address={this.props.address}
                    chainId={this.props.chainId}
                    isConnected={this.props.isConnected}
                    signer={this.props.signer}
                    walletProvider={this.props.walletProvider}
                    web3={this.state.web3}
                  />
                }
              </TabPanel>
              <TabPanel>
                {this.state.web3 &&
                  <NftStaking 
                    address={this.props.address}
                    chainId={this.props.chainId}
                    isConnected={this.props.isConnected}
                    signer={this.props.signer}
                    walletProvider={this.props.walletProvider}
                    web3={this.state.web3}
                  />
                }
              </TabPanel>
              <TabPanel>
                <Tabs>
                  <TabList className="lien_tab">
                    <Tab>Marketplace</Tab>
                    <Tab>My Purchases</Tab>
                    <Tab>My Adds</Tab>
                  </TabList>  
                  <TabPanel>
                    {this.state.web3 &&
                      <RentingMarketPlace 
                        address={this.props.address}
                        chainId={this.props.chainId}
                        isConnected={this.props.isConnected}
                        signer={this.props.signer}
                        walletProvider={this.props.walletProvider}
                        web3={this.state.web3}
                      />
                    }
                  </TabPanel>
                  <TabPanel>
                    {this.state.web3 &&
                      <RentingMyPurchases 
                        address={this.props.address}
                        chainId={this.props.chainId}
                        isConnected={this.props.isConnected}
                        signer={this.props.signer}
                        walletProvider={this.props.walletProvider}
                        web3={this.state.web3}
                      />
                    }
                  </TabPanel>
                  <TabPanel>
                    {this.state.web3 &&
                      <RentingMyAdds 
                        address={this.props.address}
                        chainId={this.props.chainId}
                        isConnected={this.props.isConnected}
                        signer={this.props.signer}
                        walletProvider={this.props.walletProvider}
                        web3={this.state.web3}
                      />
                    }
                  </TabPanel>
                </Tabs>
              </TabPanel>
              <TabPanel>
                {this.state.web3 &&
                  <NftMyAssets 
                    address={this.props.address}
                    chainId={this.props.chainId}
                    isConnected={this.props.isConnected}
                    signer={this.props.signer}
                    walletProvider={this.props.walletProvider}
                    web3={this.state.web3}
                  />
                }              
              </TabPanel>
            </Tabs>
          </div>
        </div>

      </Frame>
    );
  }
}

function MarketPlaceHOC(props) {
  let { cat_id, sub_cat_id } = useParams();
  const { open } = useWeb3Modal();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { signer, walletProvider } = useWeb3ModalSigner();
  return <MarketPlace
    open={open}
    address={address}
    chainId={chainId}
    isConnected={isConnected}
    signer={signer}
    walletProvider={walletProvider}
    cat_id={cat_id}
    sub_cat_id={sub_cat_id}
    {...props}
  />;
}

export default MarketPlaceHOC;