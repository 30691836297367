import { apikeyCovalenthq, NFT_ASSET_CONTRACT_ADDRESS } from "./constants";

export const nftCategoryAPi = (params) => {
    try {
        let fetch_url = 'https://backend.caesiumlab.com/api/nft-categories';
        let options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };

        return new Promise((resolve, reject) => {
            const result = { status: false, data: [] }
            // setInterval(()=>{
            fetch(fetch_url, options)
                .then(res => res.json())
                .then((json) => {
                    resolve(json);
                })
                .catch(err => console.error('error:' + err));
            //},10000);
        })
    } catch (error) {

    }
}

export const nftDetailsAPi = (token_url) => {
    let options = {
      method: 'GET',
      redirect: 'follow'
    };
    if (token_url.includes('caesiumlab.com')) {
      options = {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      };
    }
  
    return new Promise((resolve, reject) => {
        const result = { status: false, data: [] }
        // setInterval(()=>{
        fetch(token_url, options)
            .then(res => res.json())
            .then((json) => {
                resolve(json);
            })
            .catch(err => console.error('error:' + err));
        //},10000);
    })
}

export const getHoldings = (address) => {
    ////console.log(address);
    const url = `https://api.covalenthq.com/v1/bsc-mainnet/address/${address}/collection/${NFT_ASSET_CONTRACT_ADDRESS}/`
    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${apikeyCovalenthq}`
      }
    };

    return new Promise((resolve, reject) => {
        const result = { status: false, data: [] }
        // setInterval(()=>{
        fetch(url, options)
            .then(res => res.json())
            .then((json) => {
                resolve(json);
            })
            .catch(err => console.error('error:' + err));
        //},10000);
    })
  
    // return new Promise((resolve, reject) => {
    //   const result = { status: false, data: 0 }
    //   axios.get(url, options)
    //     .then(function (response) {
    //       // handle success
    //       let json = response.data;
    //       console.log(json);
    //       if (json.data) {
    //         result.status = true;
    //         result.data = json.data;
    //         resolve(result);
    //       } else {
    //         resolve(result);
    //       }
    //     })
    //     .catch(function (error) {
    //       // handle error
    //       console.log('error axios', error);
    //       reject(error);
    //     })
    //     .finally(function () {
    //       // always executed
    //     });
    // })
}