import React from 'react';
import Frame from "../../components/frame";
import { useParams, useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { CopyToClipboard } from "react-copy-to-clipboard";
import Modal from 'react-bootstrap/Modal';
import CaesiumNFT from "../../contracts/CaesiumNFT.json";
import Web3 from "web3";
import { createWeb3Modal, defaultConfig, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalSigner } from '@web3modal/ethers5/react';
import { nftDetailsAPi } from '../../utils';
import { binanceSmartChain, metadata, projectId, ERC20ABI, SITE_URL,
  ERC1155ABI, NFT_MARKETPLACE_ABI, NFT_ASSET_CONTRACT_ADDRESS,NFT_MaketPlace_ADDRESS,
  RENTING_ABI, CSM_MEMBER_ABI,RENTING_CONTRACT,Caesium_Member_CONTRACT

 } from '../../utils/constants';
import {
  FacebookShareButton,
  TwitterShareButton
} from "react-share";

const modal = createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [binanceSmartChain],
  projectId,
  themeMode: 'dark',
  themeVariables: {
    '--w3m-z-index': 999
  }
})

const initialState = {
  loading: false,
  is_connected: false,
  web3: null,
  accounts: null,
  renting_contract:null,
  nft_asset_contract:null,
  nft_marketplace:null,
  contractMember: null,
  YourAddress: '-----------',
  YourAddressfull: null,
  isOwnedNft: false,
  nft_sell_modal: false,
  currencyAddress: '',
  input_quantity:''
};

class InoDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      web3: null,
      accounts: null,
      renting_contract:null,
      nft_asset_contract:null,
      contractMember: null,
      YourAddress: '-----------',
      YourAddressfull: null,
      nft_id: this.props?.nft_id != "undefined" ? this.props?.nft_id : "",
      nft_detail: null,
      sub_cat_id: null,
      related_items: [],
      isOwnedNft: false,
      nft_sell_modal: false,
      currencyAddress: '',
      input_quantity:''
    }
  }

  componentDidMount = async () => {
    try {
      if(!this.props?.address){
        this.setWeb3();
      }
    } catch (error) {
      this.setState({ networkMain: false, loading: false });
      console.error(error);
    }
  };


  componentDidUpdate = async () => {
    if (this.props?.address && this.props?.address != this.state.accounts) {
      this.setState({ accounts: this.props?.address });
      this.connectToWallet();
    }

    if (this.state.accounts && !this.props?.isConnected) {
      this.disconnectFromWallet();
    }

  }


  connectToWallet = async (e) => {
    try {
      // Get network provider and web3 instance.
      let provider = this.props?.walletProvider;
      let web3 = new Web3(modal);
      web3.setProvider(binanceSmartChain.rpcUrl);
      // Use web3 to get the user's accounts.
      const accounts = this.props?.address;

      const networkId = this.props?.chainId;
      if (networkId !== 56) {
        enqueueSnackbar("Error! Please connect to bsc mainnet", { variant: 'error' });
        this.setState({ loading: false });
        return false;
      }

      if (this.state.accounts !== accounts) {
        // Get the contract instance.

        this.setState({ loading: true });
        const deployedNetwork = CaesiumNFT.networks;
        const nft_asset_contract = new web3.eth.Contract(
          ERC1155ABI,
          deployedNetwork && NFT_ASSET_CONTRACT_ADDRESS, provider
        );
  
        const nft_marketplace = new web3.eth.Contract(
          NFT_MARKETPLACE_ABI,
          deployedNetwork && NFT_MaketPlace_ADDRESS, provider
        );
  
        const renting_contract = new web3.eth.Contract(
          RENTING_ABI,
          deployedNetwork && RENTING_CONTRACT, provider
        );
  
        const contractMember = new web3.eth.Contract(
          CSM_MEMBER_ABI,
          deployedNetwork && Caesium_Member_CONTRACT, provider
        );

        let user_account = accounts;

        this.setState({ loading: false, is_connected: true });
        this.setState({ renting_contract, contractMember, web3, accounts: user_account, nft_asset_contract, nft_marketplace, loading: true }, this.fetchData);

        enqueueSnackbar("Wallet Connected", { variant: 'success' });
      }
    } catch (error) {
      // Catch any errors for any of the above operations.
      enqueueSnackbar(error.message, { variant: 'error' });
      this.setState({ networkMain: false });
      console.error(error);
      this.setState({ loading: false });
    }
  };

  disconnectFromWallet = async () => {
    try {
      this.setState({ loading: true });
      if (this.state.web3) {
        enqueueSnackbar("Wallet disconnected", { variant: 'success' });
      }
      this.setState(initialState);
    } catch (error) {
      // Catch any errors for any of the above operations.
      enqueueSnackbar(error.message, { variant: 'error' });
      this.setState({ networkMain: false });
      console.error(error);
      this.setState({ loading: false });
    }
  }

  fetchData = async () => {
    let { accounts, nft_asset_contract,renting_contract } = this.state;
    let YourAddress = this.state.YourAddress;
    if(accounts)
    YourAddress = accounts.slice(0, 6) + '.....' + accounts.slice(accounts.length - 5, accounts.length);
    let YourAddressfull = accounts;

    let isOwnedNft = false;
    let nft_detail = this.state.nft_detail;
    let sub_cat_id = this.state.sub_cat_id;    

    let nft_market = await renting_contract.methods.getNftByListingId(this.state.nft_id).call();

    nft_market = {
      itemId:parseInt(nft_market[0]),
      tokenId:parseInt(nft_market[1]),
      category:nft_market[3],
      price:parseInt(nft_market[4]),
      currency:nft_market[5],
      quantity:parseInt(nft_market[6]),
      timePeriod:parseInt(nft_market[7]),
      owner:nft_market[8]
    }
    
    let token_url = await nft_asset_contract.methods.geturi(nft_market.tokenId).call();

    let decimals = 1e18;
    let symbol = 'BNB';

    let nft_details = await nftDetailsAPi(token_url);
    // console.log('nft_details',nft_details);
    nft_details = nft_details;
    let attributes = nft_details?.attributes;
    nft_market.nft_details = nft_details;
    nft_market.attributes = attributes;
    nft_market.decimals = decimals;
    nft_market.symbol = symbol;
    nft_detail = nft_market;
    sub_cat_id = nft_detail.category;
    if (nft_market?.owner?.toLowerCase() == accounts?.toLowerCase()) {
      isOwnedNft = true;
    }

    this.setState({
      YourAddress,
      YourAddressfull,
      nft_detail,
      sub_cat_id,
      isOwnedNft,
      loading: false,
      is_refresh: false
    });
    // const script = document.createElement('script')
    // script.src = '/assets/js/image_cn.js';
    // script.async = true;
    // document.body.append(script)
  }

  setWeb3 = async ()=>{
    try {      
      this.setState({loading:true});
      let provider = this.props?.walletProvider;
      let web3 = new Web3(modal);
      const accounts = this.props?.address;
      web3.setProvider(binanceSmartChain.rpcUrl);
      const deployedNetwork = CaesiumNFT.networks;
      const nft_asset_contract = new web3.eth.Contract(
        ERC1155ABI,
        deployedNetwork && NFT_ASSET_CONTRACT_ADDRESS, provider
      );

      const nft_marketplace = new web3.eth.Contract(
        NFT_MARKETPLACE_ABI,
        deployedNetwork && NFT_MaketPlace_ADDRESS, provider
      );

      const renting_contract = new web3.eth.Contract(
        RENTING_ABI,
        deployedNetwork && RENTING_CONTRACT, provider
      );

      const contractMember = new web3.eth.Contract(
        CSM_MEMBER_ABI,
        deployedNetwork && Caesium_Member_CONTRACT, provider
      );

      this.setState({ renting_contract, contractMember, web3, nft_asset_contract, accounts:accounts, nft_marketplace},()=>{
        this.fetchData();
      });
    } catch (error) {
        console.log('setWeb3',error);
    }
  }

  doRentNft = async () => {
    try {
      if(!this.state.accounts){
        enqueueSnackbar('Please connect to wallet.', { variant: "error" });
        this.setState({ loading: false });
        return false;
      }
      if(!this.state.input_quantity){
        enqueueSnackbar('Quantity is required!', { variant: "error" });
        this.setState({ loading: false });
        return false;
      }
      let remain_qty = parseInt(this.state.nft_detail.quantity);
      if(parseInt(this.state.input_quantity)>parseInt(remain_qty)){
        enqueueSnackbar(`${remain_qty} Quantity is left.`, { variant: "error" });
        this.setState({loading:false});
        return false;
      }
      
      let amount = parseFloat(this.state.nft_detail.price);
      let quantity = parseInt(this.state.input_quantity||1);
      
      let total_amount = amount*quantity;
      let weiValue = 0;

      // let balance = await this.state.web3.eth.getBalance(this.state.accounts);
      
      // if(parseFloat(balance)<parseFloat(total_amount)){
      //   enqueueSnackbar(`Insfucient Funds!!`, { variant: "error" });
      //   this.setState({loading:false});
      //   return false;
      // }

      let currency_contract=this.state.nft_detail.currency;
      let is_approve = true;
      let Contract;

      if(/^0x00+$/.test(currency_contract)){
        is_approve = false;
        weiValue = total_amount.toString()
        let balance = await this.state.web3.eth.getBalance(this.state.accounts);        
        if(parseFloat(balance)<parseFloat(total_amount)){
          enqueueSnackbar(`Insfucient Funds!!`, { variant: "error" });
          this.setState({loading:false});
          return false;
        }  
      }else{
        let provider = this.props?.walletProvider;
        let web3 = new Web3(modal);
        web3.setProvider(binanceSmartChain.rpcUrl);
        const deployedNetwork = CaesiumNFT.networks;
        Contract = new web3.eth.Contract(
          ERC20ABI,
          deployedNetwork && currency_contract, provider
        );
        let balance = await Contract.methods.balanceOf(this.state.accounts).call();
        if(parseFloat(balance)<parseFloat(total_amount)){
          enqueueSnackbar(`Insfucient Funds!!`, { variant: "error" });
          this.setState({password:'',loading:false});
          return false;
        }
      }

      this.setState({loading:true});

      let itemId=this.state.nft_detail.itemId;
      let gasPrice = await this.state.web3.eth.getGasPrice();
      gasPrice = parseInt(gasPrice) + 20;
      let gasLimit = 10000000;
      let estimateGas = 100000;

      let options = {
        from: this.state.accounts,
        gas: gasLimit,
        value:weiValue
      };

      if(is_approve){
        let data = await Contract.methods
          .approve(RENTING_CONTRACT, total_amount.toString())
          .estimateGas(options);

        estimateGas = parseInt(data) + 10000;

        let approve_data = await Contract.methods
          .approve(RENTING_CONTRACT, total_amount.toString())
          .send({
            from: this.state.accounts,
            value: 0,
            data: {
              "constant": false,
              "inputs": [
                {
                  "name": "_spender",
                  "type": "address"
                },
                {
                  "name": "_value",
                  "type": "uint256"
                }
              ],
              "name": "approve",
              "outputs": [
                {
                  "name": "",
                  "type": "bool"
                }
              ],
              "payable": false,
              "stateMutability": "nonpayable",
              "type": "function"
            },
            gas: estimateGas.toString(),
            gasPrice: gasPrice.toString(),
          });
        if (approve_data.status) {
          let options = {
            from: this.state.accounts,
            gas: gasLimit,
            value: 0,
          };  
          let data = await this.state.renting_contract.methods.rentalNFT(
            itemId.toString(),quantity
          ).estimateGas(options);
    
          estimateGas = parseInt(data) + 10000;
          let claim_data = await this.state.renting_contract.methods.rentalNFT(
            itemId.toString(),quantity
            ).send({
              from: this.state.accounts,
              value: weiValue,
              data: {
                "inputs": [
                  {
                    "internalType": "uint256",
                    "name": "itemId",
                    "type": "uint256"
                  },
                  {
                    "internalType": "uint256",
                    "name": "quantity",
                    "type": "uint256"
                  }
                ],
                "name": "rentalNFT",
                "outputs": [],
                "stateMutability": "payable",
                "type": "function"
              },
              gas: estimateGas.toString(),
              gasPrice: gasPrice.toString(),
            });
    
          if (claim_data.status) {
            this.setState({ loading: false });
            enqueueSnackbar("NFT Rent Successfully!", { variant: "success" });
            this.fetchData();
          } else {
            this.setState({ loading: false });
            enqueueSnackbar("NFT Rent failed!");
          }
        }
      }else{
        let data = await this.state.renting_contract.methods.rentalNFT(
          itemId.toString(),quantity
        ).estimateGas(options);
  
        estimateGas = parseInt(data) + 10000;
        let claim_data = await this.state.renting_contract.methods.rentalNFT(
          itemId.toString(),quantity
          ).send({
            from: this.state.accounts,
            value: weiValue,
            data: {
              "inputs": [
                {
                  "internalType": "uint256",
                  "name": "itemId",
                  "type": "uint256"
                },
                {
                  "internalType": "uint256",
                  "name": "quantity",
                  "type": "uint256"
                }
              ],
              "name": "rentalNFT",
              "outputs": [],
              "stateMutability": "payable",
              "type": "function"
            },
            gas: estimateGas.toString(),
            gasPrice: gasPrice.toString(),
          });
  
        if (claim_data.status) {
          this.setState({ loading: false,input_quantity:'' });
          enqueueSnackbar("NFT Rent Successfully!", { variant: "success" });
          this.fetchData();
        } else {
          this.setState({ loading: false });
          enqueueSnackbar("NFT Rent failed!");
        }
      }
      
      

    } catch (error) {
      this.setState({ loading: false });
      console.log('buy nft error', error);
      if (error.message.includes("User denied transaction signature")) {
        // handle the "error" as a rejection
        enqueueSnackbar(error.message, { variant: "error" });
      } else {
        enqueueSnackbar(error, { variant: "error" });
      }
    }
  }


  render() {
    let this2 = this;
    return (
      <Frame withHeader={false} withFooter={true}>

        {this.state.loading ? (
          <div className="loader-container">
            <div className="linespinner"></div>
          </div>
        ) : (null)}

        <header id="header_main" className="pt-3 pb-3 header_1 header-fixed">
          <div className="container">
            <div className="header-inner">
              <div className="header-left">
                <div className="site-logo">
                  <a href="/" className="main-logo">
                    <img src="/assets/img/logo.png" />
                  </a>
                </div>
                <nav id="main-nav" className="main-nav">
                  <ul id="menu-primary-menu" className="menu">
                    {/* <li className="menu-item"><a href="/marketplace">Marketplace</a></li> */}
                    {/* <li className="menu-item"><a href="/my_nft">My Nft</a></li> */}
                   
                  </ul>
                </nav>
              </div>
              <div className="header-right">
                <div className="flat-wallet flex">                  
                  <div className="d-none d-lg-block ms-3">
                    {this.props.isConnected ? (
                      <a
                        disabled={this.state.loading}
                        // onClick={() => this.props.open()}
                        className="gradient_btn_skew btn pointer"
                      >
                        <i className="icon-sign-out-1"></i> {this.state.YourAddress}
                      </a>
                    ) : (
                      <a
                        disabled={this.state.loading}
                        onClick={() => this.props.open()}
                        className="gradient_btn_skew btn pointer"
                      >
                        <i className="icon-sign-out-1"></i> Connect wallet
                      </a>
                    )}
                  </div>
                  <div className="admin_active">
                    <div className="popup-user relative">
                      <div className="user">
                        <img src="/assets/img/avatar-small-09.png" alt="" />
                      </div>
                      <div className="avatar_popup2">
                        <div className="">
                          <div className="links">
                            {/* <a className="block mb-20" href="#">
                              <img src="/assets/img/boy-avater.png" alt="" />
                              <span>My Profile</span>
                            </a> */}
                            {/* <a className="block mb-20" href="#">
                              <img src="/assets/img/eye.png" alt="" />
                              <span>Watchlist</span>
                            </a>
                            <a className="block mb-20" href="/my_nft">
                              <img src="/assets/img/hand-shake.png" alt="" />
                              <span>My NFT</span>
                            </a> */}
                            {this.props.isConnected ? (
                              <a
                                disabled={this.state.loading}
                                onClick={() => this.props.open()}
                                className="block mb-20 pointer"
                              >
                                <img src="/assets/img/logout.png" alt="" />
                                <span> Sign Out</span>
                              </a>
                            ) : (
                              <a
                                disabled={this.state.loading}
                                onClick={() => this.props.open()}
                                className="block mb-20 pointer"
                              >
                                <img src="/assets/img/logout.png" alt="" />
                                <span> Sign In</span>
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mobile-button">
                    <span></span>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="mobile-nav-wrap">
            <div className="overlay-mobile-nav"></div>
            <div className="inner-mobile-nav">
              <a href="/" rel="home" className="main-logo">
                <img id="mobile-logo_header" src="/assets/img/logo.png" />
              </a>
              <div className="mobile-nav-close">
                <svg xmlns="http://www.w3.org/2000/svg" fill="white" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 122.878 122.88" enableBackground="new 0 0 122.878 122.88" space="preserve"><g><path d="M1.426,8.313c-1.901-1.901-1.901-4.984,0-6.886c1.901-1.902,4.984-1.902,6.886,0l53.127,53.127l53.127-53.127 c1.901-1.902,4.984-1.902,6.887,0c1.901,1.901,1.901,4.985,0,6.886L68.324,61.439l53.128,53.128c1.901,1.901,1.901,4.984,0,6.886 c-1.902,1.902-4.985,1.902-6.887,0L61.438,68.326L8.312,121.453c-1.901,1.902-4.984,1.902-6.886,0 c-1.901-1.901-1.901-4.984,0-6.886l53.127-53.128L1.426,8.313L1.426,8.313z" /></g></svg>
              </div>
              <nav id="mobile-main-nav" className="mobile-main-nav">
                <ul id="menu-mobile-menu" className="menu">
                  {/* <li className="menu-item"><a href="/marketplace">Marketplace</a></li>
                  <li className="menu-item"><a href="/my_nft">My Nft</a></li> */}
                </ul>
              </nav>
              <div className="mt-3">
                {this.props.isConnected ? (
                  <a
                    disabled={this.state.loading}
                    onClick={() => this.props.open()}
                    className="gradient_btn_skew btn btn-block pointer"
                  >
                    <i className="icon-sign-out-1"></i> Disconect wallet
                  </a>
                ) : (
                  <a
                    disabled={this.state.loading}
                    onClick={() => this.props.open()}
                    className="gradient_btn_skew btn btn-block pointer"
                  >
                    <i className="icon-sign-out-1"></i> Connect wallet
                  </a>
                )}
              </div>
            </div>
          </div>
        </header>

        <div className="pb-4 pt-5">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <div className="tf-card-box style-5 bg-none mb-4">
                  <div className="card-media mb-0">
                    <div className="mygif_img_details">
                      <canvas className="mycanvas_gif" width="512" height="512"></canvas>
                      {this.state.nft_detail?.nft_details?.image ? (
                        <img className="my_gif_img" src={this.state.nft_detail?.nft_details?.image} />
                      ) : (
                        <img className="my_gif_img" src="/assets/img/csm-nft-no-image.jpg" />
                      )}
                    </div>
                  </div>
                  {/* <h6 className="price gem"><img className='icon-bnb' src='/assets/img/Binance_icon.png' /></h6> */}
                  {/* <div className="wishlist-button">10<i className="icon-heart"></i></div> */}
                </div>


              </div>
              <div className="col-md-7">
                <div className="infor-product position-relative">
                  <div className="text">{this.state.nft_detail?.category} Main Collection <span className="icon-tick"><span className="path1"></span><span className="path2"></span></span></div>
                  <div className="menu_card">
                    <div className="dropdown">
                      <div className="icon">
                        <a href="javascript:void(0);" className="btn-link" data-bs-toggle="dropdown" aria-expanded="false">
                          <i className="icon-link-1"></i>
                        </a>
                        <div className="dropdown-menu">
                          <CopyToClipboard
                            text={`${SITE_URL}renting_details/${btoa(this.props.nft_id)}/${btoa(this.props.from_action)}`}
                            onCopy={() =>
                              enqueueSnackbar("Copied Successfully!", {
                                variant: "info",
                              })
                            }
                          >
                            <a className="dropdown-item" ><i className="icon-link"></i>Copy link</a>
                          </CopyToClipboard>
                          <a className="dropdown-item">
                            <FacebookShareButton style={{marginTop:5}} url={`${SITE_URL}renting_details/${btoa(this.props.nft_id)}/${btoa(this.props.from_action)}`}>
                              <i className="icon-facebook"></i><span style={{marginBottom:5}}> Share on facebook</span>
                            </FacebookShareButton>
                          </a>

                          <a className="dropdown-item mb-0" >
                            <TwitterShareButton style={{marginTop:5}} url={`${SITE_URL}renting_details/${btoa(this.props.nft_id)}/${btoa(this.props.from_action)}`}>
                              <i className="icon-twitter"></i><span style={{marginBottom:5}}> Share on twitter</span>
                            </TwitterShareButton>
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* <div className="dropdown">
                      <div className="icon">
                        <a href="javascript:void(0);" className="btn-link" data-bs-toggle="dropdown" aria-expanded="false">
                          <i className="icon-content"></i>
                        </a>
                        <div className="dropdown-menu">
                          <a className="dropdown-item" ><i className="icon-refresh"></i>Refresh metadata</a>
                          <a className="dropdown-item mb-0" ><i className="icon-report"></i>Report</a>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <h2>NFT ID : #{this.state.nft_detail?.tokenId}</h2>
                  <div className="author flex items-center mb-30">
                    <div className="avatar">
                      <img src="/assets/img/avatar-box-01.jpg" alt="Image" />
                    </div>
                    <div className="info">
                      <span>Owned by:</span>
                      <h6><a >{this.state.nft_detail?.owner ? (
                        this.state.nft_detail?.owner.slice(0, 10) + '.....' + this.state.nft_detail?.owner.slice(this.state.nft_detail?.owner.length - 5, this.state.nft_detail?.owner.length)
                      ) : ('---')}</a> </h6>
                    </div>
                  </div>
                  {/* <div className="meta mb-20">
                    <div className="meta-item view">
                      <i className="icon-show"></i>208 view
                    </div>
                    <div className="meta-item rating">
                      <i className="icon-link-2"></i>Top #2 trending
                    </div>
                    <div className="meta-item favorites">
                      <i className="icon-heart"></i>10 favorites
                    </div>
                  </div> */}
                </div>
                {this.props.from_action == 'RentingMarketPlace' && this.state.accounts ? (
                  this.state.accounts?.toLowerCase() != this.state.nft_detail?.owner?.toLowerCase()?(
                  <div className="product-item time-sales mt-5">
                    <div className="content">
                      <div className="mb-3">
                        <label className="form-label">Enter Quantity</label>
                        <input
                          type="text"
                          className="style-1"
                          placeholder="Quantity"
                          onChange={(e) =>
                            this.setState({ input_quantity: e.target.value })
                          }
                          value={this.state.input_quantity}
                        />
                      </div>
                      {/* <div className="text">Current price</div> */}
                      <div className="flex justify-between">                        
                        <a disabled={this.state.loading} onClick={() => this.doRentNft()} className="tf-button style-1 h50 w216 pointer">Rent</a>
                      </div>
                    </div>
                  </div>
                  ):(null)
                ):(null)}
                
              </div>

              <div className="col-12">
                <div className="product-item traits style-1">
                  <h6><i className="icon-description"></i>Traits</h6>
                  <i className="icon-keyboard_arrow_down"></i>
                  <div className="content">
                    {this.state.nft_detail?.attributes?.length ? (
                      this.state.nft_detail?.attributes.map(function (item, index) {
                        return (
                          <div key={`li${index}`} className="trait-item">
                            <p>{item.trait_type}</p>
                            <div className="title">{item.value}</div>
                            {/* <p>Floor: 0,056 ETH</p> */}
                          </div>
                        )
                      })
                    ) : (
                      <p className='text-center'>No data available</p>
                    )}

                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

      </Frame>
    );
  }
}

function InoDetailsHOC(props) {
  let { nft_id, from_action } = useParams();
  const { open } = useWeb3Modal();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { signer, walletProvider } = useWeb3ModalSigner();
  const navigate = useNavigate();
  if (!nft_id) {
    navigate("/");
  }
  if (!address) {
    navigate("/");
  }
  nft_id = atob(nft_id);
  if(from_action){
    from_action = atob(from_action);
  }

  return <InoDetails
    open={open}
    address={address}
    chainId={chainId}
    isConnected={isConnected}
    signer={signer}
    walletProvider={walletProvider}
    nft_id={nft_id}
    from_action={from_action}
    {...props}
  />;
}

export default InoDetailsHOC;