import React from 'react';
//import { withTranslation } from 'react-i18next';

class Footer extends React.Component {
  // constructor(props) {
  //   super(props);
  // }
  render() {
    //const { t } = this.props;
    return (
      <>
        <footer className="pt-5 pb-5">
          <div className="container">
            <div className="footer-bottom style-1 items-center">
              <p className="mb-0">Copyright © 2023 - 2024 Caesium | All Rights Reserved.</p>
              <div className="widget-social">
                <ul className="flex mb-0">
                  <li><a href="#" className="icon-facebook"></a></li>
                  <li><a href="#" className="icon-twitter"></a></li>
                  <li><a href="#" className="icon-vt"></a></li>
                  <li><a href="#" className="icon-tiktok"></a></li>
                  <li><a href="#" className="icon-youtube"></a></li>
                </ul>
              </div>
              <ul className="flex mb-0">
                <li>
                  <a href="#">Privacy Policy</a>
                </li>
                <li>
                  <a href="#">Terms of Service</a>
                </li>
              </ul>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default Footer;
//export default withTranslation()(Footer);