import React from 'react';
import CaesiumNFT from "../contracts/CaesiumNFT.json";
import { nftDetailsAPi } from '../utils';
import { ERC1155ABI, RENTING_ABI, CSM_MEMBER_ABI, NFT_ASSET_CONTRACT_ADDRESS,RENTING_CONTRACT,Caesium_Member_CONTRACT } from '../utils/constants';

class RentingMyAdds extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      web3: null,
      accounts: null,
      nft_asset_contract:null,
      renting_contract:null,
      contractMember:null,
      contractAddress: null,
      contractAddress_Short: '-',
      YourAddress: '-----------',
      YourAddressfull: null,
      total_nft_marketplace: 0,
      nft_marketplace_list: [],
      page_number: 1,
      page_limit: 12,
      category_list: [],
      category_list_obj: [],
      active_cat: null,
      active_sub_cat: null,
      pagination_m: null,
      current_page_m: 1,
      load_page_m: 1,
    }
  }

  componentDidMount = async () => {
    try {
      if(this.props.web3){
        let provider = this.props?.walletProvider;
        let web3 = this.props.web3;
        const deployedNetwork = CaesiumNFT.networks;
        const nft_asset_contract = new web3.eth.Contract(
          ERC1155ABI,
          deployedNetwork && NFT_ASSET_CONTRACT_ADDRESS, provider
        );

        const renting_contract = new web3.eth.Contract(
          RENTING_ABI,
          deployedNetwork && RENTING_CONTRACT, provider
        );

        const contractMember = new web3.eth.Contract(
          CSM_MEMBER_ABI,
          deployedNetwork && Caesium_Member_CONTRACT, provider
        );
        this.setState({nft_asset_contract,renting_contract,contractMember,loading:true},()=>{
          if(this.props?.address){
            this.fetchData();
          }else{
            this.setState({nft_marketplace_list:[],loading:false});
          }
        });        
      }
    } catch (error) {
      this.setState({ networkMain: false, loading: false });
      console.error(error);
    }
  };

  fetchData = async () => {
    try {
      const {nft_asset_contract,renting_contract} = this.state;
      let getPurchaseData = await renting_contract.methods.getItemsByAddress(this.props?.address).call();
      let nft_marketplace_list = [];
      let pagination_m = null;

      if(getPurchaseData.length > 0){
        let data_nft = getPurchaseData;
        
        getPurchaseData = data_nft.length;
        pagination_m = this.paginate(getPurchaseData, 1);
        for (var i = getPurchaseData - 1; i >= 0; i--) {
          let nft_market = data_nft[i];
          nft_market = {
            itemId:parseInt(nft_market[0]),
            tokenId:parseInt(nft_market[1]),
            category:nft_market[3],
            price:parseInt(nft_market[4]),
            currency:nft_market[5],
            quantity:parseInt(nft_market[6]),
            timePeriod:parseInt(nft_market[7]),
            owner:nft_market[8]
          }

          let balanceOf = await nft_asset_contract.methods.balanceOf(this.props?.address,nft_market.tokenId).call()
          if(parseFloat(balanceOf) <= 0){
            continue;
          }
          nft_market.qty = balanceOf;

          let token_url = await nft_asset_contract.methods.geturi(nft_market.tokenId).call();
          
          let decimals = 1e18;
          let symbol = 'BNB';
          let nft_details = await nftDetailsAPi(token_url);
          nft_details = nft_details;
          let attributes = nft_details.attributes;
          nft_market.nft_details = nft_details;
          nft_market.attributes = attributes;
          nft_market.decimals = decimals;
          nft_market.symbol = symbol;
          nft_marketplace_list.push(nft_market);
          if(nft_marketplace_list.length%4 == 0){
            this.setState({
              nft_marketplace_list,pagination_m, loading: false
            });
          }
          if(nft_marketplace_list.length==this.state.page_limit){
            break;
          }
                   
        }
      }

      this.setState({
        getPurchaseData,
        nft_marketplace_list,
        pagination_m,
        loading:false,
        is_refresh: false
      })
      
      // const script = document.createElement('script')
      // script.src = '/assets/js/image_cn.js';
      // script.async = true;
      // document.body.append(script);
    } catch (error) {
      this.setState({
        loading:false,
        is_refresh: false
      })
      console.log('fetch error', error);
    }
  }

  paginate = (
    totalItems,
    currentPage = 1,
    pageSize = 12,
    maxPages = 10
  ) => {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage, endPage;
    if (totalPages <= maxPages) {
      // total pages less than max so show all pages
      startPage = 1;
      endPage = totalPages;
    } else {
      // total pages more than max so calculate start and end pages
      let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
      let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
      if (currentPage <= maxPagesBeforeCurrentPage) {
        // current page near the start
        startPage = 1;
        endPage = maxPages;
      } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
        // current page near the end
        startPage = totalPages - maxPages + 1;
        endPage = totalPages;
      } else {
        // current page somewhere in the middle
        startPage = currentPage - maxPagesBeforeCurrentPage;
        endPage = currentPage + maxPagesAfterCurrentPage;
      }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

  getPaginationDataM = async () => {
    try {
      this.setState({ loading: true });
      const {nft_asset_contract,renting_contract} = this.state;
      let getPurchaseData = await renting_contract.methods.getItemsByAddress(this.props?.address).call();
      let nft_marketplace_list = [];      

      let pagination_m = this.state.pagination_m;
      let current_page = this.state.current_page_m;
      let load_page_m = current_page;
      let cnt = 0;
      pagination_m = this.paginate(getPurchaseData.length, current_page);
      let total_length = getPurchaseData.length;
      let show_data = total_length - (current_page - 1) * pagination_m.pageSize;
      let data_nft = getPurchaseData;

      // if(getPurchaseData.length>0){
      if (show_data > 0) {
        // for(var i=0; i<getWithDrawList.length;i++){
        for (var i = show_data - 1; i >= 0; i--) {
          if (cnt >= pagination_m.pageSize) {
            break;
          }
          let nft_market = data_nft[i];
          nft_market = {
            itemId:parseInt(nft_market[0]),
            tokenId:parseInt(nft_market[1]),
            category:nft_market[3],
            price:parseInt(nft_market[4]),
            currency:nft_market[5],
            quantity:parseInt(nft_market[6]),
            timePeriod:parseInt(nft_market[7]),
            owner:nft_market[8]
          }

          let balanceOf = await nft_asset_contract.methods.balanceOf(this.props?.address,nft_market.tokenId).call()
          if(parseFloat(balanceOf) <= 0){
            continue;
          }
          nft_market.qty = balanceOf;

          let token_url = await nft_asset_contract.methods.geturi(nft_market.tokenId).call();
          
          let decimals = 1e18;
          let symbol = 'BNB';
          let nft_details = await nftDetailsAPi(token_url);
          
          nft_details = nft_details;
          let attributes = nft_details.attributes;
          nft_market.nft_details = nft_details;
          nft_market.attributes = attributes;
          nft_market.decimals = decimals;
          nft_market.symbol = symbol;
          nft_marketplace_list.push(nft_market);
          if(nft_marketplace_list.length%4 == 0){
            this.setState({
              nft_marketplace_list,pagination_m, load_page_m,loading: false
            });
          }
          if(nft_marketplace_list.length==this.state.page_limit){
            break;
          }
          cnt++;
        }
      }

      this.setState({ nft_marketplace_list, pagination_m, load_page_m, loading: false });
      // const script = document.createElement('script')
      // script.src = '/assets/js/image_cn.js';
      // script.async = true;
      // document.body.append(script);
    } catch (error) {
      this.setState({ loading: false });
      console.log('pagination error', error);
    }
  }

  render() {
    let this2 = this;

    return (
      <>

        {this.state.loading ? (
          <div className="loader-container">
            <div className="linespinner"></div>
          </div>
        ) : (null)}

        <div className="row">
          <div className="col-md-12">
            <div className="row g-3">
              {this.state.nft_marketplace_list ? (
                this.state.nft_marketplace_list.length > 0 ? (
                  this.state.nft_marketplace_list.map(function (item, index) {
                    let image_url = item?.nft_details?.image;
                    return (
                      <div className="col-lg-3 col-md-4 col-6" key={`nft_list${index}`}>
                        <div className="tf-card-box style-1">
                          <div className="card-media">
                            <div className="mygif_img_box">
                              <canvas className="mycanvas_gif" width="512" height="512"></canvas>
                              {image_url ? (
                                <img className="my_gif_img" src={image_url} />
                              ) : (
                                <img className="my_gif_img" src="/assets/img/csm-nft-no-image.jpg" />
                              )}                              
                            </div>
                            
                            <div className="button-place-bid">
                              <a href={`/my_adds_details/${btoa(item.itemId)}/${btoa('MyAdds')}`} className="tf-button"><span>View Detail</span></a>
                            </div>
                          </div>
                          <h5 className="name"><a >{item.nft_details.name} #{item.tokenId}</a></h5>
                          
                          <h6 className='price gem mt-2'>Qty : {item.quantity}</h6>                          
                          <div className="divider"></div>
                          <div className="meta-info flex items-center justify-between">
                            
                            
                          </div>
                        </div>
                      </div>
                    )
                  })
                ) : (
                  <div className='col-md-12'>
                    <div className='heading-section middle_heading text-center justify-content-center'>
                      <img className='no_data_img' src='/assets/img/no_data_found.png' />
                      <h1 className='tf-title mb-0 mt-3'>No data available</h1>
                    </div>
                  </div>
                )
              ) : (
                <div className='col-md-12'>
                  <div className='heading-section middle_heading text-center justify-content-center'>
                    <img className='no_data_img' src='/assets/img/no_data_found.png' />
                    <h1 className='tf-title mb-0 mt-3'>No data available</h1>
                  </div>
                </div>
              )}
            </div>

            {this.state.nft_marketplace_list && this.state.nft_marketplace_list?.length ? (
              <div className="widget-pagination mt-5">
                <ul className="justify-center">
                  <li>
                    <a
                      style={{
                        cursor: this2.state.current_page_m > 1 ? "pointer" : "auto",
                      }}
                      onClick={() => {
                        this2.setState(
                          { current_page_m: this2.state.current_page_m > 1 ? this2.state.current_page_m - 1 : 1 },
                          () => {
                            if (this2.state.load_page_m > 1) {
                              this2.getPaginationDataM();
                            }
                          }
                        );
                      }}
                    ><i className="icon-keyboard_arrow_left"></i></a>
                  </li>
                  {this.state.pagination_m.pages.length > 0
                    ? this.state.pagination_m.pages.map(function (
                      val,
                      index
                    ) {
                      let className = "";
                      if (val == this2.state.current_page_m) {
                        className = "active";
                      }
                      return (
                        <li
                          key={`wlp${index}`}
                          className={className}
                          style={{
                            cursor: val ? "pointer" : "initial",
                          }}
                        >
                          <a
                            onClick={() => {
                              if (val) {
                                this2.setState(
                                  { current_page_m: val },
                                  () => {
                                    this2.getPaginationDataM();
                                  }
                                );
                              }
                            }}
                            dangerouslySetInnerHTML={{
                              __html: val,
                            }}
                          ></a>
                        </li>
                      );
                    })
                    : null}
                  <li>
                    <a
                      style={{
                        cursor: this2.state.current_page_m < this2.state.pagination_m?.totalPages ? "pointer" : "auto",
                      }}
                      onClick={() => {
                        this2.setState(
                          { current_page_m: this2.state.current_page_m < this2.state.pagination_m?.totalPages ? this2.state.current_page_m + 1 : this2.state.pagination_m?.totalPages },
                          () => {
                            if (this2.state.load_page_m < this2.state.pagination_m?.totalPages) {
                              this2.getPaginationDataM();
                            }
                          }
                        );
                      }}
                    ><i className="icon-keyboard_arrow_right"></i></a>
                  </li>
                </ul>
              </div>
            ) : null}
          </div>
        </div>

      </>
    );
  }
}

export default RentingMyAdds;