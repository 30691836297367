import React from 'react';
import Frame from "../../components/frame";
import { useParams, useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { CopyToClipboard } from "react-copy-to-clipboard";
import Modal from 'react-bootstrap/Modal';
import CaesiumNFT from "../../contracts/CaesiumNFT.json";
import Web3 from "web3";
import { createWeb3Modal, defaultConfig, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalSigner } from '@web3modal/ethers5/react';
import { nftDetailsAPi } from '../../utils';
import { binanceSmartChain, currency_arr, decimal_arr, metadata, projectId, ERC20ABI, SITE_URL,
  ERC1155ABI, NFT_MARKETPLACE_ABI, NFT_ASSET_CONTRACT_ADDRESS,NFT_MaketPlace_ADDRESS,
  GAME_INO_ABI, CSM_MEMBER_ABI,GAME_INO_CONTRACT_ADDRESS,Caesium_Member_CONTRACT,
  NFT_STAKING_CONTRACT,NFT_STAKING_ABI,RENTING_CONTRACT,RENTING_ABI
 } from '../../utils/constants';
import {
  FacebookShareButton,
  TwitterShareButton
} from "react-share";

const modal = createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [binanceSmartChain],
  projectId,
  themeMode: 'dark',
  themeVariables: {
    '--w3m-z-index': 999
  }
})

const initialState = {
  loading: false,
  is_connected: false,
  web3: null,
  accounts: null,
  game_ino_contract:null,
  nft_asset_contract:null,
  nft_marketplace:null,
  contractMember: null,
  nft_staking_contract_:null,
  renting_contract:null,
  YourAddress: '-----------',
  YourAddressfull: null,
  isOwnedNft: false,
  nft_sell_modal: false,
  input_sell_price: '',
  currencyAddress: '',
  input_quantity:'',
  isApprovedForAll:false,
  quantity:'',
  input_sell_quantity:'',
  nft_rent_modal:false,
  input_rent_price:'',
  input_rent_quantity:'',
  rent_currencyAddress:'',
  time_period:0,
  no_of_days:'',
  no_of_hours:'',
  no_of_minutes:''
};

class NftDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      web3: null,
      accounts: null,
      game_ino_contract:null,
      nft_asset_contract:null,
      contractMember: null,
      nft_staking_contract_:null,
      renting_contract:null,
      YourAddress: '-----------',
      YourAddressfull: null,
      nft_id: this.props?.nft_id != "undefined" ? this.props?.nft_id : "",
      nft_detail: null,
      sub_cat_id: null,
      related_items: [],
      isOwnedNft: false,
      nft_sell_modal: false,
      input_sell_price: '',
      currencyAddress: '',
      input_quantity:'',
      isApprovedForAll:false,
      quantity:'',
      input_sell_quantity:'',
      nft_rent_modal: false,
      input_rent_price:'',
      input_rent_quantity:'',
      rent_currencyAddress:'',
      time_period:0,
      no_of_days:'',
      no_of_hours:'',
      no_of_minutes:''
    }
  }

  componentDidMount = async () => {
    try {
      if(!this.props?.address){
        this.setWeb3();
      }
    } catch (error) {
      this.setState({ networkMain: false, loading: false });
      console.error(error);
    }
  };


  componentDidUpdate = async () => {
    if (this.props?.address && this.props?.address != this.state.accounts) {
      this.setState({ accounts: this.props?.address });
      this.connectToWallet();
    }

    if (this.state.accounts && !this.props?.isConnected) {
      this.disconnectFromWallet();
    }

  }


  connectToWallet = async (e) => {
    try {
      // Get network provider and web3 instance.
      let provider = this.props?.walletProvider;
      let web3 = new Web3(modal);
      web3.setProvider(binanceSmartChain.rpcUrl);
      // Use web3 to get the user's accounts.
      const accounts = this.props?.address;

      const networkId = this.props?.chainId;
      if (networkId !== 56) {
        enqueueSnackbar("Error! Please connect to bsc mainnet", { variant: 'error' });
        this.setState({ loading: false });
        return false;
      }

      if (this.state.accounts !== accounts) {
        // Get the contract instance.

        this.setState({ loading: true });
        const deployedNetwork = CaesiumNFT.networks;
        const nft_asset_contract = new web3.eth.Contract(
          ERC1155ABI,
          deployedNetwork && NFT_ASSET_CONTRACT_ADDRESS, provider
        );
  
        const nft_marketplace = new web3.eth.Contract(
          NFT_MARKETPLACE_ABI,
          deployedNetwork && NFT_MaketPlace_ADDRESS, provider
        );
  
        const game_ino_contract = new web3.eth.Contract(
          GAME_INO_ABI,
          deployedNetwork && GAME_INO_CONTRACT_ADDRESS, provider
        );
  
        const contractMember = new web3.eth.Contract(
          CSM_MEMBER_ABI,
          deployedNetwork && Caesium_Member_CONTRACT, provider
        );

        const nft_staking_contract_ = new web3.eth.Contract(
          NFT_STAKING_ABI,
          deployedNetwork && NFT_STAKING_CONTRACT, provider
        );

        const renting_contract = new web3.eth.Contract(
          RENTING_ABI,
          deployedNetwork && RENTING_CONTRACT, provider
        );

        let user_account = accounts;

        this.setState({ loading: false, is_connected: true });
        this.setState({ renting_contract, nft_staking_contract_, game_ino_contract, contractMember, web3, accounts: user_account, nft_asset_contract, nft_marketplace, loading: true }, this.fetchData);

        enqueueSnackbar("Wallet Connected", { variant: 'success' });
      }
    } catch (error) {
      // Catch any errors for any of the above operations.
      enqueueSnackbar(error.message, { variant: 'error' });
      this.setState({ networkMain: false });
      console.error(error);
      this.setState({ loading: false });
    }
  };

  disconnectFromWallet = async () => {
    try {
      this.setState({ loading: true });
      if (this.state.web3) {
        enqueueSnackbar("Wallet disconnected", { variant: 'success' });
      }
      this.setState(initialState);
    } catch (error) {
      // Catch any errors for any of the above operations.
      enqueueSnackbar(error.message, { variant: 'error' });
      this.setState({ networkMain: false });
      console.error(error);
      this.setState({ loading: false });
    }
  }

  fetchData = async () => {
    let { accounts, nft_marketplace, nft_asset_contract } = this.state;
    let YourAddress = this.state.YourAddress;
    if(accounts)
    YourAddress = accounts.slice(0, 6) + '.....' + accounts.slice(accounts.length - 5, accounts.length);
    let YourAddressfull = accounts;
    let isApprovedForAll = this.state.isApprovedForAll;
    if(accounts){
      isApprovedForAll = await nft_asset_contract.methods.isApprovedForAll(accounts,NFT_STAKING_CONTRACT).call();
    }

    let isOwnedNft = false;
    let nft_detail = this.state.nft_detail;
    let sub_cat_id = this.state.sub_cat_id;
    let from_action = this.props.from_action;
    if (this.state.nft_id) {
      if(from_action == 'MyAssets'){
        let token_url = await nft_asset_contract.methods.geturi(this.state.nft_id).call();
        nft_detail = await nftDetailsAPi(token_url);
        nft_detail.tokenId = this.state.nft_id;
        nft_detail.seller = this.state.accounts;
        console.log('55',nft_detail);
      }else{
        let listing_id = this.state.nft_id;
        let nft_market = await nft_marketplace.methods.getNftByListingId(listing_id).call();
        nft_market = {
          itemId:parseFloat(nft_market[0]),
          nftContract:nft_market[1],
          tokenId:parseFloat(nft_market[2]),
          seller:nft_market[3],
          owner:nft_market[4],
          category:nft_market[5],
          price:parseFloat(nft_market[6]),
          currency:nft_market[7],
          quantity:parseFloat(nft_market[8]),
          isSold:nft_market[9]
        }
        let token_url = await nft_asset_contract.methods.geturi(nft_market.tokenId).call();
        let decimals;
        let symbol;
        if (/^0x00+$/.test(nft_market.currency)) {
          decimals = 1e18;
          symbol = 'BNB';
        } else {
          let contract = new this.state.web3.eth.Contract(ERC20ABI, nft_market.currency, this.props?.walletProvider);
          decimals = await contract.methods.decimals().call();
          decimals = parseInt(decimals);
          decimals = 10 ** decimals;
          symbol = await contract.methods.symbol().call();
        }
  
        let nft_details = await nftDetailsAPi(token_url);
        nft_details = nft_details;
        let attributes = nft_details?.attributes;
        nft_market.nft_details = nft_details;
        nft_market.attributes = attributes;
        nft_market.decimals = decimals;
        nft_market.symbol = symbol;
        nft_detail = nft_market;
        sub_cat_id = nft_detail.category;
        if (nft_market?.seller?.toLowerCase() == accounts?.toLowerCase()) {
          isOwnedNft = true;
        }
      }
      
    }

    this.setState({
      YourAddress,
      YourAddressfull,
      isApprovedForAll,
      nft_detail,
      sub_cat_id,
      isOwnedNft,
      loading: false,
      is_refresh: false
    }, async () => {
      // let { nft_marketplace, nft_contract } = this.state;

      // let total_nft_marketplace = await nft_marketplace.methods.getItemsByCategory(this.state.sub_cat_id).call();
      // let related_items = [];

      // if (total_nft_marketplace.length > 0) {
      //   let data_nft = total_nft_marketplace;

      //   total_nft_marketplace = data_nft.length;
      //   for (var i = total_nft_marketplace - 1; i >= 0; i--) {
      //     let nft_market = data_nft[i];

      //     if (parseInt(nft_market[0]) == this.state.nft_id) {
      //       continue;
      //     }
      //     nft_market = {
      //       itemId: parseInt(nft_market[0]),
      //       nftContract: nft_market[1],
      //       tokenId: parseInt(nft_market[2]),
      //       seller: nft_market[3],
      //       owner: nft_market[4],
      //       category: nft_market[5],
      //       price: parseInt(nft_market[6]),
      //       currency: nft_market[7],
      //       isSold: nft_market[8]
      //     }
      //     let token_url = await nft_contract.methods.tokenURI(nft_market.tokenId).call();

  
      //     // console.log('44',token_url)
      //     let decimals;
      //     let symbol;
      //     if (/^0x00+$/.test(nft_market.currency)) {
      //       decimals = 1e18;
      //       symbol = 'BNB';
      //     } else {
      //       let contract = new this.state.web3.eth.Contract(ERC20ABI, nft_market.currency, this.props?.walletProvider);
      //       decimals = await contract.methods.decimals().call();
      //       decimals = parseInt(decimals);
      //       decimals = 10 ** decimals;
      //       symbol = await contract.methods.symbol().call();
      //     }

      //     let nft_details = await nftDetailsAPi(token_url);
      //     // console.log('nft_details',nft_details);
      //     nft_details = nft_details;
      //     let attributes = nft_details?.attributes;
      //     nft_market.nft_details = nft_details;
      //     nft_market.attributes = attributes;
      //     nft_market.decimals = decimals;
      //     nft_market.symbol = symbol;
      //     // console.log("1",nft_market);
      //     related_items.push(nft_market);
      //     if (related_items.length == 4) {
      //       break;
      //     }
      //   }
      // }

      // this.setState({ related_items });
      // const script = document.createElement('script')
      // script.src = '/assets/js/image_cn.js';
      // script.async = true;
      // document.body.append(script);
    });
    // const script = document.createElement('script')
    // script.src = '/assets/js/image_cn.js';
    // script.async = true;
    // document.body.append(script)
  }

  setWeb3 = async ()=>{
    try {      
      this.setState({loading:true});
      let provider = this.props?.walletProvider;
      let web3 = new Web3(modal);
      const accounts = this.props?.address;
      web3.setProvider(binanceSmartChain.rpcUrl);
      const deployedNetwork = CaesiumNFT.networks;
      const nft_asset_contract = new web3.eth.Contract(
        ERC1155ABI,
        deployedNetwork && NFT_ASSET_CONTRACT_ADDRESS, provider
      );

      const nft_marketplace = new web3.eth.Contract(
        NFT_MARKETPLACE_ABI,
        deployedNetwork && NFT_MaketPlace_ADDRESS, provider
      );

      const game_ino_contract = new web3.eth.Contract(
        GAME_INO_ABI,
        deployedNetwork && GAME_INO_CONTRACT_ADDRESS, provider
      );

      const contractMember = new web3.eth.Contract(
        CSM_MEMBER_ABI,
        deployedNetwork && Caesium_Member_CONTRACT, provider
      );

      this.setState({ game_ino_contract, contractMember, web3, nft_asset_contract, accounts:accounts, nft_marketplace},()=>{
        this.fetchData();
      });
    } catch (error) {
        console.log('setWeb3',error);
    }
  }

  doBuyNft = async () => {
    try {
      if(!this.state.accounts){
        enqueueSnackbar('Please connect to wallet.', { variant: "error" });
        this.setState({ loading: false });
        return false;
      }
      this.setState({loading:true});
      let weiValue = 0;
      if (/^0x00+$/.test(this.state.nft_detail.currency)) {
        weiValue = this.state.nft_detail.price*this.state.input_quantity;
      }
      let gasPrice = await this.state.web3.eth.getGasPrice();
      gasPrice = parseInt(gasPrice) + 20;
      let gasLimit = 10000000;
      let estimateGas = 100000;

      console.log('gasPrice',gasPrice)

      let options = {
        from: this.state.accounts,
        gas: gasLimit,
        value: weiValue,
      };
      console.log('options',options)
      console.log(this.state.nft_detail.itemId.toString(),
      this.state.input_quantity.toString());

      let data = await this.state.nft_marketplace.methods.createMarketSale(
        this.state.nft_detail.itemId.toString(),
        this.state.input_quantity.toString()
      ).estimateGas(options);
      estimateGas = parseInt(data) + 10000;
      let claim_data = await this.state.nft_marketplace.methods.createMarketSale(
        this.state.nft_detail.itemId.toString(),
        this.state.input_quantity.toString()
      )
        .send({
          from: this.state.accounts,
          value: weiValue,
          data: {
            "inputs":[
              {
                "internalType":"uint256",
                "name":"itemId",
                "type":"uint256"
              },
              {
                "internalType":"uint256",
                "name":"quantity",
                "type":"uint256"
              }
            ],
            "name":"createMarketSale",
            "outputs":[],
            "stateMutability":"payable",
            "type":"function"
          },
          gas: estimateGas.toString(),
          gasPrice: gasPrice.toString(),
        });

      if (claim_data.status) {
        this.setState({ loading: false });
        enqueueSnackbar("Buy Successfully!", { variant: "success" });
        this.fetchData();
      } else {
        this.setState({ loading: false });
        enqueueSnackbar("Some Network Error Occurred!");
      }

    } catch (error) {
      this.setState({ loading: false });
      console.log('buy nft error', error);
      if (error.message.includes("User denied transaction signature")) {
        // handle the "error" as a rejection
        enqueueSnackbar(error.message, { variant: "error" });
      } else {
        enqueueSnackbar(error, { variant: "error" });
      }
    }
  }

  doSellNft = async () => {
    try {
      if(!this.state.accounts){
        enqueueSnackbar('Please connect to wallet.', { variant: "error" });
        this.setState({ loading: false });
        return false;
      }
   
      if (!this.state.input_sell_price) {
        enqueueSnackbar('Price is required.', { variant: "error" });
        this.setState({ loading: false });
        return false;
      }

      if (!this.state.input_sell_quantity) {
        enqueueSnackbar('Quantity is required.', { variant: "error" });
        this.setState({ loading: false });
        return false;
      }

      if (!this.state.currencyAddress) {
        enqueueSnackbar('Currency is required.', { variant: "error" });
        this.setState({ loading: false });
        return false;
      }

      this.setState({loading:true, nft_sell_modal:false});

      let isApprovedForAll = await this.state.nft_asset_contract.methods.isApprovedForAll(this.state.accounts,NFT_MaketPlace_ADDRESS).call();

      let weiValue = 0;      
      let gasPrice = await this.state.web3.eth.getGasPrice();
      gasPrice = parseInt(gasPrice) + 20;
      let gasLimit = 10000000;
      let estimateGas = 100000;

      console.log('gasPrice',gasPrice)

      let options = {
        from: this.state.accounts,
        gas: gasLimit,
        value: weiValue,
      };

      let decimals = parseInt(decimal_arr[this.state.currencyAddress]);

      let sell_price = parseFloat(this.state.input_sell_price)*(10**decimals);

      if (isApprovedForAll == false) {
        let data = await this.state.nft_asset_contract.methods
          .setApprovalForAll(NFT_MaketPlace_ADDRESS,true)
          .estimateGas(options);

        estimateGas = parseInt(data) + 10000;

        let approve_data = await this.state.nft_asset_contract.methods
          .setApprovalForAll(NFT_MaketPlace_ADDRESS,true)
          .send({
            from: this.state.accounts,
            value: 0,
            data: {
              "inputs": [
                {
                  "internalType": "address",
                  "name": "operator",
                  "type": "address"
                },
                {
                  "internalType": "bool",
                  "name": "approved",
                  "type": "bool"
                }
              ],
              "name": "setApprovalForAll",
              "outputs": [],
              "stateMutability": "nonpayable",
              "type": "function"
            },
            gas: estimateGas.toString(),
            gasPrice: gasPrice.toString(),
          });

        if (approve_data.status) {
          let options = {
            from: this.state.accounts,
            gas: gasLimit,
            value: 0,
          };

          let data = await this.state.nft_marketplace.methods.createMarketItem(
            NFT_ASSET_CONTRACT_ADDRESS,
            this.state.nft_detail?.tokenId,
            sell_price.toString(),
            this.state.currencyAddress,
            this.state.input_sell_quantity,
            this.state.nft_detail?.category
          ).estimateGas(options);

          estimateGas = parseInt(data) + 10000;

          let claim_data = await this.state.nft_marketplace.methods.createMarketItem(
            NFT_ASSET_CONTRACT_ADDRESS,
            this.state.nft_detail?.tokenId,
            sell_price.toString(),
            this.state.currencyAddress,
            this.state.input_sell_quantity,
            this.state.nft_detail?.category
          ).send({
            from: this.state.accounts,
            value: 0,
            data: {
              "inputs":[
                {
                  "internalType":"address",
                  "name":"nftContractadd",
                  "type":"address"
                },
                {
                  "internalType":"uint256",
                  "name":"tokenId",
                  "type":"uint256"
                },
                {
                  "internalType":"uint256",
                  "name":"price",
                  "type":"uint256"
                },
                {
                  "internalType":"address",
                  "name":"currency",
                  "type":"address"
                },
                {
                  "internalType":"uint256",
                  "name":"quantity",
                  "type":"uint256"
                },
                {
                  "internalType":"string",
                  "name":"category",
                  "type":"string"
                }
              ],
              "name":"createMarketItem",
              "outputs":[],
              "stateMutability":"payable",
              "type":"function"
            },
            gas: estimateGas.toString(),
            gasPrice: gasPrice.toString(),
          });

          if (claim_data.status) {
            this.setState({ loading: false, nft_sell_modal:false, input_sell_price:'', input_sell_quantity:''});
            enqueueSnackbar("Sell Successfully!", { variant: "success" });
            this.fetchData();
          } else {
            this.setState({ loading: false });
            enqueueSnackbar("Some Network Error Occurred!");
          }
        } else {
          this.setState({ loading: false });
          enqueueSnackbar("Failed to approve!");
        }

      } else {
        let options = {
          from: this.state.accounts,
          gas: gasLimit,
          value: 0,
        };

        let data = await this.state.nft_marketplace.methods.createMarketItem(
          NFT_ASSET_CONTRACT_ADDRESS,
          this.state.nft_detail?.tokenId,
          sell_price.toString(),
          this.state.currencyAddress,
          this.state.input_sell_quantity,
          this.state.nft_detail?.category
        ).estimateGas(options);

        estimateGas = parseInt(data) + 10000;

        let claim_data = await this.state.nft_marketplace.methods.createMarketItem(
          NFT_ASSET_CONTRACT_ADDRESS,
          this.state.nft_detail?.tokenId,
          sell_price.toString(),
          this.state.currencyAddress,
          this.state.input_sell_quantity,
          this.state.nft_detail?.category
        ).send({
          from: this.state.accounts,
          value: 0,
          data: {
            "inputs":[
              {
                "internalType":"address",
                "name":"nftContractadd",
                "type":"address"
              },
              {
                "internalType":"uint256",
                "name":"tokenId",
                "type":"uint256"
              },
              {
                "internalType":"uint256",
                "name":"price",
                "type":"uint256"
              },
              {
                "internalType":"address",
                "name":"currency",
                "type":"address"
              },
              {
                "internalType":"uint256",
                "name":"quantity",
                "type":"uint256"
              },
              {
                "internalType":"string",
                "name":"category",
                "type":"string"
              }
            ],
            "name":"createMarketItem",
            "outputs":[],
            "stateMutability":"payable",
            "type":"function"
          },
          gas: estimateGas.toString(),
          gasPrice: gasPrice.toString(),
        });

        if (claim_data.status) {
          this.setState({ loading: false, nft_sell_modal:false, input_sell_price:'', input_sell_quantity:''});
          enqueueSnackbar("Sell Successfully!", { variant: "success" });
          this.fetchData();
        } else {
          this.setState({ loading: false });
          enqueueSnackbar("Some Network Error Occurred!");
        }
      }
    } catch (error) {
      this.setState({ loading: false });
      console.log('sell nft error', error);
      if (error.message.includes("User denied transaction signature")) {
        // handle the "error" as a rejection
        enqueueSnackbar(error.message, { variant: "error" });
      } else {
        enqueueSnackbar(error, { variant: "error" });
      }
    }
  }

  doApproveStake =async()=>{
    try {
      if(!this.state.accounts){
        enqueueSnackbar('Please connect to wallet.', { variant: "error" });
        this.setState({ loading: false });
        return false;
      }
      this.setState({loading:true});
      let weiValue = 0;      
      let gasPrice = await this.state.web3.eth.getGasPrice();
      gasPrice = parseInt(gasPrice) + 20;
      let gasLimit = 10000000;
      let estimateGas = 100000;

      console.log('gasPrice',gasPrice)

      let options = {
        from: this.state.accounts,
        gas: gasLimit,
        value: weiValue,
      };

      let data = await this.state.nft_asset_contract.methods.setApprovalForAll(
        NFT_STAKING_CONTRACT,true
      ).estimateGas(options);

      estimateGas = parseInt(data) + 10000;
      let claim_data = await this.state.nft_asset_contract.methods.setApprovalForAll(
        NFT_STAKING_CONTRACT,true
      ).send({
        from: this.state.accounts,
        value: weiValue,
        data: {
          "inputs": [
            {
              "internalType": "address",
              "name": "operator",
              "type": "address"
            },
            {
              "internalType": "bool",
              "name": "approved",
              "type": "bool"
            }
          ],
          "name": "setApprovalForAll",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        gas: estimateGas.toString(),
        gasPrice: gasPrice.toString(),
      });
        

      if (claim_data.status) {
        let isApprovedForAll = await this.state.nft_asset_contract.methods.isApprovedForAll(this.state.accounts,NFT_STAKING_CONTRACT).call();
        this.setState({ isApprovedForAll, loading: false });
        enqueueSnackbar("NFT Enabled!!", { variant: "success" });
        this.fetchData();
      } else {
        this.setState({ loading: false });
        enqueueSnackbar("NFT Enable failed!!");
      }

    } catch (error) {
      this.setState({ loading: false });
      console.log('Enable nft error', error);
      if (error.message.includes("User denied transaction signature")) {
        // handle the "error" as a rejection
        enqueueSnackbar(error.message, { variant: "error" });
      } else {
        enqueueSnackbar(error, { variant: "error" });
      }
    }
  }

  doAddNft =async()=>{
    try {
      if(!this.state.accounts){
        enqueueSnackbar('Please connect to wallet.', { variant: "error" });
        this.setState({ loading: false });
        return false;
      }
      this.setState({loading:true});
      if(!this.state.quantity){
        enqueueSnackbar("Quantity is required!!", { variant: "error" });
        this.setState({loading:false});
        return;
      }
  
      let balanceOf = await this.state.nft_asset_contract.methods.balanceOf(
        this.state.accounts,this.state.nft_detail.tokenId
      ).call();
      balanceOf = parseFloat(balanceOf);

      
  
      if(parseFloat(balanceOf)<parseFloat(this.state.quantity)){
        enqueueSnackbar("Invalid NFT.", { variant: "error" });
        this.setState({loading:false});
        return;
      }

      let weiValue = 0;
      let gasPrice = await this.state.web3.eth.getGasPrice();
      gasPrice = parseInt(gasPrice) + 20;
      let gasLimit = 10000000;
      let estimateGas = 100000;

      console.log('gasPrice',gasPrice)

      let options = {
        from: this.state.accounts,
        gas: gasLimit,
        value: weiValue,
      };

      console.log(this.state.nft_detail.tokenId,this.state.quantity);

      let data = await this.state.nft_staking_contract_.methods.stake(
        this.state.nft_detail.tokenId.toString(),
        this.state.quantity.toString()
      ).estimateGas(options);
      estimateGas = parseInt(data) + 10000;
      let claim_data = await this.state.nft_staking_contract_.methods.stake(
        this.state.nft_detail.tokenId.toString(),
        this.state.quantity.toString()
      )
        .send({
          from: this.state.accounts,
          value: weiValue,
          data: {
            "inputs":[
              {
                "internalType":"uint256",
                "name":"nftId",
                "type":"uint256"
              },
              {
                "internalType":"uint256",
                "name":"qty",
                "type":"uint256"
              }
            ],
            "name":"stake",
            "outputs":[],
            "stateMutability":"nonpayable",
            "type":"function"
          },
          gas: estimateGas.toString(),
          gasPrice: gasPrice.toString(),
        });

      if (claim_data.status) {
        this.setState({ loading: false, quantity:'' });
        enqueueSnackbar("NFT added successfully.", { variant: "success" });
        this.fetchData();
      } else {
        this.setState({ loading: false });
        enqueueSnackbar("Error to add NFT.");
      }
    } catch (error) {
      console.log('error',error);
      this.setState({loading:false});
    }
  }

  doRentNft = async () => {
    try {
      if(!this.state.accounts){
        enqueueSnackbar('Please connect to wallet.', { variant: "error" });
        this.setState({ loading: false });
        return false;
      }
   
      if (!this.state.input_rent_price) {
        enqueueSnackbar('Price is required.', { variant: "error" });
        this.setState({ loading: false });
        return false;
      }

      if (!this.state.input_rent_quantity) {
        enqueueSnackbar('Quantity is required.', { variant: "error" });
        this.setState({ loading: false });
        return false;
      }

      if (!this.state.rent_currencyAddress) {
        enqueueSnackbar('Currency is required.', { variant: "error" });
        this.setState({ loading: false });
        return false;
      }

      if (!this.state.time_period) {
        enqueueSnackbar('Time Period is required.', { variant: "error" });
        this.setState({ loading: false });
        return false;
      }

      this.setState({loading:true, nft_rent_modal:false});

      let isApprovedForAll = await this.state.nft_asset_contract.methods.isApprovedForAll(this.state.accounts,RENTING_CONTRACT).call();

      let weiValue = 0;      
      let gasPrice = await this.state.web3.eth.getGasPrice();
      gasPrice = parseInt(gasPrice) + 20;
      let gasLimit = 10000000;
      let estimateGas = 100000;

      console.log('gasPrice',gasPrice)

      let options = {
        from: this.state.accounts,
        gas: gasLimit,
        value: weiValue,
      };

      let decimals = parseInt(decimal_arr[this.state.rent_currencyAddress]);

      let rent_price = parseFloat(this.state.input_rent_price)*(10**decimals);

      if (isApprovedForAll == false) {
        let data = await this.state.nft_asset_contract.methods
          .setApprovalForAll(RENTING_CONTRACT,true)
          .estimateGas(options);

        estimateGas = parseInt(data) + 10000;

        let approve_data = await this.state.nft_asset_contract.methods
          .setApprovalForAll(RENTING_CONTRACT,true)
          .send({
            from: this.state.accounts,
            value: 0,
            data: {
              "inputs": [
                {
                  "internalType": "address",
                  "name": "operator",
                  "type": "address"
                },
                {
                  "internalType": "bool",
                  "name": "approved",
                  "type": "bool"
                }
              ],
              "name": "setApprovalForAll",
              "outputs": [],
              "stateMutability": "nonpayable",
              "type": "function"
            },
            gas: estimateGas.toString(),
            gasPrice: gasPrice.toString(),
          });

        if (approve_data.status) {
          let options = {
            from: this.state.accounts,
            gas: gasLimit,
            value: 0,
          };

          let data = await this.state.renting_contract.methods.createItem(
            this.state.nft_detail?.tokenId,
            rent_price.toString(),
            this.state.rent_currencyAddress,
            this.state.input_rent_quantity,
            this.state.time_period.toString(),
            this.state.nft_detail?.category
          ).estimateGas(options);

          estimateGas = parseInt(data) + 10000;

          let claim_data = await this.state.renting_contract.methods.createItem(
            this.state.nft_detail?.tokenId,
            rent_price.toString(),
            this.state.rent_currencyAddress,
            this.state.input_rent_quantity,
            this.state.time_period.toString(),
            this.state.nft_detail?.category
          ).send({
            from: this.state.accounts,
            value: 0,
            data: {
              "inputs": [
                {
                  "internalType": "uint256",
                  "name": "tokenId",
                  "type": "uint256"
                },
                {
                  "internalType": "uint256",
                  "name": "price",
                  "type": "uint256"
                },
                {
                  "internalType": "address",
                  "name": "currency",
                  "type": "address"
                },
                {
                  "internalType": "uint256",
                  "name": "quantity",
                  "type": "uint256"
                },
                {
                  "internalType": "uint256",
                  "name": "timePeriod",
                  "type": "uint256"
                },
                {
                  "internalType": "string",
                  "name": "category",
                  "type": "string"
                }
              ],
              "name": "createItem",
              "outputs": [],
              "stateMutability": "payable",
              "type": "function"
            },
            gas: estimateGas.toString(),
            gasPrice: gasPrice.toString(),
          });

          if (claim_data.status) {
            this.setState({ loading: false, nft_rent_modal:false, input_rent_price:'', input_rent_quantity:'', time_period:''});
            enqueueSnackbar("Rent Successfully!", { variant: "success" });
            this.fetchData();
          } else {
            this.setState({ loading: false });
            enqueueSnackbar("Some Network Error Occurred!");
          }
        } else {
          this.setState({ loading: false });
          enqueueSnackbar("Failed to approve!");
        }

      } else {
        let options = {
          from: this.state.accounts,
          gas: gasLimit,
          value: 0,
        };

        let data = await this.state.renting_contract.methods.createItem(
          this.state.nft_detail?.tokenId,
          rent_price.toString(),
          this.state.rent_currencyAddress,
          this.state.input_rent_quantity,
          this.state.time_period.toString(),
          this.state.nft_detail?.category
        ).estimateGas(options);

        estimateGas = parseInt(data) + 10000;

        let claim_data = await this.state.renting_contract.methods.createItem(
          this.state.nft_detail?.tokenId,
          rent_price.toString(),
          this.state.rent_currencyAddress,
          this.state.input_rent_quantity,
          this.state.time_period.toString(),
          this.state.nft_detail?.category
        ).send({
          from: this.state.accounts,
          value: 0,
          data: {
            "inputs": [
              {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "price",
                "type": "uint256"
              },
              {
                "internalType": "address",
                "name": "currency",
                "type": "address"
              },
              {
                "internalType": "uint256",
                "name": "quantity",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "timePeriod",
                "type": "uint256"
              },
              {
                "internalType": "string",
                "name": "category",
                "type": "string"
              }
            ],
            "name": "createItem",
            "outputs": [],
            "stateMutability": "payable",
            "type": "function"
          },
          gas: estimateGas.toString(),
          gasPrice: gasPrice.toString(),
        });

        if (claim_data.status) {
          this.setState({ loading: false, nft_rent_modal:false, input_rent_price:'', input_rent_quantity:'', time_period:''});
          enqueueSnackbar("Rent Successfully!", { variant: "success" });
          this.fetchData();
        } else {
          this.setState({ loading: false });
          enqueueSnackbar("Some Network Error Occurred!");
        }
      }
    } catch (error) {
      this.setState({ loading: false });
      console.log('rent nft error', error);
      if (error.message.includes("User denied transaction signature")) {
        // handle the "error" as a rejection
        enqueueSnackbar(error.message, { variant: "error" });
      } else {
        enqueueSnackbar(error, { variant: "error" });
      }
    }
  }

  calculateTimePeriod = ()=> {
    let time_period = 0;
    let {no_of_days,no_of_hours,no_of_minutes} = this.state;
    if(!no_of_days) no_of_days=0;
    if(!no_of_hours) no_of_hours=0;
    if(!no_of_minutes) no_of_minutes=0;

    if(no_of_days){
      no_of_days = parseInt(no_of_days);
      let days_in_seconds = no_of_days*24*60*60;
      time_period = days_in_seconds;
    }

    if(no_of_hours){
      no_of_hours = parseInt(no_of_hours);
      let hours_in_seconds = no_of_hours*60*60;
      time_period += hours_in_seconds;
    }

    if(no_of_minutes){
      no_of_minutes = parseInt(no_of_minutes);
      let minutes_in_seconds = no_of_minutes*60;
      time_period += minutes_in_seconds;
    }

    console.log('time_period',time_period);
    this.setState({time_period});

  }

  render() {
    let this2 = this;
    return (
      <Frame withHeader={false} withFooter={true}>

        {this.state.loading ? (
          <div className="loader-container">
            <div className="linespinner"></div>
          </div>
        ) : (null)}

        <header id="header_main" className="pt-3 pb-3 header_1 header-fixed">
          <div className="container">
            <div className="header-inner">
              <div className="header-left">
                <div className="site-logo">
                  <a href="/" className="main-logo">
                    <img src="/assets/img/logo.png" />
                  </a>
                </div>
                <nav id="main-nav" className="main-nav">
                  <ul id="menu-primary-menu" className="menu">
                    {/* <li className="menu-item"><a href="/marketplace">Marketplace</a></li> */}
                    {/* <li className="menu-item"><a href="/my_nft">My Nft</a></li> */}
                   
                  </ul>
                </nav>
              </div>
              <div className="header-right">
                <div className="flat-wallet flex">                  
                  <div className="d-none d-lg-block ms-3">
                    {this.props.isConnected ? (
                      <a
                        disabled={this.state.loading}
                        // onClick={() => this.props.open()}
                        className="gradient_btn_skew btn pointer"
                      >
                        <i className="icon-sign-out-1"></i> {this.state.YourAddress}
                      </a>
                    ) : (
                      <a
                        disabled={this.state.loading}
                        onClick={() => this.props.open()}
                        className="gradient_btn_skew btn pointer"
                      >
                        <i className="icon-sign-out-1"></i> Connect wallet
                      </a>
                    )}
                  </div>
                  <div className="admin_active">
                    <div className="popup-user relative">
                      <div className="user">
                        <img src="/assets/img/avatar-small-09.png" alt="" />
                      </div>
                      <div className="avatar_popup2">
                        <div className="">
                          <div className="links">
                            {/* <a className="block mb-20" href="#">
                              <img src="/assets/img/boy-avater.png" alt="" />
                              <span>My Profile</span>
                            </a> */}
                            {/* <a className="block mb-20" href="#">
                              <img src="/assets/img/eye.png" alt="" />
                              <span>Watchlist</span>
                            </a>
                            <a className="block mb-20" href="/my_nft">
                              <img src="/assets/img/hand-shake.png" alt="" />
                              <span>My NFT</span>
                            </a> */}
                            {this.props.isConnected ? (
                              <a
                                disabled={this.state.loading}
                                onClick={() => this.props.open()}
                                className="block mb-20 pointer"
                              >
                                <img src="/assets/img/logout.png" alt="" />
                                <span> Sign Out</span>
                              </a>
                            ) : (
                              <a
                                disabled={this.state.loading}
                                onClick={() => this.props.open()}
                                className="block mb-20 pointer"
                              >
                                <img src="/assets/img/logout.png" alt="" />
                                <span> Sign In</span>
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mobile-button">
                    <span></span>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="mobile-nav-wrap">
            <div className="overlay-mobile-nav"></div>
            <div className="inner-mobile-nav">
              <a href="/" rel="home" className="main-logo">
                <img id="mobile-logo_header" src="/assets/img/logo.png" />
              </a>
              <div className="mobile-nav-close">
                <svg xmlns="http://www.w3.org/2000/svg" fill="white" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 122.878 122.88" enableBackground="new 0 0 122.878 122.88" space="preserve"><g><path d="M1.426,8.313c-1.901-1.901-1.901-4.984,0-6.886c1.901-1.902,4.984-1.902,6.886,0l53.127,53.127l53.127-53.127 c1.901-1.902,4.984-1.902,6.887,0c1.901,1.901,1.901,4.985,0,6.886L68.324,61.439l53.128,53.128c1.901,1.901,1.901,4.984,0,6.886 c-1.902,1.902-4.985,1.902-6.887,0L61.438,68.326L8.312,121.453c-1.901,1.902-4.984,1.902-6.886,0 c-1.901-1.901-1.901-4.984,0-6.886l53.127-53.128L1.426,8.313L1.426,8.313z" /></g></svg>
              </div>
              <nav id="mobile-main-nav" className="mobile-main-nav">
                <ul id="menu-mobile-menu" className="menu">
                  {/* <li className="menu-item"><a href="/marketplace">Marketplace</a></li>
                  <li className="menu-item"><a href="/my_nft">My Nft</a></li> */}
                </ul>
              </nav>
              <div className="mt-3">
                {this.props.isConnected ? (
                  <a
                    disabled={this.state.loading}
                    onClick={() => this.props.open()}
                    className="gradient_btn_skew btn btn-block pointer"
                  >
                    <i className="icon-sign-out-1"></i> Disconect wallet
                  </a>
                ) : (
                  <a
                    disabled={this.state.loading}
                    onClick={() => this.props.open()}
                    className="gradient_btn_skew btn btn-block pointer"
                  >
                    <i className="icon-sign-out-1"></i> Connect wallet
                  </a>
                )}
              </div>
            </div>
          </div>
        </header>

        <div className="pb-4 pt-5">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <div className="tf-card-box style-5 bg-none mb-4">
                  <div className="card-media mb-0">
                    <div className="mygif_img_details">
                      <canvas className="mycanvas_gif" width="512" height="512"></canvas>
                      {this.props.from_action == 'MyAssets' ? (
                        this.state.nft_detail?.image ? (
                          <img className="my_gif_img" src={this.state.nft_detail?.image} />
                        ) : (
                          <img className="my_gif_img" src="/assets/img/csm-nft-no-image.jpg" />
                        )
                      ):(
                        this.state.nft_detail?.nft_details?.image ? (
                          <img className="my_gif_img" src={this.state.nft_detail?.nft_details?.image} />
                        ) : (
                          <img className="my_gif_img" src="/assets/img/csm-nft-no-image.jpg" />
                        )
                      )}                      
                    </div>
                  </div>
                  {/* <h6 className="price gem"><img className='icon-bnb' src='/assets/img/Binance_icon.png' /></h6>
                  <div className="wishlist-button">10<i className="icon-heart"></i></div> */}
                </div>


              </div>
              <div className="col-md-7">
                <div className="infor-product position-relative">
                  <div className="text">{this.state.nft_detail?.category} Main Collection <span className="icon-tick"><span className="path1"></span><span className="path2"></span></span></div>
                  <div className="menu_card">
                    <div className="dropdown">
                      <div className="icon">
                        <a href="javascript:void(0);" className="btn-link" data-bs-toggle="dropdown" aria-expanded="false">
                          <i className="icon-link-1"></i>
                        </a>
                        <div className="dropdown-menu">
                          <CopyToClipboard
                            text={`${SITE_URL}nft_details/${btoa(this.props.nft_id)}/${btoa(this.props.from_action)}`}
                            onCopy={() =>
                              enqueueSnackbar("Copied Successfully!", {
                                variant: "info",
                              })
                            }
                          >
                            <a className="dropdown-item" ><i className="icon-link"></i>Copy link</a>
                          </CopyToClipboard>
                          <a className="dropdown-item">
                            <FacebookShareButton style={{marginTop:5}} url={`${SITE_URL}nft_details/${btoa(this.props.nft_id)}/${btoa(this.props.from_action)}`}>
                              <i className="icon-facebook"></i><span style={{marginBottom:5}}> Share on facebook</span>
                            </FacebookShareButton>
                          </a>

                          <a className="dropdown-item mb-0" >
                            <TwitterShareButton style={{marginTop:5}} url={`${SITE_URL}nft_details/${btoa(this.props.nft_id)}/${btoa(this.props.from_action)}`}>
                              <i className="icon-twitter"></i><span style={{marginBottom:5}}> Share on twitter</span>
                            </TwitterShareButton>
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* <div className="dropdown">
                      <div className="icon">
                        <a href="javascript:void(0);" className="btn-link" data-bs-toggle="dropdown" aria-expanded="false">
                          <i className="icon-content"></i>
                        </a>
                        <div className="dropdown-menu">
                          <a className="dropdown-item" ><i className="icon-refresh"></i>Refresh metadata</a>
                          <a className="dropdown-item mb-0" ><i className="icon-report"></i>Report</a>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <h2>NFT ID : #{this.state.nft_detail?.tokenId}</h2>
                  <div className="author flex items-center mb-30">
                    <div className="avatar">
                      <img src="/assets/img/avatar-box-01.jpg" alt="Image" />
                    </div>
                    <div className="info">
                      <span>Owned by:</span>
                      <h6><a >{this.state.nft_detail?.seller ? (
                        this.state.nft_detail?.seller.slice(0, 10) + '.....' + this.state.nft_detail?.seller.slice(this.state.nft_detail?.seller.length - 5, this.state.nft_detail?.seller.length)
                      ) : ('---')}</a> </h6>
                    </div>
                  </div>
                  {/* <div className="meta mb-20">
                    <div className="meta-item view">
                      <i className="icon-show"></i>208 view
                    </div>
                    <div className="meta-item rating">
                      <i className="icon-link-2"></i>Top #2 trending
                    </div>
                    <div className="meta-item favorites">
                      <i className="icon-heart"></i>10 favorites
                    </div>
                  </div> */}
                </div>
                <div className="product-item time-sales mt-5">
                  {/* <h6><i className="icon-clock"></i>Sale ends May 22 at 9:39</h6> */}
                  <div className="content">
                    {this.props.from_action == 'NftMarketPlace' ? (
                      <>
                        <div className="mb-3">
                          <label className="form-label">Enter Quantity</label>
                          <input
                            type="text"
                            className="style-1"
                            placeholder="Quantity"
                            onChange={(e) =>
                              this.setState({ input_quantity: e.target.value })
                            }
                            value={this.state.input_quantity}
                          />
                        </div>
                        <div className="text">Current price</div>
                      </>                      
                    ):(null)}

                    {this.props.from_action == 'MyAssets' && this.state.isApprovedForAll!=false ? (
                      <>
                        <div className="mb-3">
                          <label className="form-label">Enter Stake Quantity</label>
                          <input
                            type="text"
                            className="style-1"
                            placeholder="Stake Quantity"
                            onChange={(e) =>
                              this.setState({ quantity: e.target.value })
                            }
                            value={this.state.quantity}
                          />
                        </div>
                      </>                      
                    ):(null)}

                    
                    <div className="flex justify-between">
                      {this.props.from_action == 'NftMarketPlace' ? (
                        this.state.nft_detail?.price ? (
                          <p>{parseFloat(this.state.nft_detail?.price / this.state.nft_detail?.decimals).toFixed(4)} {this.state.nft_detail?.symbol} <span></span></p>
                        ) : (
                          <p>0.00 <span></span></p>
                        )
                      ):(null)}

                      {this.props.from_action == 'MyAssets' ? (
                        <>
                          {this.state.isApprovedForAll==false?(
                            <a onClick={() => this.doApproveStake()} className="tf-button style-1 h50 pointer">Enable</a>
                          ):(
                            <a onClick={() => this.doAddNft()} className="tf-button style-1 h50 pointer">Stake</a>
                          )}
                          <a onClick={() => this.setState({ nft_rent_modal: true })} className="tf-button style-1 h50 pointer">Rent</a>
                          <a onClick={() => this.setState({ nft_sell_modal: true })} className="tf-button style-1 h50 pointer">Sell<i className="icon-arrow-up-right2"></i></a>
                        </>
                      ) : (
                        <a disabled={this.state.nft_detail?.seller?.toLowerCase() != this.props?.address?.toLowerCase()?this.state.loading:true} 
                        onClick={() => {
                          if(!this.state.accounts){
                            enqueueSnackbar('Please connect to wallet.', { variant: "error" });
                            this.setState({ loading: false });
                            return false;
                          }
                          if(this.state.nft_detail?.seller?.toLowerCase() != this.props?.address?.toLowerCase()){
                            if(!this.state.input_quantity){
                              enqueueSnackbar(`Quantity is required!!`, { variant: 'error' });
                              this.setState({loading:false});
                              return false;
                            }
                            if(parseInt(this.state.input_quantity) > parseInt(this.state.nft_detail?.quantity)){
                              enqueueSnackbar(`Quantity should be less than equals to ${parseInt(this.state.nft_detail?.quantity)}!!`, { variant: 'error' });
                              this.setState({loading:false});
                              return false;
                            }
                            this.doBuyNft()
                          }
                          
                        }} 
                        style={{
                          opacity:this.state.nft_detail?.seller?.toLowerCase() != this.props?.address?.toLowerCase()?1:0.5,
                          cursor:this.state.nft_detail?.seller?.toLowerCase() != this.props?.address?.toLowerCase()?'pointer':'not-allowed'
                        }}
                        className="tf-button style-1 h50 w216 pointer">Buy<i className="icon-arrow-up-right2"></i></a>
                      )}
                    </div>
                  </div>
                </div>

              </div>
              
              <div className="col-12">
                <div className="product-item traits style-1">
                  <h6><i className="icon-description"></i>Traits</h6>
                  <i className="icon-keyboard_arrow_down"></i>
                  <div className="content">
                    {this.state.nft_detail?.attributes?.length ? (
                      this.state.nft_detail?.attributes.map(function (item, index) {
                        return (
                          <div key={`li${index}`} className="trait-item">
                            <p>{item.trait_type}</p>
                            <div className="title">{item.value}</div>
                            {/* <p>Floor: 0,056 ETH</p> */}
                          </div>
                        )
                      })
                    ) : (
                      <p className='text-center'>No data available</p>
                    )}

                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        {/* <div className="pt-0 pb-5 position-relative">
          <div className="container">
            <div className="heading-section pb-20">
              <h2 className="tf-title ">More From This Collection</h2>
            </div>
            <div className="featured pt-10 swiper-container carousel" data-swiper='{
                "loop":false,
                "slidesPerView": 2,
                "observer": true,
                "observeParents": true,
                "spaceBetween": 5,
                "navigation": {
                    "clickable": true,
                    "nextEl": ".slider-next",
                    "prevEl": ".slider-prev"
                },
                "pagination": {
                    "el": ".swiper-pagination",
                    "clickable": true
                },
                "breakpoints": {
                    "768": {
                        "slidesPerView": 2,
                        "spaceBetween": 20
                    },
                    "1024": {
                        "slidesPerView": 4,
                        "spaceBetween": 20
                    },
                    "1300": {
                        "slidesPerView": 4,
                        "spaceBetween": 20
                    }
                }
            }'>
              <div className="swiper-wrapper">

                {this.state.related_items ? (

                  this.state.related_items.length > 0 ? (
                    this.state.related_items.map(function (item, index) {
                      let image_url = item.nft_details.image;
                     
                      let seller_view = '---';
                      if (item?.seller && !/^0x00+$/.test(item?.seller)) {
                        let seller = item?.seller;
                        seller_view = seller.slice(0, 10) + '.....' + seller.slice(seller.length - 5, seller.length);
                      }
                      return (
                        <div className="swiper-slide" key={`more${index}`}>
                          <div className="tf-card-box style-1">
                            <div className="card-media">
                              <div className="mygif_img_box">
                                <canvas className="mycanvas_gif" width="512" height="512"></canvas>
                                {image_url?(
                                  <img className="my_gif_img" src={image_url} />
                                ):(
                                  <img className="my_gif_img" src="/assets/img/csm-nft-no-image.jpg" />
                                )}
                              </div>
                              <span className="wishlist-button icon-heart"></span>
                              <div className="button-place-bid">
                                <a href={`/nft_details/${btoa(item.itemId)}/${btoa(this.props.from_action)}`} className="tf-button"><span>Place Buy</span></a>
                              </div>
                            </div>
                            <h5 className="name"><a >NFT ID : #{item.tokenId}</a></h5>
                            <div className="author flex items-center">
                              <div className="avatar">
                                <img src="/assets/img/avatar-box-01.jpg" alt="Image" />
                              </div>
                              <div className="info">
                                <span>Created by:</span>
                                <h6><a >{seller_view}</a> </h6>
                              </div>
                            </div>
                            <div className="divider"></div>
                            <div className="meta-info flex items-center justify-between">
                              <span className="text-bid">Current Price</span>
                              <h6 className="price gem">
                                {parseFloat(item.price/item.decimals).toFixed(4)} {item.symbol}
                              </h6>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  ) : (
                    <div className='heading-section middle_heading text-center justify-content-center'>
                      <img className='no_data_img' src='/assets/img/no_data_found.png' />
                      <h1 className='tf-title mb-0 mt-3'>No data available</h1>
                    </div>
                  )
                ) : (
                  <div className='heading-section middle_heading text-center justify-content-center'>
                    <img className='no_data_img' src='/assets/img/no_data_found.png' />
                    <h1 className='tf-title mb-0 mt-3'>No data available</h1>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="gradient-bg"></div>
        </div> */}

        <Modal
          show={this.state.nft_sell_modal}
          onHide={() => this.setState({ nft_sell_modal: false })}
          backdrop="static"
          keyboard={false}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="modal_top_bg"
        >
          <button onClick={() => this.setState({ nft_sell_modal: false })} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>

          <div className="modal-body p-4">

            <div className="mb-3">
              <label className="form-label">NFT Name</label>
              <input
                type="text"
                className="style-1"
                placeholder="NFT Name"
                readOnly
                value={this.state.nft_detail?.name || "-"}
              />
            </div>

            <div className="mb-3">
              <label className="form-label">NFT Id</label>
              <input
                type="text"
                className="style-1"
                placeholder="NFT Id"
                readOnly
                value={this.state.nft_detail?.tokenId || "-"}
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Category</label>
              <input
                type="text"
                className="style-1"
                placeholder="NFT Id"
                readOnly
                value={this.state.nft_detail?.category || "-"}
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Enter Price</label>
              <input
                type="text"
                className="style-1"
                placeholder="Enter Price"
                onChange={(e) =>
                  this.setState({ input_sell_price: e.target.value })
                }
                value={this.state.input_sell_price}
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Enter Quantity</label>
              <input
                type="text"
                className="style-1"
                placeholder="Enter Quantity"
                onChange={(e) =>
                  this.setState({ input_sell_quantity: e.target.value })
                }
                value={this.state.input_sell_quantity}
              />
            </div>

            <div className="blockchain mb-4">
              <label className='form-label'>Choose Currency</label>
              <div className="widget-coins flex gap10 flex-wrap">
                {currency_arr.map(function(item,index){
                  let active_class = '';
                  if(this2.state.currencyAddress == item.address){
                    active_class = 'active';
                  }
                  return (
                  <div key={`cc${index}`} onClick={()=>this2.setState({currencyAddress:item.address})} className={`widget-coins-item flex items-center ${active_class}`}>
                    <img src={`/assets/img/${item.symbol.toLocaleLowerCase()}-coin.png`} alt="" />
                    <p>{item.symbol}</p>
                  </div>
                  )
                })}                
              </div>
            </div>
            <button disabled={this.state.loading} onClick={()=>this.doSellNft()} className="tf-button style-1 h50 w216 pointer">Sell<i className="icon-arrow-up-right2"></i></button>
          </div>
        </Modal>

        <Modal
          show={this.state.nft_rent_modal}
          onHide={() => this.setState({ nft_rent_modal: false })}
          backdrop="static"
          keyboard={false}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="modal_top_bg"
        >
          <button onClick={() => this.setState({ nft_rent_modal: false })} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>

          <div className="modal-body p-4">

            <div className="mb-3">
              <label className="form-label">NFT Name</label>
              <input
                type="text"
                className="style-1"
                placeholder="NFT Name"
                readOnly
                value={this.state.nft_detail?.name || "-"}
              />
            </div>

            <div className="mb-3">
              <label className="form-label">NFT Id</label>
              <input
                type="text"
                className="style-1"
                placeholder="NFT Id"
                readOnly
                value={this.state.nft_detail?.tokenId || "-"}
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Category</label>
              <input
                type="text"
                className="style-1"
                placeholder="NFT Id"
                readOnly
                value={this.state.nft_detail?.category || "-"}
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Enter Price</label>
              <input
                type="text"
                className="style-1"
                placeholder="Enter Price"
                onChange={(e) =>
                  this.setState({ input_rent_price: e.target.value })
                }
                value={this.state.input_rent_price}
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Enter Quantity</label>
              <input
                type="text"
                className="style-1"
                placeholder="Enter Quantity"
                onChange={(e) =>
                  this.setState({ input_rent_quantity: e.target.value })
                }
                value={this.state.input_rent_quantity}
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Time Period</label>
              <div className="row">
                <div className="col-md-12 flex justify-between">
                  <div className="col-md-4">
                    <input
                      type="text"
                      className="style-1"
                      placeholder="Days"
                      onChange={(e) =>
                        this.setState({ no_of_days: e.target.value },()=>{
                          this.calculateTimePeriod();
                        })
                      }
                      value={this.state.no_of_days}
                    />
                  </div>
                  <div className="col-md-4">
                    <input
                      type="text"
                      className="style-1"
                      placeholder="Hours"
                      onChange={(e) =>
                        this.setState({ no_of_hours: e.target.value },()=>{
                          this.calculateTimePeriod();
                        })
                      }
                      value={this.state.no_of_hours}
                    />
                  </div>
                  <div className="col-md-4">
                    <input
                      type="text"
                      className="style-1"
                      placeholder="Minutes"
                      onChange={(e) =>
                        this.setState({ no_of_minutes: e.target.value },()=>{
                          this.calculateTimePeriod();
                        })
                      }
                      value={this.state.no_of_minutes}
                    />
                  </div>
                </div>
              </div>              
            </div>

            <div className="blockchain mb-4">
              <label className='form-label'>Choose Currency</label>
              <div className="widget-coins flex gap10 flex-wrap">
                {currency_arr.map(function(item,index){
                  let active_class = '';
                  if(this2.state.rent_currencyAddress == item.address){
                    active_class = 'active';
                  }
                  return (
                  <div key={`cc${index}`} onClick={()=>this2.setState({rent_currencyAddress:item.address})} className={`widget-coins-item flex items-center ${active_class}`}>
                    <img src={`/assets/img/${item.symbol.toLocaleLowerCase()}-coin.png`} alt="" />
                    <p>{item.symbol}</p>
                  </div>
                  )
                })}
              </div>
            </div>
            <button disabled={this.state.loading} onClick={()=>this.doRentNft()} className="tf-button style-1 h50 w216 pointer">Rent</button>
          </div>
        </Modal>

      </Frame>
    );
  }
}

function NftDetailsHOC(props) {
  let { nft_id, from_action } = useParams();
  const { open } = useWeb3Modal();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { signer, walletProvider } = useWeb3ModalSigner();
  const navigate = useNavigate();
  if (!nft_id) {
    navigate("/");
  }
  if (!address) {
    navigate("/");
  }
  nft_id = atob(nft_id);
  if(from_action){
    from_action = atob(from_action);
  }

  return <NftDetails
    open={open}
    address={address}
    chainId={chainId}
    isConnected={isConnected}
    signer={signer}
    walletProvider={walletProvider}
    nft_id={nft_id}
    from_action={from_action}
    {...props}
  />;
}

export default NftDetailsHOC;